import React,{useState} from "react";

import style from "./singlefaq.module.css";
import { IoIosArrowDown } from "react-icons/io";
const SingleFaq = ({ title, detail }) => {
  const [isExp, setIsExp] = useState(false);

  const expHandler = () => {
    setIsExp(!isExp);
  };

  return (
    <div>
      <div className={style.faqs}>
        <div className={style.faq}>
          <div onClick={expHandler} className={style.control}>
            <p className={style.title}>{title}</p>
            <IoIosArrowDown
              className={isExp ? style.arrowLeft : style.arrowDown}
            />
          </div>
          <p className={isExp ? style.exp : style.detail}>{detail}</p>
        </div>
      </div>
    </div>
  );
};

export default SingleFaq;
