import React from "react";
import logo from "../../../../assets/cards/card20/logo.png";
import banner from "../../../../assets/cards/card20/bg.jpg";
import { useSelector } from "react-redux";
import UserBrandLogo from "../common/user-logo-com/UserBrandLogo";
import UserBanner from "../common/UserBanner";
import style from "./card20.module.css";
import UserShowName from "../common/UserShowName";
import UserShowDesigination from "../common/UserShowDesigination";
import UserShowLocation from "../common/UserShowLocation";
import UserMobileNum from "../common/UserMobileNum";
import { IoCall } from "react-icons/io5";
import UserWhatsapp from "../common/UserWhatsapp";
import { FaRegCircleRight } from "react-icons/fa6";
import {
  FaInternetExplorer,
  FaLocationArrow,
  FaSave,
  FaWhatsapp,
} from "react-icons/fa";
import UserEmail from "../common/UserEmail";
import { MdEmail } from "react-icons/md";
import UserWebsite from "../common/UserWebsite";
import UserLocation from "../common/UserLocation";

const Card20 = ({ data }) => {
  const fields = useSelector((state) => state.cardFiled);
  return (
    <div className={style.main}>
      <div className={style.header}>
        <div className={style.logo_section}>
          <UserBrandLogo
            data={data}
            fields={fields}
            logo={logo}
            deco={style.logo}
          />
        </div>
        <div className={style.name_section}>
          <UserShowName
            data={data}
            fields={fields}
            name="Big Bang Hotel"
            deco={style.name}
          />
        </div>
      </div>

      <div className={style.banner_section}>
        <UserBanner
          data={data}
          fields={fields}
          banner={banner}
          deco={style.banner}
        />
      </div>
      <UserShowName
        data={data}
        fields={fields}
        name="Stephanie"
        deco={style.name}
      />
      <div className={style.bar}></div>
      <UserShowDesigination
        data={data}
        fields={fields}
        desigination="Sales Director"
        deco={style.desigination}
      />

      <UserShowLocation
        data={data}
        fields={fields}
        location="Street Number, Street Name,Town, Postal Code, State or Province, Country"
        deco={style.location}
      />

      <div className={style.links}>
        <UserMobileNum data={data} fields={fields} mobile="">
          <div className={style.item}>
            <IoCall className={style.icon} />
            <span className={style.title}>Call</span>
          </div>
        </UserMobileNum>
        <UserWhatsapp data={data} fields={fields} whatsapp="">
          <div className={style.item}>
            <FaWhatsapp className={style.icon} />
            <span className={style.title}>Whatsapp</span>
          </div>
        </UserWhatsapp>
        <UserEmail data={data} fields={fields} email="">
          <div className={style.item}>
            <MdEmail className={style.icon} />
            <span className={style.title}>Email</span>
          </div>
        </UserEmail>
      </div>
      <div className={style.links}>
        <UserWebsite data={data} fields={fields} website="">
          <div className={style.item}>
            <FaInternetExplorer className={style.icon} />
            <span className={style.title}>Website</span>
          </div>
        </UserWebsite>
        <UserLocation data={data} fields={fields} location="">
          <div className={style.item}>
            <FaLocationArrow className={style.icon} />
            <span className={style.title}>Location </span>
          </div>
        </UserLocation>
        <div className={style.item}>
          <FaSave className={style.icon} />
          <span className={style.title}>Save</span>
        </div>
      </div>
      <div className={style.about}>
        <span className={style.about_title}>About Us</span>
        <FaRegCircleRight className={style.arrow} />
      </div>
    </div>
  );
};

export default Card20;
