import React from "react";
import useUpdateCard from "../../../utils/useUpdateCard";
import style from "./textInputField.module.css";
const UserPostField = () => {
  const { dispatch, fieldActions } = useUpdateCard();
  const postChange = (event) => {
    dispatch(fieldActions.setUserPost(event.target.value));
  };

  return (
    <>
      <input
        onChange={postChange}
        type="text"
          placeholder="enter post"
        className={style.textInput}
      />
    </>
  );
};

export default UserPostField;
