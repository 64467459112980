import React from "react";
import style from "./card25.module.css";
import { useSelector } from "react-redux";
import profile from "../../../../assets/cards/card25/card25.png";
import UserProfile from "../common/user-profile-com/UserProfile";
import UserShowName from "../common/UserShowName";
import UserShowDesigination from "../common/UserShowDesigination";
import UserMobileNum from "../common/UserMobileNum";
import {
  FaFacebook,
  FaInstagram,
  FaInternetExplorer,
  FaMobileAlt,
  FaTwitter,
  FaVimeo,
  FaWhatsapp,
} from "react-icons/fa";
import UserWhatsapp from "../common/UserWhatsapp";
import UserEmail from "../common/UserEmail";
import { MdEmail } from "react-icons/md";
import UserWebsite from "../common/UserWebsite";
import UserFacebook from "../common/UserFacebook";
import UserInsta from "../common/UserInsta";
import UserTwitter from "../common/UserTwitter";
import UserShowLocation from "../common/UserShowLocation";
const Card25 = ({ data }) => {
  const fields = useSelector((state) => state.cardFiled);

  return (
    <div className={style.main}>
      <div className={style.profile_section}>
        <UserProfile
          data={data}
          fields={fields}
          profile={profile}
          deco={style.profile}
        />
      </div>

      <div className={style.name_section}>
        <UserShowName
          data={data}
          fields={fields}
          deco={style.name}
          name="Nora Charlotte"
        />
        <UserShowDesigination
          data={data}
          fields={fields}
          desigination="CEO/FOUNDER"
          deco={style.desigination}
        />
      </div>
      <div className={style.action}>
        <button className={style.btn}>
          <UserMobileNum data={data} fields={fields} mobile="">
            <FaMobileAlt className={style.icon_btn} />
          </UserMobileNum>
          <span className={style.title}>Call To Mobile</span>
        </button>
      </div>
      <div className={style.links1}>
        <UserWhatsapp data={data} fields={fields} whatsapp="">
          <div className={style.item}>
            <FaWhatsapp className={style.icon} />
            <span className={style.title}>Chat</span>
          </div>
        </UserWhatsapp>

        <UserEmail data={data} fields={fields} email="">
          <div className={style.item}>
            <MdEmail className={style.icon} />
            <span className={style.title}>Email</span>
          </div>
        </UserEmail>
      </div>

      <div className={style.links}>
        <UserWebsite data={data} fields={fields} website="">
          <FaInternetExplorer className={style.icon_link} />
        </UserWebsite>
        <UserFacebook data={data} fields={fields} facebook="">
          <FaFacebook className={style.icon_link} />
        </UserFacebook>
        <UserInsta data-={data} fields={fields} insta="">
          <FaInstagram className={style.icon_link} />
        </UserInsta>
        <UserTwitter data={data} fields={fields} twitter="">
          <FaTwitter className={style.icon_link} />
        </UserTwitter>
        <FaVimeo className={style.icon_link} />
      </div>
      <div className={style.contact}>
        <div className={style.heading}>CONTACT INFO</div>
        <UserShowLocation
          data={data}
          fields={fields}
          location="your123@gmail.com
www.yoursite.com
+555 564 7890"
          deco={style.location}
        />
      </div>
    </div>
  );
};

export default Card25;
