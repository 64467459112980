import React from "react";

const UserShowInsta = ({ data, fields, insta, deco }) => {
  return (
    <div className={deco}>
      {data?.link_insta
        ? data.link_insta
        : fields.link_insta
        ? fields.link_insta
        : insta}
    </div>
  );
};

export default UserShowInsta;
