import React, { useRef } from "react";
import style from "./wellcomeSec.module.css";
import { motion, useInView } from "framer-motion";

const WellcomeSec = () => {
  const ref = useRef(null);
  const inView = useInView(ref, { once: true });
  const boxVariant = {
    hidden: {
      y: 120,
      opacity: 0,
    },
    visible: { y: inView ? 0 : 120, opacity: inView ? 1 : 0 },
  };

  return (
    <div className={style.main}>
      <div className={style.pageContent}>
        <p className={style.pageT}>HOW TO USE?</p>
        <motion.p
          variants={boxVariant}
          initial="hidden"
          animate="visible"
          transition={{ duration: 1.5 }}
          className={style.pageD}
          ref={ref} // Apply the ref here
        >
          By following these guidelines, you can effectively use NFC cards
        </motion.p>
      </div>
    </div>
  );
};

export default WellcomeSec;
