import React from "react";
import style from "./card35.module.css";
import profile from "../../../../assets/cards/card34/profile.png";
import { useSelector } from "react-redux";
import UserProfile from "../common/user-profile-com/UserProfile";
import UserShowName from "../common/UserShowName";
import UserShowDesigination from "../common/UserShowDesigination";
import UserMobileNum from "../common/UserMobileNum";
import { FaInternetExplorer, FaMobileAlt } from "react-icons/fa";
import UserShowMobileNum from "../common/UserShowMobileNum";
import UserEmail from "../common/UserEmail";
import UserShowEmail from "../common/UserShowEmail";
import UserLocation from "../common/UserLocation";
import { FaLocationArrow } from "react-icons/fa6";
import UserShowLocation from "../common/UserShowLocation";
import UserWebsite from "../common/UserWebsite";
import UserShowWebsite from "../common/UserShowWebsite";
import { MdEmail } from "react-icons/md";
const Card35 = ({ data }) => {
  const fields = useSelector((state) => state.cardFiled);
  return (
    <div className={style.main}>
      <div className={style.header}>
        <div className={style.profile_section}>
          <UserProfile
            data={data}
            fields={fields}
            profile={profile}
            deco={style.profile}
          />
        </div>
      </div>
      <div className={style.name_section}>
        <UserShowName
          data={data}
          fields={fields}
          name="Rachael SMITH"
          deco={style.name}
        />
        <UserShowDesigination
          data={data}
          fields={fields}
          desigination="REALTOR"
          deco={style.desigination}
        />
      </div>
      <div className={style.links}>
        <UserMobileNum data={data} fields={fields} mobile="">
          <div className={style.item}>
            <FaMobileAlt className={style.icon} />
            <UserShowMobileNum
              data={data}
              fields={fields}
              mobile="+123 234 5544 765"
              deco={style.text}
            />
          </div>
        </UserMobileNum>
        <UserEmail data={data} fields={fields} email="">
          <div className={style.item}>
            <MdEmail className={style.icon} />
            <UserShowEmail
              data={data}
              fields={fields}
              email="mailyour@gmail.com"
              deco={style.text}
            />
          </div>
        </UserEmail>
      </div>
      <div className={style.links}>
        <UserLocation data={data} fields={fields} location="">
          <div className={style.item}>
            <FaLocationArrow className={style.icon} />
            <UserShowLocation
              data={data}
              fields={fields}
              location="123 street, Your Road,your State"
              deco={style.text}
            />
          </div>
        </UserLocation>
        <UserWebsite data={data} fields={fields} website="">
          <div className={style.item}>
            <FaInternetExplorer className={style.icon} />
            <UserShowWebsite
              data={data}
              fields={fields}
              website="www.yournet.com"
              deco={style.text}
            />
          </div>
        </UserWebsite>
      </div>

      <UserShowWebsite
        data={data}
        fields={fields}
        website="WWW.REALTOR.COM"
        deco={style.dark}
      />
    </div>
  );
};

export default Card35;
