import React from "react";
import style from "./card17.module.css";
import { IoCall } from "react-icons/io5";
import { FaInstagram, FaMobileAlt } from "react-icons/fa";
import { MdOutlineMailOutline } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa";
import { FaLocationArrow } from "react-icons/fa6";
import { MdDashboard } from "react-icons/md";
import { FaDownload } from "react-icons/fa";
import { FaShareAlt } from "react-icons/fa";
import { IoPerson } from "react-icons/io5";
import { IoIosArrowForward } from "react-icons/io";
import { FaInstagramSquare } from "react-icons/fa";
import { FaSpotify } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaMusic } from "react-icons/fa";
import { FaDeezer } from "react-icons/fa";
import profile from "../../../../assets/cards/card17/profile.png";
import UserProfile from "../common/user-profile-com/UserProfile";
import UserShowName from "../common/UserShowName";
import UserShowDesigination from "../common/UserShowDesigination";
import UserEmail from "../common/UserEmail";
import UserWhatsapp from "../common/UserWhatsapp";
import UserFacebook from "../common/UserFacebook";
import { FaFacebookF } from "react-icons/fa";
import UserTwitter from "../common/UserTwitter";
import { FaTwitter } from "react-icons/fa";
import UserLinkdin from "../common/UserLinkdin";
import { FaLinkedinIn } from "react-icons/fa";
import UserInsta from "../common/UserInsta";
import UserBioShow from "../common/UserBioShow";
import UserBanner from "../common/UserBanner";
import UserBanner2 from "../common/UserBanner2";
import banner from "../../../../assets/cards/card17/banner.jpg";
import banner1 from "../../../../assets/cards/card17/banner2.jpeg";
import { useSelector } from "react-redux";
const Card17 = ({ data }) => {
  const fields = useSelector((state) => state.cardFiled);
  return (
    <div className={style.main}>
      <div className={style.header}>
        <UserProfile
          data={data}
          fields={fields}
          profile={profile}
          deco={style.profile}
        />
      </div>

      <div className={style.name_section}>
        <UserShowName
          data={data}
          fields={fields}
          name="Jack Daniel"
          deco={style.name}
        />
        <UserShowDesigination
          data={data}
          fields={fields}
          desigination="CEO/Owner"
          deco={style.desigination}
        />
      </div>
      <div onCanPlay={style.contact}>
        <button className={style.btn}>+ Add To Contact</button>
      </div>
      <div className={style.link_button}>
        <UserEmail data={data} fields={fields} email="">
          <button className={style.link_btn}>
            <MdOutlineMailOutline className={style.icon1} />
            <span className={style.title}>Email</span>
          </button>
        </UserEmail>
        <UserWhatsapp data={data} fields={fields} whatsapp="">
          <button className={style.link_btn}>
            <FaWhatsapp className={style.icon1} />
            <span className={style.title}>Whatsapp</span>
          </button>
        </UserWhatsapp>
      </div>
      <div className={style.links}>
        <UserFacebook data={data} fields={fields} facebook="">
          <FaFacebookF className={style.icon} />
        </UserFacebook>

        <UserTwitter data={data} fields={fields} twitter="">
          <FaTwitter className={style.icon} />
        </UserTwitter>
        <UserLinkdin data={data} fields={fields} linkdin="">
          <FaLinkedinIn className={style.icon} />
        </UserLinkdin>
        <UserInsta data={data} fields={fields} insta="">
          <FaInstagram className={style.icon} />
        </UserInsta>
      </div>
      <div className={style.bar}></div>
      <div className={style.about}>
        <div className={style.about_title}>About Me</div>
        <UserBioShow
          data={data}
          fields={fields}
          bio="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo."
          deco={style.bio}
        />
      </div>
      <div className={style.banners}>
        <UserBanner
          data={data}
          fields={fields}
          banner={banner}
          deco={style.banner}
        />
        <UserBanner2
          data={data}
          fields={fields}
          banner2={banner1}
          deco={style.banner}
        />
      </div>
      <div className={style.footer}>All Rights Reserved</div>
    </div>
  );
};

export default Card17;
