import React from "react";

const UserBrandLogo = ({ data, fields, logo, deco }) => {
  return (
    <>
      <img
        className={deco}
        src={
          data?.user_brand_logo
            ? data.user_brand_logo
            : fields.user_brand_logo
            ? fields.user_brand_logo
            : logo
        }
      />
    </>
  );
};

export default UserBrandLogo;
