import React from "react";
import { useSelector } from "react-redux";
import style from "./card33.module.css";
import profile from "../../../../assets/cards/card32/profile.png";
import UserProfile from "../common/user-profile-com/UserProfile";
import UserShowName from "../common/UserShowName";
import UserShowDesigination from "../common/UserShowDesigination";
import UserCall from "../common/UserCall";
import UserMobileNum from "../common/UserMobileNum";
import UserEmail from "../common/UserEmail";
import { MdMail } from "react-icons/md";
import UserFacebook from "../common/UserFacebook";
import { IoCall } from "react-icons/io5";
import { FaMessage } from "react-icons/fa6";
import {
  FaFacebook,
  FaInstagram,
  FaInternetExplorer,
  FaSave,
  FaYoutube,
  FaTwitter,
} from "react-icons/fa";
import UserInsta from "../common/UserInsta";
import UserTwitter from "../common/UserTwitter";
import UserYoutube from "../common/UserYoutube";
import UserWebsite from "../common/UserWebsite";
const Card33 = ({ data }) => {
  const fields = useSelector((state) => state.cardFiled);

  return (
    <div className={style.main}>
      <div className={style.box}>
        <div className={style.profile_section}>
          <UserProfile
            data={data}
            fields={fields}
            profile={profile}
            deco={style.profile}
          />
        </div>
        <div className={style.name_section}>
          <UserShowName
            data={data}
            fields={fields}
            name="Peter Chan"
            deco={style.name}
          />
          <UserShowDesigination
            data={data}
            fields={fields}
            desigination="luxury Real Estate/Upgrade Specialist Real Estate Agent"
            deco={style.desigination}
          />
        </div>

        <div className={style.links}>
          <UserMobileNum data={data} fields={fields} mobile="">
            <div className={style.item}>
              <FaMessage className={style.icon} />
              <span className={style.text}>Text</span>
            </div>
          </UserMobileNum>
          <UserMobileNum data={data} fields={fields} mobile="">
            <div className={style.item}>
              <IoCall className={style.icon} />
              <span className={style.text}>Call</span>
            </div>
          </UserMobileNum>
          <UserEmail data={data} fields={fields} email="">
            <div className={style.item}>
              <MdMail className={style.icon} />
              <span className={style.text}>Email</span>
            </div>
          </UserEmail>
        </div>
      </div>
      <div className={style.box}>
        <div className={style.links}>
          <UserFacebook data={data} fields={fields} facebook="">
            <div className={style.item}>
              <FaFacebook className={style.icon} />
              <span className={style.text}>Facebook</span>
            </div>
          </UserFacebook>
          <UserInsta data={data} fields={fields} insta="">
            <div className={style.item}>
              <FaInstagram className={style.icon} />
              <span className={style.text}>Instagram</span>
            </div>
          </UserInsta>
          <UserTwitter data={data} fields={fields} twitter="">
            <div className={style.item}>
              <FaTwitter className={style.icon} />
              <span className={style.text}>Twitter</span>
            </div>
          </UserTwitter>
        </div>

        <div className={style.links}>
          <div className={style.item}>
            <FaSave className={style.icon} />
            <span className={style.text}>Save</span>
          </div>
          <UserYoutube data={data} fields={fields} youtube="">
            <div className={style.item}>
              <FaYoutube className={style.icon} />
              <span className={style.text}>Youtube</span>
            </div>
          </UserYoutube>
        </div>
      </div>
      <div className={style.footer}>
        <div className={style.bottom}>
          <UserWebsite data={data} fields={fields} website="">
            <div className={style.item2}>
              <FaInternetExplorer className={style.icon} />
              <span className={style.web}>Check Out My Website</span>
            </div>
          </UserWebsite>
        </div>
      </div>
    </div>
  );
};

export default Card33;
