import React from 'react'

import RegisterForm from '../components/user-account/register/RegisterForm'
const Register = () => {
  return (
    <div>
      <RegisterForm/>
    </div>
  )
}

export default Register
