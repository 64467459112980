import React from "react";
import style from "./ctabutton.module.css";
const CtaButton = ({ onClick, bg, title,animation,width,clr }) => {
  return (
    <div className={style.main}>
      <button  onClick={onClick} style={{ backgroundColor: bg,width:width,color:clr?clr:'' }} className={animation?style.animate:style.button} >
        {title}
      </button>
    </div>
  );
};

export default CtaButton;
