import React from "react";
import { CircularProgress } from "@mui/material";
import style from "./loadingSpinner.module.css";
const LoadingSpinner = () => {
  return (
    <div className={style.main}>
      <CircularProgress />
    </div>
  );
};

export default LoadingSpinner;
