import React from "react";
import style from "./textInputField.module.css";
import useUpdateCard from "../../../utils/useUpdateCard";

const UserTwitterLinkField = () => {
  const { dispatch, fieldActions } = useUpdateCard();
  const twitterChange = (event) => {
    dispatch(fieldActions.setUserTwitter(event.target.value));
  };
  return (
    <>
      <input
        type="text"
        placeholder="twitter account link"
        className={style.textInput}
        onChange={twitterChange}
      />
    </>
  );
};

export default UserTwitterLinkField;
