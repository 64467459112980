import React from "react";
import style from "./hero.module.css";
import CtaButton from "../ui/cta/CtaButton";
import { FaArrowRightLong } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

import { motion } from "framer-motion";

const Hero = () => {
  const navigate = useNavigate();
  const heroActionHAndler = () => {
    navigate("/shop");
  };

  return (
    <div className={style.main}>
      <section className={style.section}>
        <div className={style.item}>
          <motion.p
            initial={{ translateY: 0, opacity: 0 }}
            animate={{ translateY: -10, opacity: 1 }}
            transition={{ duration: 1 }}
            className={style.wideTitle}
          >
            LINK WISELY, NOT HARDER
          </motion.p>
          <motion.p
            initial={{ translateY: 200, opacity: 0 }}
            animate={{ translateY: 0, opacity: 1 }}
            transition={{ duration: 1.2 }}
            className={style.heiding}
          >
            <span>Share contact with</span>
            <br />
            <span>only one tap.</span>
          </motion.p>
          <motion.div
            initial={{ translateY: 200, opacity: 0 }}
            animate={{ translateY: 0, opacity: 1 }}
            transition={{ duration: 1.4 }}
            className={style.heroDetail}
          >
            <div className={style.veticalBar}></div>
            <div>
              <p className={style.description}>
                The Sustainable Solution for Next-Level Networking refers to
                environmentally friendly and forward-thinking approaches in the
                field of networking. It focuses on reducing environmental impact
                while adapting to the latest networking advancements.
              </p>
              <div className={style.actions}>
                <div>
                  <CtaButton
                    title="Get Started"
                    onClick={heroActionHAndler}
                    animation={true}
                    bg="white"
                    width="140px"
                  />
                  <div className={style.contactSec}>
                    <span className={style.contactTitle}>Contact Us</span>
                    <FaArrowRightLong className={style.arrowIcon} />
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
        <div className={style.item}></div>
      </section>
    </div>
  );
};

export default Hero;
