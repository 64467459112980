import { Link, Outlet } from "react-router-dom";

const UserEmail = ({ data, fields, email, children }) => {
  // Added 'children' prop
  return (
    <Link
      to={
        data?.link_email
          ? data.link_email
          : fields.link_email
          ? fields.link_email
          : email
      }
    >
      <Outlet />
      {children}
    </Link>
  );
};

export default UserEmail;
