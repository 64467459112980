import React from "react";

const UserShowEmail = ({ data, fields, email, deco }) => {
  return (
    <div className={deco}>
      {data?.link_email
        ? data.link_email
        : fields.link_email
        ? fields.link_email
        : email}
    </div>
  );
};

export default UserShowEmail;
