import React from 'react'
import LoginForm from '../components/user-account/login/LoginForm'
const Login = () => {
  return (
    <div  >
      <LoginForm/>
    </div>
  )
}

export default Login
