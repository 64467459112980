import React from "react";
import style from "./textInputField.module.css";
import useUpdateCard from "../../../utils/useUpdateCard";

const UserSnapchatLinkField = () => {
  const { dispatch, fieldActions } = useUpdateCard();
  const snapchatChange = (event) => {
    dispatch(fieldActions.setUserSnapchat(event.target.value));
  };
  return (
    <>
      <input
        type="text"
          placeholder="linkdin account link"
        className={style.textInput}
        onChange={snapchatChange}
      />
    </>
  );
};

export default UserSnapchatLinkField;
