import React from "react";
import UserNameFiled from "../../../ui/card-fileds/UserNameFiled";
import UserWebsiteLinkField from "../../../ui/card-fileds/UserWebsiteLinkField";
import UserBrandPictureField from "../../../ui/card-fileds/UserBrandPictureField";
import UserProfileImgField from "../../../ui/card-fileds/UserProfileImgField";
import UserDesginationField from "../../../ui/card-fileds/UserDesginationField";
import UserBioField from "../../../ui/card-fileds/UserBioField";
import UserPaypalLinkField from "../../../ui/card-fileds/UserPaypalLinkField";
import UserLinkdinLinkField from "../../../ui/card-fileds/UserLinkdinLinkField";
import PaymentModalOpner from "./payment-model-opner/PaymentModalOpner";

const CardForm6 = () => {
  return (
    <div>
      <UserNameFiled />
      <UserDesginationField />
      <UserBioField />

      <UserPaypalLinkField />
      <UserWebsiteLinkField />
      <UserLinkdinLinkField />
      <div style={{ display: "flex" }}>
        <UserBrandPictureField />
        <UserProfileImgField />
      </div>
      <PaymentModalOpner />
    </div>
  );
};

export default CardForm6;
