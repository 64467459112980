import React from "react";
import style from "./textInputField.module.css";
import useUpdateCard from "../../../utils/useUpdateCard";

const UserFacebookLinkField = () => {
  const { dispatch, fieldActions } = useUpdateCard();
  const facebookChange = (event) => {
    dispatch(fieldActions.setUserFacebook(event.target.value));
  };
  return (
    <>
      <input
        type="text"
           placeholder="facebook account link"
        className={style.textInput}
        onChange={facebookChange}
      />
    </>
  );
};

export default UserFacebookLinkField;
