import React from "react";
import useUpdateCard from "../../../utils/useUpdateCard";
import style from "./textInputField.module.css";
const UserHomeAddressField = () => {
  const { dispatch, fieldActions } = useUpdateCard();

  const addressChange = (event) => {
    dispatch(fieldActions.setUserAddress(event.target.value));
  };

  return (
    <>
      <input
        type="text"
          placeholder="enter your address"
        className={style.textInput}
        onChange={addressChange}
      />
    </>
  );
};

export default UserHomeAddressField;
