import React, { useRef } from "react";
import { useInView, motion } from "framer-motion";
import style from "./homehowWork.module.css";
import { FaRegFileAlt } from "react-icons/fa";
import { BsPerson } from "react-icons/bs";
import { CgCreditCard } from "react-icons/cg";
import card from "../../../assets/home-how-it-work-section/card.jpg";
const HomeHowWrokSec = () => {
  const ref = useRef();
  const inView = useInView(ref, { once: true });
  const boxVarients = {
    hidden: { y: 400, opacity: 0 },
    visible: {
      y: inView ? 0 : 400,
      opacity: inView ? 1 : 0,

      transition: {
        duration: 1.5,
      },
    },
  };

  const data = [
    {
      icon: <FaRegFileAlt />,
      title: "Step 01",
      boxTitle: "Choose your favorite product",
      boxDetail:
        "Choose from a variety of smart products tailored to fit your lifestyle, brand and needs.",
    },
    {
      icon: <BsPerson />,
      title: "Step 02",
      boxTitle: "Set up your Profile",
      boxDetail:
        "Once you’ve activated your product, you’re all set! Share your profile using your product or in-app QR code.",
    },
    {
      icon: <CgCreditCard />,
      title: "Step 03",
      boxTitle: "Share it with Everyone",
      boxDetail:
        "Activate your product and you’re all set!  Share your profile using your  product or in-app QR code.",
    },
  ];

  return (
    <div className={style.main}>
      <motion.section
        variants={boxVarients}
        initial="hidden"
        animate="visible"
        ref={ref}
        className={style.section}
      >
        <div className={style.row}>
          <div className={style.col1}>
            <div className={style.imgSection}>
              <img src={card} alt="card" className={style.img} />
            </div>
          </div>
          <div className={style.col2}>
            <p className={style.pageTitle}>HOW WE WORK</p>
            <p className={style.pageDetail}>Enabling Global Technology</p>
            <div className={style.col2Row}>
              {data.map((item, index) => {
                return (
                  <div key={index} className={style.innerCol}>
                    <p className={style.title}>{item.title}</p>
                    <span className={style.icon}>{item.icon}</span>
                    <div className={style.box}>
                      <p className={style.boxTitle}>{item.boxTitle}</p>
                      <p className={style.boxDetail}>{item.boxDetail}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className={style.imgSectionM}>
            <img src={card} alt="card" className={style.imgM} />
          </div>
        </div>
      </motion.section>
    </div>
  );
};

export default HomeHowWrokSec;
