import React from "react";

const UserName = ({ data, fields, name, deco }) => {
  return (
    <div className={deco}>
      {data?.user_name
        ? data.user_name
        : fields.user_name
        ? fields.user_name
        : name}
    </div>
  );
};

export default UserName;
