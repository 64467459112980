import React, { useRef } from "react";
import style from "./herosec.module.css";
import { useInView, motion } from "framer-motion";


const HeroSec = ({pageTitle,pageDetail}) => {
  const viewRef = useRef();
  const inView = useInView(viewRef, { once: true });

  const boxVarient = {
    hidden: {
      y: 60,
      opacity: 0,
    },
    visible: {
      y: inView ? 0 : 60,
      opacity: inView ? 1 : 0,
      transition: { duration: 1 },
    },
  };

  return (
    <div className={style.main}>
      <section className={style.section}>
        <div>
          <motion.p
            initial={{ y: 60, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 1 }}
            className={style.pTitle}
          >
            {pageTitle}
          </motion.p>
          <p className={style.pDetail}>
            {pageDetail}
          </p>
        </div>
      </section>
    </div>
  );
};

export default HeroSec;
