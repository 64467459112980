import React from "react";
import style from "./card14.module.css";
import profile from "../../../../assets/cards/card14/profile.jpg";
import logo from "../../../../assets/cards/card14/icon.png";
import UserProfile from "../common/user-profile-com/UserProfile";
import { useSelector } from "react-redux";
import UserShowName from "../common/UserShowName";
import UserShowDesigination from "../common/UserShowDesigination";
import UserBrandLogo from "../common/user-logo-com/UserBrandLogo";
import { FaLinkedinIn } from "react-icons/fa";
import { IoCall } from "react-icons/io5";
import { FaMobileAlt } from "react-icons/fa";
import { MdOutlineMailOutline } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa";
import { FaLocationArrow } from "react-icons/fa6";
import { MdDashboard } from "react-icons/md";
import { FaDownload } from "react-icons/fa";
import { FaShareAlt } from "react-icons/fa";
import { IoPerson } from "react-icons/io5";
import { IoIosArrowForward } from "react-icons/io";
import UserMobileField from "../../../ui/card-fileds/UserMobileField";
import UserMobileNum from "../common/UserMobileNum";
import UserEmail from "../common/UserEmail";
import UserWhatsapp from "../common/UserWhatsapp";
import UserLinkdin from "../common/UserLinkdin";
import UserLocation from "../common/UserLocation";
const Card14 = ({ data }) => {
  const fields = useSelector((state) => state.cardFiled);
  return (
    <div className={style.main}>
      <div className={style.profile_section}>
        <UserProfile
          data={data}
          fields={fields}
          profile={profile}
          deco={style.profile}
        />
      </div>
      <div className={style.name_section}>
        <UserShowName
          data={data}
          fields={fields}
          name="Emma Ava"
          deco={style.name}
        />
        <UserShowDesigination
          data={data}
          fields={fields}
          desigination="CHIEF TECHNOLOGY OFFICER"
          deco={style.desigination}
        />
      </div>
      <div className={style.logo_section}>
        <UserBrandLogo
          data={data}
          fields={fields}
          logo={logo}
          deco={style.logo}
        />
      </div>
      <div className={style.links}>
        <UserMobileNum data={data} fields={fields} mobile="">
          <div className={style.item}>
            <IoCall className={style.icon} />
            <span className={style.titl}>Office</span>
          </div>
        </UserMobileNum>
        <UserMobileNum data={data} fields={fields} mobile="">
          <div className={style.item}>
            <FaMobileAlt className={style.icon} />
            <span className={style.titl}>Mobile</span>
          </div>
        </UserMobileNum>

        <UserEmail data={data} fields={fields} email="">
          <div className={style.item}>
            <MdOutlineMailOutline className={style.icon} />
            <span className={style.titl}>Email</span>
          </div>
        </UserEmail>
      </div>
      <div className={style.links}>
        <UserWhatsapp data={data} fields={fields} whatsapp="">
          <div className={style.item}>
            <FaWhatsapp className={style.icon} />
            <span className={style.titl}>Whatsapp</span>
          </div>
        </UserWhatsapp>
        <UserLinkdin data={data} fields={fields} linkdin="">
          <div className={style.item}>
            <FaLinkedinIn className={style.icon} />
            <span className={style.titl}>Linkedin</span>
          </div>
        </UserLinkdin>
        <UserLocation data={data} fields={fields} location="">
          <div className={style.item}>
            <FaLocationArrow className={style.icon} />
            <span className={style.titl}>Location</span>
          </div>
        </UserLocation>
      </div>
      <div className={style.action}>
        <button className={style.btn}>
          <span>Get cloudCard</span>
          <IoIosArrowForward className={style.arrow} />
        </button>
      </div>
      <div className={style.bottom_icon}>
        <FaDownload className={style.b_icon} />
        <MdDashboard className={style.b_icon} />
        <FaShareAlt className={style.b_icon} />
        <UserEmail data={data} fields={fields} email="">
          <MdOutlineMailOutline className={style.b_icon} />
        </UserEmail>
        <IoPerson className={style.b_icon} />
      </div>
      <div className={style.footer}>All Rights Reserved</div>
    </div>
  );
};

export default Card14;
