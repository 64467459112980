import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  user_name: "",
  user_designation: "",
  user_post: "",
  user_bio: "",
  link_facebook: "",
  link_mobile: "",
  link_whatsapp: "",
  link_twitter: "",
  link_youtube: "",
  link_insta: "",
  user_profile: "",
  card_index: "",

  // fields start for card NO 1
  user_brand_logo: "",

  link_email: "",
  link_website: "",
  user_home_address: "",
  link_tiktok: "",
  link_linkdin: "",
  link_snapchat: "",
  link_pintrest: "",

  // links more from above
  link_location: "",
  link_google: "",
  Link_paypal: "",


  // user banners
user_banner:'',
user_banner2:'',
user_banner3:''

};






export const resetState = createAction("cardFiled/resetState");

const filedSlice = createSlice({
  name: "cardFiled",
  initialState: initialState,
  reducers: {
    setUserName(state, action) {
      state.user_name = action.payload;
    },
    setUserDesignation(state, action) {
      state.user_designation = action.payload;
    },

    setUserPost(state, action) {
      state.user_post = action.payload;
    },
    setUserBio(state, action) {
      state.user_bio = action.payload;
    },
    setUserMobile(state, action) {
      state.link_mobile = action.payload;
    },
    setUserWhatsapp(state, action) {
      state.link_whatsapp = action.payload;
    },
    setUserFacebook(state, action) {
      state.link_facebook = action.payload;
    },
    setUserTwitter(state, action) {
      state.link_twitter = action.payload;
    },
    setUserYoutube(state, action) {
      state.link_youtube = action.payload;
    },
    setUserInsta(state, action) {
      state.link_insta = action.payload;
    },
    setUserProfileImg(state, action) {
      state.user_profile = action.payload;
    },

    // actions for card NO 1

    setUserAddress(state, action) {
      state.user_home_address = action.payload;
    },
    setUserWebsite(state, action) {
      state.link_website = action.payload;
    },
    setUserEmail(state, action) {
      state.link_email = action.payload;
    },
    setUserBrnadLogo(state, action) {
      state.user_brand_logo = action.payload;
    },

    setUserTiktok(state, action) {
      state.link_tiktok = action.payload;
    },
    setUserPintrest(state, action) {
      state.link_pintrest = action.payload;
    },
    setUserLinkdin(state, action) {
      state.link_linkdin = action.payload;
    },
    setUserSnapchat(state, action) {
      state.link_snapchat = action.payload;
    },
    setCardIndex(state, action) {
      state.card_index = action.payload;
    },

    // actions for card 5

    setLocation(state, action) {
      state.link_location = action.payload;
    },
    setGoole(state, action) {
      state.link_google = action.payload;
    },
    setPaypal(state, action) {
      state.Link_paypal = action.payload;
    },

    setUserBanner(state,action){
      state.user_banner = action.payload
    },
    setUserBanner2(state,action){
      state.user_banner2 = action.payload
    },
    setUserBanner3(state,action){
      state.user_banner3 = action.payload
    },


    resetAllFields(state) {
      // Reset the state to the initial state object
      Object.assign(state, initialState);
    },
  },
});

export const fieldActions = filedSlice.actions;
export default filedSlice;
