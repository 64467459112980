import React from "react";
import style from "./contactForm.module.css";
import CtaButton from "../../ui/cta/CtaButton";
import {
  FaFacebookF,
  FaYoutube,
  FaLinkedinIn,
  FaTwitter,
} from "react-icons/fa";
const ContactForm = () => {
  const submitHandler = (event) => {
    event.preventDefault();
  };

  return (
    <div className={style.main}>
      <section className={style.section}>
        <form onSubmit={submitHandler}>
          <div className={style.row}>
            <div className={style.col}>
              <p className={style.cTitle}>CONTACT</p>
              <p className={style.cTouchTitle}>Get In Touch</p>
              <div className={style.pair}>
                <span className={style.pairItem1}>Address:</span>
                <span className={style.pairItem2}>
                  2nd Floor, 206A Wilgoda Estate Rd,
                </span>
              </div>
              <div className={style.pair}>
                <span className={style.pairItem1}>Phone:</span>
                <span className={style.pairItem2}>077 552 4866</span>
              </div>
              <div className={style.pair}>
                <span className={style.pairItem1}>Email:</span>
                <span className={style.pairItem2}>infivellc@gmail.com</span>
              </div>
              <div className={style.bar}></div>

              <div className={style.icons}>
                <FaFacebookF className={style.icon1} />
                <FaYoutube className={style.icon2} />
                <FaLinkedinIn className={style.icon3} />
                <FaTwitter className={style.icon4} />
              </div>
            </div>
            <div className={style.col}>
              <div className={style.items}>
                <div className={style.formPair}>
                  <label>Name</label>
                  <input
                    required
                    className={style.input}
                    type="text"
                    placeholder="Name"
                  />
                </div>
                <div className={style.formPair}>
                  <label>Email</label>
                  <input
                    required
                    className={style.input}
                    type="email"
                    placeholder="Email"
                  />
                </div>
                <div className={style.formPair}>
                  <label>Message</label>
                  <textarea
                    required
                    className={style.tArea}
                    rows={6}
                    type="text"
                    placeholder="Message"
                  />
                </div>
                <CtaButton
                  title="Send"
                  bg="black"
                  clr="white"
                  width="100%"
                  type="submit"
                />
              </div>
            </div>
          </div>
        </form>
      </section>
    </div>
  );
};

export default ContactForm;
