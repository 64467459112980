import React from "react";
import HeroSec from "../about-us/hero-sec/HeroSec";
import WellcomeSec from "./wellcomeSec/WellcomeSec";
import style from "./hoetoUse.module.css";
import StepSec from "./step-sec/StepSec";
const HowToUse = () => {
  return (
    <div className={style.main}>
      <HeroSec
        pageDetail="Using NFC (Near Field Communication) cards is fairly straightforward. Here’s a general  guide on how to use them:"
        pageTitle="How To Use ?"
      />
      <WellcomeSec />
      <StepSec />
    </div>
  );
};

export default HowToUse;
