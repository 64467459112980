import { configureStore } from "@reduxjs/toolkit";
import filedSlice from "./slices/card-fields-slice";

import authSlice from "./slices/auth-slice";
import uiSlice from "./slices/ui-slice";

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    ui: uiSlice.reducer,
    cardFiled: filedSlice.reducer,
  },
});

export default store;
