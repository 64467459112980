import React from "react";

const UserShowDesigination = ({ data, fields, desigination, deco }) => {
  return (
    <div className={deco}>
      {data?.user_designation
        ? data.user_designation
        : fields.user_designation
        ? fields.user_designation
        : desigination}
    </div>
  );
};

export default UserShowDesigination;
