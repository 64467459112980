import React from "react";
import style from "./hometestimonialSec.module.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Rating, Slide } from "@mui/material";
import { RiDoubleQuotesR } from "react-icons/ri";
import girl1 from "../../../assets/home-testimonial-section/girl1.jpg";
import girl2 from "../../../assets/home-testimonial-section/girl2.jpg";
import girl3 from "../../../assets/home-testimonial-section/girl3.jpg";

const HomeTestimonialSec = ({ bg, text, light,quote }) => {
  const data = [
    {
      name: "Tinna Torres",
      des: "Designer",
      img: girl1,
      rating: <Rating readOnly defaultValue={5} color="yellow" />,
      detail:
        "Choosing  for NFC card solutions has been a game-changer for my business. Their attention to quality and security is commendable. I can't recommend them enough!",
    },
    {
      name: "Wanda Franses",
      des: "Teacher",
      img: girl2,
      rating: <Rating readOnly defaultValue={5} color="yellow" />,
      detail:
        "The NFC cards from have simplified my daily transactions. I love the convenience, and the peace of mind knowing my information is secure. Great job!",
    },
    {
      name: "Kate Washington",
      des: "Manager",
      img: girl3,

      rating: <Rating readOnly defaultValue={5} color="yellow" />,
      detail:
        "For our large events, we rely on  for NFC technology. It has reduced wait times and improved the overall attendee experience. They offer top-notch service and support.",
    },
  ];

  const settings = {
    infinite: true,
    slidesToShow: window.innerWidth > 1000 ? 3 : 1,
    speed: 500,
    swipeToSlide: true,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <div style={{ backgroundColor: bg ? bg : "" }} className={style.main}>
      <section className={style.section}>
        <p style={{ color: text ? text : "" }} className={style.pageDetail}>
          What Our Customer Say About Us
        </p>
        <p style={{ color: text ? text : "" }} className={style.pageTitle}>
          TESTIMONIAL
        </p>
        <div className={style.slider}>
          <Slider {...settings}>
            {data.map((item, index) => {
              return (
                <div className={style.slide} key={index}>
                  <span>{item.rating}</span>
                  <p
                    style={{ color: text ? text : "" }}
                    className={style.detail}
                  >
                    {item.detail}
                  </p>
                  <div className={style.userDetail}>
                    <div className={style.col1}>
                      <div className={style.profileSec}>
                        <img src={item.img} alt="user" className={style.img} />
                      </div>
                      <div>
                        <p
                          style={{ color: text ? text : "" }}
                          className={style.name}
                        >
                          {item.name}
                        </p>
                        <p
                          style={{ color: light ? light : "" }}
                          className={style.des}
                        >
                          {item.des}
                        </p>
                      </div>
                    </div>
                    <RiDoubleQuotesR style={{color:quote?quote:''}} className={style.quote} />
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </section>
    </div>
  );
};

export default HomeTestimonialSec;
