import React from "react";

const UserBanner = ({ data, fields, banner, deco }) => {
  return (
    <>
      <img
        className={deco}
        src={
          data?.user_banner
            ? data.user_banner
            : fields.user_banner
            ? fields.user_banner
            : banner
        }
      />
    </>
  );
};

export default UserBanner;
