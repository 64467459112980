import React from "react";

const UserShowLinkdin = ({ data, fields, linkdin, deco }) => {
  return (
    <div className={deco}>
      {data?.link_linkdin
        ? data.link_linkdin
        : fields.link_linkdin
        ? fields.link_linkdin
        : linkdin}
    </div>
  );
};

export default UserShowLinkdin;
