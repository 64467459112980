import { uiActions } from "../ui-slice";

const httpAction = (data) => async (dispatch) => {
  try {
    dispatch(uiActions.setError(null));
    dispatch(uiActions.setLoading(true));

    const response = await fetch(data.url, {
      method: data.method ? data.method : "GET",
      body: data.formdata
        ? data.formdata
        : data.body
        ? JSON.stringify(data.body)
        : null,
      headers: data.formdata
        ? {}
        : data.token
        ? {
            "Content-Type": "application/json",
            Authorization: `Bearer ${data.token}`,
          }
        : { "Content-Type": "application/json" },
    });

    const resData = await response.json();

    if (!response.ok) {
      console.log(resData.message);
    }
    return resData;
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(uiActions.setLoading(false));
  }
};

export default httpAction;
