import { Link, Outlet } from "react-router-dom";

const UserGoogle = ({ data, fields, google, children }) => {
  // Added 'children' prop
  return (
    <Link
      to={
        data?.link_google
          ? data.link_google
          : fields.link_google
          ? fields.link_google
          : google
      }
    >
      <Outlet />
      {children}
    </Link>
  );
};

export default UserGoogle;
