import { Link, Outlet } from "react-router-dom";

const UserInsta = ({ data, fields, insta, children }) => {
  // Added 'children' prop
  return (
    <Link
      to={
        data?.link_insta
          ? data.link_insta
          : fields.link_insta
          ? fields.link_insta
          : insta
      }
    >
      <Outlet />
      {children}
    </Link>
  );
};

export default UserInsta;
