import React from "react";
import style from "./card12.module.css";
import { IoCall } from "react-icons/io5";
import { FaWhatsapp } from "react-icons/fa";
import { FaLocationArrow } from "react-icons/fa6";
import { FaTwitter } from "react-icons/fa";
import { FaEnvelopeOpenText } from "react-icons/fa";
import { FaPlus } from "react-icons/fa";
import profile from "../../../../assets/cards/card12/profile.png";
import logo from "../../../../assets/cards/card12/logo.png";
import UserProfile from "../common/user-profile-com/UserProfile";
import UserShowName from "../common/UserShowName";
import UserShowDesigination from "../common/UserShowDesigination";
import UserMobileNum from "../common/UserMobileNum";
import UserWhatsapp from "../common/UserWhatsapp";
import UserLocation from "../common/UserLocation";
import UserEmail from "../common/UserEmail";
import UserTwitter from "../common/UserTwitter";
import UserBrandLogo from "../common/user-logo-com/UserBrandLogo";
import { useSelector } from "react-redux";
const Card12 = ({ data }) => {
  const fields = useSelector((state) => state.cardFiled);
  return (
    <div className={style.main}>
      <div className={style.header}>
        <div className={style.profile_section}>
          <UserProfile
            data={data}
            fields={fields}
            profile={profile}
            deco={style.profile}
          />
        </div>
      </div>
      <div className={style.hero}>
        <div className={style.user_info}>
          <UserShowName
            data={data}
            fields={fields}
            name="Carol Jefferson"
            deco={style.name}
          />
          <UserShowDesigination
            data={data}
            fields={fields}
            desigination="Owner/CEO"
            deco={style.desigination}
          />
        </div>
        <div className={style.links}>
          <UserMobileNum data={data} fields={fields} mobile="">
            <IoCall className={style.icon} />
          </UserMobileNum>
          <UserWhatsapp data={data} fields={fields} whatsapp="">
            <FaWhatsapp className={style.icon} />
          </UserWhatsapp>
          <UserLocation data={data} fields={fields} location="">
            <FaLocationArrow className={style.icon} />
          </UserLocation>
        </div>
        <div className={style.links}>
          <UserEmail data={data} fields={fields} email="">
            <FaEnvelopeOpenText className={style.icon} />
          </UserEmail>
          <FaPlus className={style.icon} />
          <UserTwitter data={data} fields={fields} twitter="">
            <FaTwitter className={style.icon} />
          </UserTwitter>
        </div>
        <div className={style.logo_section}>
          <UserBrandLogo
            data={data}
            fields={fields}
            logo={logo}
            deco={style.logo}
          />
        </div>
        <div className={style.footer}>All Rights Reserved</div>
      </div>
    </div>
  );
};

export default Card12;
