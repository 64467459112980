import { useDispatch } from "react-redux";
import { fieldActions } from "../store/slices/card-fields-slice";
const useUpdateCard = () => {
  const dispatch = useDispatch();

  return {
    dispatch,
    fieldActions,
  };
};

export default useUpdateCard;
