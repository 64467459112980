import React from "react";

const Map = () => {
  return (
    <div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15822.207212934914!2d80.226928!3d7.514626000000001!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae33a13fb186b91%3A0x802ab88e7f5c965d!2s2nd%20Floor%2C%20206%20Wilgoda%20Estate%20Rd%2C%20Kurunegala%2C%20Sri%20Lanka!5e0!3m2!1sen!2sus!4v1713803023014!5m2!1sen!2sus"
        width="100%"
        height="450"
        style={{ border: "0" }}
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default Map;
