import React from "react";

const UserBanner3 = ({ data, fields, banner3, deco }) => {
  return (
    <>
      <img
        className={deco}
        src={
          data?.user_banner3
            ? data.user_banner3
            : fields.user_banner3
            ? fields.user_banner3
            : banner3
        }
      />
    </>
  );
};

export default UserBanner3;
