import React from "react";
import useUpdateCard from "../../../utils/useUpdateCard";
import style from "./textInputField.module.css";
const UserBioField = () => {
  const { dispatch, fieldActions } = useUpdateCard();

  const bioChange = (event) => {
    dispatch(fieldActions.setUserBio(event.target.value));
  };

  return (
    <>
      <input
        type="text"

        placeholder="enter bio,(about your-self)"
        className={style.textInput}
        onChange={bioChange}
      />
    </>
  );
};

export default UserBioField;
