import apis from "../../../utils/apis";
import httpAction from "./httpAction";
const sendCreateCardPicture = async (data, dispatch) => {
  const apisList = apis();

  const formData = new FormData();
  formData.append("image", data);

  const data2 = {
    url: apisList.uploadCardImg,
    method: "POST",
    formdata: formData,
  };

  const result = await dispatch(httpAction(data2));
  return result
};

export default sendCreateCardPicture;
