import React, { useRef } from "react";
import style from "./wellcomeSec.module.css";
import { useInView, motion } from "framer-motion";

const WellcomeSec = () => {
  const viewRef = useRef();
  const inView = useInView(viewRef, { once: true });

  const boxVariant = {
    hidden: {
      y: 100,
      opacity: 0,
    },
    visible: {
      y: inView ? 0 : 100,
      opacity: inView ? 1 : 0,
      transition: { duration: 1.4 },
    },
  };

  const childVariant = {
    hidden: { y: 400, opacity: 0 },
    visible: { y: 0, opacity: 1.4 },
  };

  return (
    <div className={style.main}>
      <motion.section
        variants={boxVariant}
        initial="hidden"
        animate="visible"
        ref={viewRef}
        className={style.section}
      >
        <div className={style.pageDetail}>
          <p className={style.pageT}>ABOUT US</p>
          <p className={style.pageD1}>
            Welcome to Tapilinq, where innovation meets
          </p>
          <p className={style.pageD2}>
            connectivity in the world of business cards.
          </p>
        </div>
        <motion.div
          className={style.row}
          variants={boxVariant} // You can adjust the variants if needed
          initial="hidden"
          animate="visible"
          transition={{ staggerChildren: 0.4 }} // Adjust the stagger duration as needed
        >
          <motion.div variants={childVariant} className={style.col}>
            <p className={style.colDetail}>
              At Tapilinq, we believe that traditional business cards are
              evolving, and we’re at the forefront of this evolution. With the
              power of Near Field Communication (NFC) technology, we’re
              revolutionizing the way you share your professional identity.
            </p>
            <p className={style.bar}></p>
          </motion.div>
          <motion.div variants={childVariant} className={style.col}>
            <p className={style.colDetail}>
              Gone are the days of fumbling through stacks of paper cards. With
              our NFC business cards, you can effortlessly exchange contact
              information, portfolio links, social media profiles, and more,
              with just a tap of your card to a smartphone.
            </p>
            <p className={style.bar}></p>
          </motion.div>
        </motion.div>
      </motion.section>
    </div>
  );
};

export default WellcomeSec;
