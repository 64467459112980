import React from "react";
import style from "./card32.module.css";
import UserProfile from "../common/user-profile-com/UserProfile";
import profile from "../../../../assets/cards/card31/profile.png";
import UserShowName from "../common/UserShowName";
import UserShowDesigination from "../common/UserShowDesigination";
import UserEmail from "../common/UserEmail";
import { MdMail } from "react-icons/md";
import UserMobileNum from "../common/UserMobileNum";
import { useSelector } from "react-redux";
import { IoCall } from "react-icons/io5";
import UserWebsite from "../common/UserWebsite";
import {
  FaFacebook,
  FaInstagram,
  FaInternetExplorer,
  FaTwitch,
  FaTwitter,
} from "react-icons/fa";
import UserInsta from "../common/UserInsta";
import UserTwitter from "../common/UserTwitter";
import UserFacebook from "../common/UserFacebook";
const Card32 = ({ data }) => {
  const fields = useSelector((state) => state.cardFiled);
  return (
    <div className={style.main}>
      <div className={style.header}>
        <div className={style.profile_section}>
          <UserProfile
            data={data}
            fields={fields}
            profile={profile}
            deco={style.profile}
          />
        </div>
      </div>

      <div className={style.name_section}>
        <UserShowName
          data={data}
          fields={fields}
          name="Kelli Luna"
          deco={style.name}
        />
        <UserShowDesigination
          data={data}
          fields={fields}
          desigination="Director/Founder"
          deco={style.desigination}
        />
      </div>
      <div className={style.links}>
        <UserEmail data={data} fields={fields} email="">
          <div className={style.item}>
            <MdMail className={style.icon} />
            <span className={style.text}>Email</span>
          </div>
        </UserEmail>
        <UserMobileNum data={data} fields={fields} mobile="">
          <div className={style.item}>
            <IoCall className={style.icon} />
            <span className={style.text}>Call</span>
          </div>
        </UserMobileNum>
        <UserWebsite data={data} fields={fields} website="">
          <div className={style.item}>
            <FaInternetExplorer className={style.icon} />
            <span className={style.text}>Website</span>
          </div>
        </UserWebsite>
      </div>
      <div className={style.contact_action}>
        <button className={style.save_btn}>Save Contact</button>
      </div>
      <div className={style.folow}>follow us on</div>
      <UserInsta data={data} fields={fields} insta="">
        <div className={style.link_item}>
          <FaInstagram className={style.link_icon} />
          <span className={style.link_title}>Instagram</span>
        </div>
      </UserInsta>
      <UserTwitter data={data} fields={fields} twitter="">
        <div className={style.link_item}>
          <FaTwitter className={style.link_icon} />
          <span className={style.link_title}>Twitter</span>
        </div>
      </UserTwitter>
      <UserFacebook data={data} fields={fields} FaFacebook="">
        <div className={style.link_item}>
          <FaFacebook className={style.link_icon} />
          <span className={style.link_title}> Facebook </span>
        </div>
      </UserFacebook>
    </div>
  );
};

export default Card32;
