import React from "react";

const UserShowTwitter = ({ data, fields, twitter, deco }) => {
  return (
    <div className={deco}>
      {data?.link_twitter
        ? data.link_twitter
        : fields.link_twitter
        ? fields.link_twitter
        : twitter}
    </div>
  );
};

export default UserShowTwitter;
