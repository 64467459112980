import React, { useState } from "react";
import style from "./homefaqs.module.css";
import SingleFaq from "./SingleFaq";

const HomeFaqsSec = () => {
  const data = [
    {
      title: "01. What is NFC technology, and how does it work?",
      detail:
        "NFC stands for Near Field Communication, which is a wireless communication technology that allows for short-range data exchange between devices, typically within a few centimeters. It enables contactless transactions and data transfer.",
    },

    {
      title: "02. What are NFC cards, and what are they used for?",
      detail:
        "NFC cards are small, contactless cards embedded with NFC technology. They are used for various applications, including contactless payments, access control, public transport, and data sharing.",
    },
    {
      title: "03. How secure are NFC cards for payments and access control?",
      detail:
        "NFC cards are known for their high level of security. They use encryption and authentication protocols to protect data, making them secure for payments and access control.",
    },
    {
      title:
        "04. Can NFC cards be customized with our branding or information?",
      detail:
        "Yes, NFC cards can be customized with your branding, logo, or specific information. We offer customization services to meet your needs.",
    },
    {
      title:
        "05. How can I integrate NFC card systems into my existing infrastructure?",
      detail:
        "Our team provides guidance and support for seamless integration. We work with your existing systems to ensure a smooth transition.",
    },
  ];

  return (
    <div className={style.main}>
      <section className={style.section}>
        <p className={style.pageTitle}>FAQS</p>
        <p className={style.pageDetail} >Frequently Asked Questions</p>
        <div className={style.faq}>
          {data.map((item, index) => (
            <SingleFaq title={item.title} detail={item.detail} key={index} />
          ))}
        </div>
      </section>
    </div>
  );
};

export default HomeFaqsSec;
