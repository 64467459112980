import { Link, Outlet } from "react-router-dom";

const UserPaypal = ({ data, fields, paypal, children }) => {
  // Added 'children' prop
  return (
    <Link
      to={
        data?.link_paypal
          ? data.link_paypal
          : fields.link_paypal
          ? fields.link_paypal
          : paypal
      }
    >
      <Outlet />
      {children}
    </Link>
  );
};

export default UserPaypal;
