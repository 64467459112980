import React from "react";

const UserBanner2 = ({ data, fields, banner2, deco }) => {
  return (
    <>
      <img
        className={deco}
        src={
          data?.user_banner2
            ? data.user_banner2
            : fields.user_banner2
            ? fields.user_banner2
            : banner2
        }
      />
    </>
  );
};

export default UserBanner2;
