import React from "react";
import step1 from "../../../assets/how-to-use-page/step1.jpg";
import step2 from "../../../assets/how-to-use-page/step2.jpg";
import step3 from "../../../assets/how-to-use-page/step3.jpg";
import step4 from "../../../assets/how-to-use-page/step4.jpg";
import step5 from "../../../assets/how-to-use-page/step5.jpg";
import step6 from "../../../assets/how-to-use-page/step6.jpg";
import step7 from "../../../assets/how-to-use-page/step7.jpg";

import style from "./stepSec.module.css";
const StepSec = () => {
  const steps = [
    {
      img: step1,
      title: "Activate NFC:",
      detail:
        "Make sure that the NFC feature is enabled on your smartphone. This can usually be done through your device’s settings menu.",
    },
    {
      img: step2,
      title: "Locate NFC-enabled device:",
      detail:
        "Bring your NFC card close to the NFC-enabled device you want to interact with. The NFC antenna is typically located on the back of the smartphone, near the top or bottom.",
    },
    {
      img: step3,
      title: "Tap the NFC card:",
      detail:
        "Gently tap the NFC card against the NFC-enabled device. Ensure that the NFC chip on the card comes into contact with the NFC antenna on the device.",
    },
    {
      img: step4,
      title: "Wait for the interaction:",
      detail:
        "After tapping the NFC card, wait for a moment for the interaction to occur. The exact action will depend on how the NFC card and device are programmed. It could trigger actions such as opening a website, displaying contact information, initiating a payment, or launching an app.",
    },
    {
      img: step5,
      title: "Follow prompts (if any):",
      detail:
        "If prompted, follow any on-screen instructions or prompts that appear on your smartphone. These instructions may vary depending on the specific NFC application being used.",
    },
    {
      img: step6,
      title: "Complete the interaction:",
      detail:
        "Once the interaction is complete, remove the NFC card from the NFC-enabled device.",
    },
    {
      img: step7,
      title: "Store or discard the card:",
      detail:
        "Depending on the purpose of the interaction, you may choose to keep the NFC card for future use or discard it if it’s a one-time interaction.",
    },
  ];

  return (
    <div className={style.main}>
      <section className={style.section}>
        {steps.map((item, index) => {
          return (
            <div key={index} className={style.step}>
              <div className={style.col}>
                <span className={style.stepN}>
                  {"Step 0" + parseFloat(index + 1)}
                </span>
                <p className={style.stepT}>{item.title}</p>
                <p className={style.stepD}>{item.detail}</p>
              </div>
              <div className={style.col}>
                <div className={style.image}>
                  <img src={item.img} className={style.img} />
                </div>
              </div>
            </div>
          );
        })}
      </section>
    </div>
  );
};

export default StepSec;
