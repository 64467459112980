import React from "react";
import { useSelector } from "react-redux";
import profile from "../../../../assets/cards/card28/profile.png";
import logo from "../../../../assets/cards/card28/logo.png";
import style from "./card29.module.css";
import UserProfile from "../common/user-profile-com/UserProfile";
import UserBrandLogo from "../common/user-logo-com/UserBrandLogo";
import UserShowName from "../common/UserShowName";
import UserShowDesigination from "../common/UserShowDesigination";
import UserMobileNum from "../common/UserMobileNum";
import {
  FaFacebook,
  FaLocationArrow,
  FaMobileAlt,
  FaTwitch,
  FaTwitter,
  FaVimeo,
  FaWhatsapp,
  FaYoutube,
} from "react-icons/fa";
import UserEmail from "../common/UserEmail";
import { MdEmail } from "react-icons/md";
import UserWhatsapp from "../common/UserWhatsapp";
import UserLocation from "../common/UserLocation";
import UserFacebook from "../common/UserFacebook";
import UserTwitter from "../common/UserTwitter";
import UserYoutube from "../common/UserYoutube";
const Card29 = ({ data }) => {
  const fields = useSelector((state) => state.cardFiled);
  return (
    <div className={style.main}>
      <div className={style.header}>
        <UserBrandLogo
          data={data}
          fields={fields}
          logo={logo}
          deco={style.logo}
        />
      </div>
      <div className={style.profile_section}>
        <UserProfile
          data={data}
          fields={fields}
          profile={profile}
          deco={style.profile}
        />
      </div>
      <div className={style.body}>
        <div className={style.name_section}>
          <UserShowName
            data={data}
            fields={fields}
            name="ames Henry"
            deco={style.name}
          />
          <UserShowDesigination
            data={data}
            fields={fields}
            desigination="CEO"
            deco={style.desigination}
          />
        </div>
        <div className={style.links}>
          <UserMobileNum data={data} fields={fields} mobile="">
            <div className={style.item}>
              <FaMobileAlt className={style.icon} />
              <span className={style.title}>Mobile</span>
            </div>
          </UserMobileNum>
          <UserEmail data={data} fields={fields} email="">
            <div className={style.item}>
              <MdEmail className={style.icon} />
              <span className={style.title}>Email</span>
            </div>
          </UserEmail>
          <UserWhatsapp data={data} fields={fields} whatsapp="">
            <div className={style.item}>
              <FaWhatsapp className={style.icon} />
              <span className={style.title}>Whatsapp</span>
            </div>
          </UserWhatsapp>
          <UserLocation data={data} fields={fields} location="">
            <div className={style.item}>
              <FaLocationArrow className={style.icon} />
              <span className={style.title}>Location</span>
            </div>
          </UserLocation>
        </div>
        <div className={style.actions}>
          <button className={style.btn}>+ Add To Contact</button>
          <button className={style.btn}>About Me</button>
        </div>
        <div className={style.links}>
          <UserFacebook data={data} fields={fields} facebook="">
            <FaFacebook className={style.icon} />
          </UserFacebook>
          <UserTwitter data={data} fields={fields} twitter="">
            <FaTwitter className={style.icon} />
          </UserTwitter>
          <UserYoutube data={data} fields={fields} youtube="">
            <FaYoutube className={style.icon} />
          </UserYoutube>
          <FaVimeo className={style.icon} />
        </div>
      </div>
    </div>
  );
};

export default Card29;
