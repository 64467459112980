import React from "react";
import style from "./footer.module.css";
import logoLarge from "../../../assets/footer/logo-new.png";
import { FaFacebookF } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { Link } from "react-router-dom";
import { IoChatbubbleOutline } from "react-icons/io5";

const Footer = () => {
  return (
    <div className={style.main}>
      <section className={style.section}>
        <div className={style.row}>
          <div className={style.col1}>
            <div className={style.logoSec}>
              <img src={logoLarge} alt="log" className={style.logo} />
            </div>
            <p className={style.detail}>
              Lorem ipsum dolor sit amet, consec tetur adipiscing. Maecenas
              consectetur felis none.
            </p>
            <div className={style.icons}>
              <FaFacebookF className={style.icon1} />
              <FaYoutube className={style.icon2} />
              <FaLinkedinIn className={style.icon3} />
              <FaTwitter className={style.icon4} />
            </div>
          </div>
          <div className={style.col2}>
            <div className={style.row1}>
              <div className={style.innerCol}>
                <div className={style.linkContainer}>
                  <p className={style.heiding}>Address</p>
                  <Link className={style.link}>
                    457 Morningview Lane
                    <br /> New York USA
                  </Link>
                </div>
                <div className={style.linkContainer}>
                  <p className={style.heiding}>Email</p>
                  <Link className={style.link}>
                    deroly@mails.com
                    <br />
                    sample@mails.com
                  </Link>
                </div>
                <div className={style.linkContainer}>
                  <p className={style.heiding}>Phone</p>
                  <Link className={style.link}>
                    +1 (234) 567 890
                    <br />
                    +0 (987) 654 321
                  </Link>
                </div>
              </div>
              <div className={style.bar}></div>

              <div className={style.innerCol}>
                <div className={style.linkContainer}>
                  <p className={style.heiding}>Quicklinks</p>
                  <Link to="/" className={style.link}>
                    Home
                  </Link>
                  <Link to="/about/us" className={style.link}>
                    Abbout Us
                  </Link>
                  <Link to="/shop" className={style.link}>
                    Design
                  </Link>
                  <Link to="/how-to-use" className={style.link}>
                    How To Use?
                  </Link>
                  <Link to="/contact/us" className={style.link}>
                    Contact Us
                  </Link>
                </div>
                <div className={style.linkContainer}>
                  <p className={style.heiding}>Support</p>
                  <Link className={style.link}>Help Center</Link>
                  <Link className={style.link}>Privacy policy</Link>
                  <Link className={style.link}>Discliamer</Link>
                  <Link className={style.link}>FAQs</Link>
                  <Link className={style.link}>Contact</Link>
                </div>
                <div className={style.linkContainer}>
                  <p className={style.heiding}>Let's Talk!</p>
                  <Link className={style.link}>Lorem ipsum dolor sit</Link>
                  <Link className={style.link}>amet, consec tetur.</Link>
                  <div className={style.chat}>
                    <IoChatbubbleOutline className={style.chatIcon} />
                    <p className={style.chatTitle}>Live Chat</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={style.bar}></div>
        <div className={style.copy}>
          <p>Copyright © 2023</p>
          <div>
            <p>Privacy Policy </p>
            <p>Terms & Services</p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Footer;
