import React from "react";
import style from "./card21.module.css";
import profile from "../../../../assets/cards/card21/profile.png";
import { useSelector } from "react-redux";
import UserProfile from "../common/user-profile-com/UserProfile";
import UserShowName from "../common/UserShowName";
import UserShowDesigination from "../common/UserShowDesigination";
import UserShowWebsite from "../common/UserShowWebsite";
import UserWhatsapp from "../common/UserWhatsapp";
import {
  FaInternetExplorer,
  FaMessage,
  FaMobile,
  FaWhatsapp,
} from "react-icons/fa6";
import { FiMessageCircle } from "react-icons/fi";
import UserEmail from "../common/UserEmail";
import { MdEmail } from "react-icons/md";
import UserMobileNum from "../common/UserMobileNum";
import UserShowMobileNum from "../common/UserShowMobileNum";
import UserWebsite from "../common/UserWebsite";
import { FaMobileAlt } from "react-icons/fa";
import { sync } from "framer-motion";
import UserShowEmail from "../common/UserShowEmail";
const Card21 = ({ data }) => {
  const fields = useSelector((state) => state.cardFiled);

  return (
    <div className={style.main}>
      <div className={style.profile_section}>
        <UserProfile
          data={data}
          fields={fields}
          profile={profile}
          deco={style.profile}
        />
      </div>
      <div className={style.name_section}>
        <UserShowName
          data={data}
          fields={fields}
          name="David L. Soares"
          deco={style.name}
        />
        <UserShowDesigination
          data={data}
          fields={fields}
          desigination="Music Therapist"
          deco={style.desigination}
        />
        <UserShowDesigination
          data={data}
          fields={fields}
          desigination="Central therapy Studio"
          deco={style.desigination2}
        />
      </div>
      <div className={style.links}>
        <UserMobileNum data={data} fields={fields} mobile="">
          <FaMobileAlt className={style.icon} />
        </UserMobileNum>
        <UserWhatsapp data={data} fields={fields} whatsapp="">
          <FaWhatsapp className={style.icon} />
        </UserWhatsapp>
        <UserEmail data={data} fields={fields} email="">
          <MdEmail className={style.icon} />
        </UserEmail>
        <UserEmail data={data} fields={fields} email="">
          <FiMessageCircle className={style.icon} />
        </UserEmail>
      </div>

      <div className={style.contact_section}>
        <div className={style.heading}>Contact Us</div>
        <div className={style.item}>
          <UserMobileNum data={data} fields={fields} mobile="">
            <FaMobileAlt className={style.iconD} />
          </UserMobileNum>
          <UserShowMobileNum
            data={data}
            fields={fields}
            deco={style.title}
            mobile="+123 456 7658"
          />
        </div>
        <div className={style.item}>
          <UserEmail data={data} fields={fields} email="">
            <MdEmail className={style.iconD} />
          </UserEmail>
          <UserShowEmail
            data={data}
            fields={fields}
            email="YourEmail"
            deco={style.title}
          />
        </div>
        <div className={style.item}>
          <UserWebsite data={data} fields={fields} website="">
            <FaInternetExplorer className={style.iconD} />
          </UserWebsite>
          <UserShowWebsite
            data={data}
            fields={fields}
            website="www.yoursite.com"
            deco={style.title}
          />
        </div>
      </div>
      <div className={style.action}>
        <button className={style.btn}>+ Add To Contact</button>
      </div>
    </div>
  );
};

export default Card21;
