import UserNameFiled from "../../../ui/card-fileds/UserNameFiled";
import UserEmailField from "../../../ui/card-fileds/UserEmailField";
import UserTwitterLinkField from "../../../ui/card-fileds/UserTwitterLinkField";
import UserBrandPictureField from "../../../ui/card-fileds/UserBrandPictureField";
import UserProfileImgField from "../../../ui/card-fileds/UserProfileImgField";
import UserMobileField from "../../../ui/card-fileds/UserMobileField";
import UserDesginationField from "../../../ui/card-fileds/UserDesginationField";
import UserFacebookLinkField from "../../../ui/card-fileds/UserFacebookLinkField";
import UserInstaLinkField from "../../../ui/card-fileds/UserInstaLinkField";
import UserWhatsappField from "../../../ui/card-fileds/UserWhatsappField";
import PaymentModalOpner from "./payment-model-opner/PaymentModalOpner";

const Card5Form = () => {
  return (
    <div>
      <UserNameFiled />
      <UserDesginationField />
      <UserEmailField />
      <UserMobileField />
      <UserEmailField />
      <UserWhatsappField />
      <div style={{ display: "flex" }}>
        <UserBrandPictureField />
        <UserProfileImgField />
      </div>
      <UserFacebookLinkField />
      <UserTwitterLinkField />
      <UserInstaLinkField />

      <div>
        <PaymentModalOpner />
      </div>
    </div>
  );
};

export default Card5Form;
