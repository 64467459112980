import React from "react";
import style from "./card1.module.css";
import profile from "../../../../assets/cards/card1/profile.png";
import logo from "../../../../assets/cards/card1/logo.png";
import whatsappLogo from "../../../../assets/cards/card1/whatsapp.png";
import phoneLogo from "../../../../assets/cards/card1/phone.png";
import mailLogo from "../../../../assets/cards/card1/mail.png";
import netLogo from "../../../../assets/cards/card1/internet.png";
import locatiobLogo from "../../../../assets/cards/card1/map.png";
import instaLogo from "../../../../assets/cards/card1/instagram.png";
import facebookLogo from "../../../../assets/cards/card1/facebook.png";
import youtubeLogo from "../../../../assets/cards/card1/youtube.png";
import tiktokLogo from "../../../../assets/cards/card1/tiktok.png";
import linkdinLogo from "../../../../assets/cards/card1/linkedin.png";
import twitterLogo from "../../../../assets/cards/card1/twitter.png";
import snapLogo from "../../../../assets/cards/card1/social.png";
import pintrestLogo from "../../../../assets/cards/card1/pinterest.png";
import contactLogo from "../../../../assets/cards/card1/save.png";

import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
const Card1 = ({ data }) => {
  const fields = useSelector((state) => state.cardFiled);

  return (
    <div className={style.main}>
      <div className={style.topBg}>
        <img
          src={
            data?.user_brand_logo
              ? data.user_brand_logo
              : fields.user_brand_logo
              ? fields.user_brand_logo
              : logo
          }
          alt="logo"
          className={style.logo}
        />
      </div>
      <section className={style.section}>
        <div className={style.profilesec}>
          <img
            src={
              data?.user_profile
                ? data.user_profile
                : fields.user_profile
                ? fields.user_profile
                : profile
            }
            alt="user"
            className={style.img}
          />
          <h1 className={style.username}>
            {data?.user_name
              ? data.user_name
              : fields.user_name
              ? fields.user_name
              : "William James"}
          </h1>
          <p style={{ textTransform: "uppercase" }}>
            {data?.user_designation
              ? data.user_designation
              : fields.user_designation
              ? fields.user_designation
              : "REALTOR"}
          </p>
        </div>

        <div className={style.links}>
          <Link
            to={data?.link_whatsapp ? data.link_whatsapp: "#"}
            className={style.link}
          >
            <img src={whatsappLogo} className={style.linkLogo} alt="link" />
            <p>contact with whatsapp</p>
          </Link>
          <Link to={data?.link_mobile} className={style.link}>
            <img src={phoneLogo} className={style.linkLogo} alt="link" />
            <p>
              {data?.link_mobile
                ? data.link_mobile
                : fields.link_mobile
                ? fields.link_mobile
                : "0775524899"}
            </p>
          </Link>
          <Link to={data?.link_email} className={style.link}>
            <img src={mailLogo} className={style.linkLogo} alt="link" />
            <p>
              {data?.link_email
                ? data.link_email
                : fields.link_email
                ? fields.link_email
                : "youremail@yourwebsite.com"}
            </p>
          </Link>
          <Link to={data?.link_website} className={style.link}>
            <img src={netLogo} className={style.linkLogo} alt="link" />
            <p>
              {data?.link_website
                ? data.link_website
                : fields.link_website
                ? fields.link_website
                : " www.yourwebsiteaddress.com"}
            </p>
          </Link>
          <Link className={style.link}>
            <img src={locatiobLogo} className={style.linkLogo} alt="link" />
            <p>
              {data?.user_home_address
                ? data.user_home_address
                : fields.user_home_address
                ? fields.user_home_address
                : "919 Oaktree Crescent, Newmark and extra texts"}
            </p>
          </Link>
        </div>
        <p className={style.socialTitle}>CONNECT WITH SOCIAL MEDIA</p>
        <div className={style.row}>
          <Link to={data?.link_insta}>
            <img src={instaLogo} className={style.socialLogo} alt="social" />
          </Link>
          <Link to={data?.link_facebook}>
            <img src={facebookLogo} className={style.socialLogo} alt="social" />
          </Link>
          <Link to={data?.link_youtube}>
            <img src={youtubeLogo} className={style.socialLogo} alt="social" />
          </Link>
          <Link to={data?.link_tiktok}>
            <img src={tiktokLogo} className={style.socialLogo} alt="social" />
          </Link>
        </div>
        <div className={style.row}>
          <Link to={data?.link_linkdin}>
            <img src={linkdinLogo} className={style.socialLogo} alt="social" />
          </Link>
          <Link to={data?.link_twitter}>
            <img src={twitterLogo} className={style.socialLogo} alt="social" />
          </Link>
          <Link to={data?.link_snapchat}>
            <img src={snapLogo} className={style.socialLogo} alt="social" />
          </Link>
          <Link to={data?.link_pintrest}>
            <img src={pintrestLogo} className={style.socialLogo} alt="social" />
          </Link>
        </div>
        <div className={style.contacts}>
          <Link to={data?.link_mobile}>
            <img src={contactLogo} alt="save" className={style.contact} />
          </Link>
          <p>save to contacts</p>
        </div>
      </section>
    </div>
  );
};

export default Card1;
