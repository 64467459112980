import React, { useEffect, useState } from "react";
import style from "./header.module.css";
import logo from "../../../assets/logo.png";
import logoDark from "../../../assets/logo_dark.png";
import newLogo from '../../../assets/logo-url.png'
import { Link } from "react-router-dom";
import { FaArrowRightLong } from "react-icons/fa6";
import CtaButton from "../../ui/cta/CtaButton";
import { RxHamburgerMenu } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import { IoCloseSharp } from "react-icons/io5";
import { useSelector, useDispatch } from "react-redux";
import { authActions } from "../../../store/slices/auth-slice";
const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDone, setIsDone] = useState(false);

  const isAuth = useSelector((state) => state.auth.login);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getStarttedHandler = () => {
    navigate("/shop");
  };

  const menuOpenHandler = () => {
    setIsOpen(true);
  };

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setIsDone(true);
      }, 250);
    }
  }, [isOpen]);

  const menuCloseHandler = () => {
    setIsDone(false);
  };

  useEffect(() => {
    if (!isDone) {
      setTimeout(() => {
        setIsOpen(false);
      }, [400]);
    }
  }, [isDone]);

  const linkClickHandler = (event) => {
    const id = event.target.id;
    // if (id) {
    //   const ele = document.getElementById(id);
    //   ele.style.color = "white";
    // }
  };

  return (
    <div className={style.main}>
      <div className={isOpen ? style.open : style.close}></div>
      <div className={isDone ? style.open2 : style.close2}>
        <div className={style.closeIconContainer}>
          <img src={newLogo} className={style.mobileMenuLogo} />
          <IoCloseSharp
            onClick={menuCloseHandler}
            className={style.closeIcon}
          />
        </div>
        <div className={style.mobileMenus}>
          <Link to="/" className={style.linkMobile}>
            Home
          </Link>
          <Link to="/about/us" className={style.linkMobile}>
            Abbout Us
          </Link>
          <Link to="/shop" className={style.linkMobile}>
            Design
          </Link>
          <Link to="/how-to-use" className={style.linkMobile}>
            How To Use?
          </Link>
          <Link to="/contact/us" className={style.linkMobile}>
            Contact Us
          </Link>
        </div>
      </div>
      <div className={style.heroSection}>
        <div className={style.header}>
          <div className={style.sideLeft}>
            <img
              onClick={() => navigate("/")}
              src={logo}
              alt="logo"
              className={style.logo}
            />
            <div className={style.bar}></div>
            <Link
              to="/"
              onClick={linkClickHandler}
              id="link1"
              data="1"
              className={style.link}
            >
              Home
            </Link>
            <Link
              onClick={linkClickHandler}
              id="link3"
              data="3"
              className={style.link}
              to="/about/us"
            >
              Abbout Us
            </Link>
            <Link
              onClick={linkClickHandler}
              id="link2"
              data="2"
              className={style.link}
              to="/shop"
            >
              Design
            </Link>

            <Link
              to="/how-to-use"
              onClick={linkClickHandler}
              id="link4"
              data="4"
              className={style.link}
            >
              How To Use?
            </Link>
            <Link
              to="/contact/us"
              onClick={linkClickHandler}
              id="link5"
              data="5"
              className={style.link}
            >
              Contact Us
            </Link>
          </div>
          <div className={style.sideRight}>
            <div
              onClick={() => {
                navigate("/login");
                dispatch(authActions.setLoagin(false));
              }}
              className={style.loginSec}
            >
              <span className={style.loginTitle}>
                {isAuth ? "log out" : "Log In"}
              </span>
              <FaArrowRightLong className={style.loginIcon} />
            </div>
            <CtaButton
              width="132px"
              animation={true}
              title="Get Started"
              onClick={getStarttedHandler}
              bg={"white"}
            />
          </div>
          <RxHamburgerMenu
            onClick={menuOpenHandler}
            className={style.menuIcon}
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
