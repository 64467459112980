import React from "react";
import useUpdateCard from "../../../utils/useUpdateCard";
import style from "./textInputField.module.css";
const UserLocationfeild = () => {
  const { dispatch, fieldActions } = useUpdateCard();

  const locationChange = (event) => {
    
    dispatch(fieldActions.setLocation(event.target.value));
  };

  return (
    <>
      <input
        type="url"
        placeholder="paste your location URL"
        className={style.textInput}
        onChange={locationChange}
      />
    </>
  );
};

export default UserLocationfeild;
