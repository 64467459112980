import React, { useState } from "react";
import style from "./registerForm.module.css";
import { Link } from "react-router-dom";
const RegisterForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const emailChane = (event) => {
    setEmail(event.target.value);
  };
  const passChange = (event) => {
    setPassword(event.target.value);
  };

  const nameChange = (event) => {
    setName(event.target.value);
  };

  const submitHandler = (event) => {
    event.preventDefault();
  };

  return (
    <div className={style.main}>
      <section className={style.section}>
        <div className={style.formSec}>
          <form type="submit" onSubmit={submitHandler}>
            <div className={style.formItem}>
              <label>username</label>
              <input
                onChange={nameChange}
                placeholder="username"
                required
                type="text"
                className={style.input}
              />
            </div>
            <div className={style.formItem}>
              <label>email</label>
              <input
                onChange={emailChane}
                placeholder="Email address (e.g., example@example.com)"
s                required
                type="email"
                className={style.input}
              />
            </div>
            <div className={style.formItem}>
              <label>password</label>
              <input
                onChange={passChange}
                placeholder="Password (at least 6 characters)"
                required
                type="password"
                className={style.input}
              />
            </div>
            <button className={style.loginBtn} type="submit">
              register
            </button>
          </form>

          <div className={style.bar}></div>
          <div className={style.links}>
            <Link to={"/login"} className={style.link}>
              have already account?
            </Link>
            {/* <Link className={style.link}>forgot password?</Link> */}
          </div>
        </div>
      </section>
    </div>
  );
};

export default RegisterForm;
