import style from "./textInputField.module.css";
import useUpdateCard from "../../../utils/useUpdateCard";

const UserNameFiled = () => {
  const { dispatch, fieldActions } = useUpdateCard();

  const nameChange = (event) => {
    dispatch(fieldActions.setUserName(event.target.value));
  };

  return (
    <>
      <input
        onChange={nameChange}
        type="text"
        placeholder="enter your name"
        className={style.textInput}
      />
    </>
  );
};

export default UserNameFiled;
