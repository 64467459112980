import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import style from "./cardQr.module.css";
import { useNavigate } from "react-router-dom";
import { FaRegCopy } from "react-icons/fa6";
import { FaRegShareSquare } from "react-icons/fa";
import apis from "../../../utils/apis";
const CardQrSec = ({ open, setOpen, id }) => {
  const navigate = useNavigate();
  const [isCopied, setIsCopied] = useState(false);
  const apiList = apis();
  const closeHandler = () => {
    setOpen(false);
    navigate("/shop");
  };

  const clientUrl = apiList.clientPath;
  const qr = "https://api.qrserver.com/v1/create-qr-code/?size=200x200&data=";
  const url = qr + `${clientUrl}${id}`;

  const copyHandler = () => {
    if (navigator) {
      navigator.clipboard.writeText(clientUrl + id);
    }
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  const shareHandler = async () => {
    if (navigator.share) {
      await navigator.share({
        text: "share your E-card",
        URL: clientUrl + id,
        title: "share your E-card",
      });
    }
  };

  return (
    <div className={style.main}>
      <Modal show={open} onHide={closeHandler} centered>
        <Modal.Header closeButton>
          <Modal.Title>congratulations!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={style.qr_section}>
            <img src={url} className={style.qr} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className={style.copy_section}>
            <FaRegCopy
              onClick={copyHandler}
              className={isCopied ? style.copied : style.icon}
            />
            <FaRegShareSquare onClick={shareHandler} className={style.icon} />
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CardQrSec;
