import React from "react";
import style from "./textInputField.module.css";
import useUpdateCard from "../../../utils/useUpdateCard";

const UserWhatsappField = () => {
  const { dispatch, fieldActions } = useUpdateCard();
  const whatsappChange = (event) => {
    dispatch(
      fieldActions.setUserWhatsapp("https://wa.me:" + event.target.value)
    );
  };
  return (
    <>
      <input
        type="tel"
        pattern="^\+?[0-9\-]+"
          placeholder="whatsapp number"
        className={style.textInput}
        onChange={whatsappChange}
      />
    </>
  );
};

export default UserWhatsappField;
