import React from "react";
import Hero from "../hero-section/Hero";
import style from "./home.module.css";
import HomeProductsSec from "./home-products-section/HomeProductsSec";
import HomeFaqsSec from "./home-faqs-section/HomeFaqsSec";
import FlipSection from "../flip-section/FlipSection";
import FeactureSection from "../feature-section/FeactureSection";
import HomeWhyUseSec from "./home-whyUs-section/HomeWhyUseSec";
import HomeChoiceSec from "../home-choice-section/HomeChoiceSec";
import HomeHowWrokSec from "./home-howWork-section/HomeHowWrokSec";
import cardLeft from "../../assets/home-choice/card-right.png";
import cardRight from "../../assets/home-choice/card-mobile-right.png";
import post1 from "../../assets/home-choice/Post-01.jpg";
import post2 from "../../assets/home-choice/Post-02.jpg";
import HomeAboutusSec from "../home-aboutus-sec/HomeAboutusSec";
import HomeSliderSec from "../home-slider-section/HomeSliderSec";
import HomeAvchievementSec from "./home-achievement-section/HomeAvchievementSec";
import HomeTestimonialSec from "./home-testimonial-section/HomeTestimonialSec";
import HomeGetStartedSec from "./home-getstartted-section/HomeGetStartedSec";
import HomePlanSec from "./home-plan-sec/HomePlanSec";
const Home = () => {
  return (
    <div>
      <div className={style.main}>
        <Hero />
      </div>
      <FlipSection />
      <FeactureSection />
      <p className={style.heading}>Choose What you want</p>
      <div className={style.choiceContainer}>
        <HomeChoiceSec
          icon={post1}
          title="QR code + Vcode"
          price="From Rs.1500/-"
        />
        <HomeChoiceSec
          icon={post2}
          title="QR code + Vcode + 1 NFC Card"
          price="From Rs.2450/-"
        />
      </div>

      {/* <HomeAboutusSec /> */}
      {/* <HomeSliderSec /> */}
      <HomeProductsSec />
      {/* <HomeWhyUseSec /> */}
      <HomeAvchievementSec />
      <HomeHowWrokSec />
      <HomePlanSec />
      <HomeTestimonialSec />
      <HomeFaqsSec />
      {/* <HomeGetStartedSec /> */}
    </div>
  );
};

export default Home;
