const apis = () => {
  const local = "http://localhost:8080/";
  const live = "http://kzabani.com/";
  // const vercelServer = "https://derloye-backend.vercel.app/";

  const list = {
    uploadCardImg: `${live}card/image/upload`,
    newCardCreate: `${live}card/create`,

    // user card
    getUserCardList: `${live}card/list`,
    getSingleCardById: `${live}card/single/id`,

    // client path url
    clientPath: "https://vc.tapilinq.com/card/detail?id=",
  };

  return list;
};

export default apis;
