import React from "react";

import UserNameFiled from "../../../ui/card-fileds/UserNameFiled";
import UserEmailField from "../../../ui/card-fileds/UserEmailField";
import UserWebsiteLinkField from "../../../ui/card-fileds/UserWebsiteLinkField";
import UserHomeAddressField from "../../../ui/card-fileds/UserHomeAddressField";
import UserYoutubeLinkField from "../../../ui/card-fileds/UserYoutubeLinkField";
import UserLinkdinLinkField from "../../../ui/card-fileds/UserLinkdinLinkField";
import UserTwitterLinkField from "../../../ui/card-fileds/UserTwitterLinkField";
import UserBrandPictureField from "../../../ui/card-fileds/UserBrandPictureField";
import UserProfileImgField from "../../../ui/card-fileds/UserProfileImgField";
import UserMobileField from "../../../ui/card-fileds/UserMobileField";
import PaymentModalOpner from "./payment-model-opner/PaymentModalOpner";

const CardForm3 = () => {
  return (
    <div>
      <UserNameFiled />
      <UserMobileField />
      <UserEmailField />
      <UserEmailField />
      <div style={{ display: "flex", gap: "0rem" }}>
        <UserBrandPictureField />
        <UserProfileImgField />
      </div>
      <UserWebsiteLinkField />
      <UserHomeAddressField />
      <UserYoutubeLinkField />
      <UserLinkdinLinkField />
      <UserTwitterLinkField />

      <div>
        <PaymentModalOpner />
      </div>
    </div>
  );
};

export default CardForm3;
