import React from "react";
import profile from "../../../../assets/cards/card33/profile.png";
import style from "./card34.module.css";
import { useSelector } from "react-redux";
import UserProfile from "../common/user-profile-com/UserProfile";
import UserShowName from "../common/UserShowName";
import UserShowDesigination from "../common/UserShowDesigination";
import UserMobileNum from "../common/UserMobileNum";
import { IoCall } from "react-icons/io5";
import UserShowMobileNum from "../common/UserShowMobileNum";
import UserEmail from "../common/UserEmail";
import { MdEmail } from "react-icons/md";
import UserShowEmail from "../common/UserShowEmail";
import UserWebsite from "../common/UserWebsite";
import {
  FaFacebook,
  FaInternetExplorer,
  FaLinkedin,
  FaLocationArrow,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import UserShowWebsite from "../common/UserShowWebsite";
import UserLocation from "../common/UserLocation";
import UserShowLocation from "../common/UserShowLocation";
import UserFacebook from "../common/UserFacebook";
import UserTwitter from "../common/UserTwitter";
import UserYoutube from "../common/UserYoutube";
import UserLinkdin from "../common/UserLinkdin";
const Card34 = ({ data }) => {
  const fields = useSelector((state) => state.cardFiled);
  return (
    <div className={style.main}>
      <div className={style.header}>
        <span className={style.date}>
          {new Date().toLocaleString("en-US", { weekday: "long" })}
        </span>

        <div className={style.profile_section}>
          <UserProfile
            data={data}
            fields={fields}
            profile={profile}
            deco={style.profile}
          />
        </div>
      </div>
      <div className={style.name_section}>
        <UserShowName
          data={data}
          fields={fields}
          name="Ashini Stesy"
          deco={style.name}
        />
      </div>
      <UserShowDesigination
        data={data}
        fields={fields}
        desigination="LADY BOSS & STYLIST"
        deco={style.desigination}
      />

      <div className={style.links}>
        <UserMobileNum data={data} fields={fields} mobile="">
          <div className={style.item}>
            <IoCall className={style.icon} />
            <UserShowMobileNum
              data={data}
              fields={fields}
              deco={style.text}
              mobile="Call Me : 000.123.1212"
            />
          </div>
        </UserMobileNum>
        <UserEmail data={data} fields={fields} email="">
          <div className={style.item}>
            <MdEmail className={style.icon} />
            <UserShowEmail
              data={data}
              fields={fields}
              email="hello@yourgmail.com"
              deco={style.text}
            />
          </div>
        </UserEmail>
        <UserWebsite data={data} fields={fields} website="">
          <div className={style.item}>
            <div className={style.item}>
              <FaInternetExplorer className={style.icon} />
              <UserShowWebsite
                data={data}
                fields={fields}
                website="ladybossa&stylist.com"
                deco={style.text}
              />
            </div>
          </div>
        </UserWebsite>
        <UserLocation data={data} fields={fields} location="">
          <div className={style.item}>
            <FaLocationArrow className={style.icon} />
            <UserShowLocation
              data={data}
              fields={fields}
              location="123, Street Ave, Plam Springs, CA 09586"
              deco={style.text}
            />
          </div>
        </UserLocation>
      </div>
      <div className={style.footer}>
        <span className={style.date_small}>
          {new Date().toLocaleString("en-US", { weekday: "long" })}
        </span>
        <div className={style.media}>
          <UserFacebook data={data} fields={fields} facebook="">
            <FaFacebook className={style.media_icon} />
          </UserFacebook>
          <UserTwitter data={data} fields={fields} twitter="">
            <FaTwitter className={style.media_icon} />
          </UserTwitter>
          <UserYoutube data={data} fields={fields} youtube="">
            <FaYoutube className={style.media_icon} />
          </UserYoutube>
          <UserLinkdin data={data} fields={fields} linkdin="">
            <FaLinkedin className={style.media_icon} />
          </UserLinkdin>
        </div>
      </div>
    </div>
  );
};

export default Card34;
