import React, { useEffect, useState } from "react";
import style from "./homechoiceSec.module.css";
import { motion } from "framer-motion";

const HomeChoiceSec = ({ title, price, icon }) => {
  const [isInitial, setIsInitial] = useState(true);

  useEffect(() => {
    setIsInitial(false);
  }, []);

  return (
    <div className={style.main}>
      <motion.section
        initial={{
          translateY: isInitial ? 100 : 0,
          opacity: isInitial ? 0.5 : 1,
        }}
        whileInView={{ translateY: 0, opacity: 1 }}
        transition={{ duration: 1 }}
        className={style.section}
      >
        <div className={style.card}>
          <div className={style.imgSection}>
            <img src={icon} alt="card" className={style.img} />
          </div>
        </div>
      </motion.section>
    </div>
  );
};

export default HomeChoiceSec;
