import React from "react";
import userProfile2 from "../../../../assets/cards/card-2/profiledummy.jpg";
import { IoCall } from "react-icons/io5";
import { IoLogoWhatsapp } from "react-icons/io5";
import { IoLogoYoutube } from "react-icons/io5";
import { IoLogoTwitter } from "react-icons/io5";
import { IoLogoFacebook } from "react-icons/io5";
import { IoLogoInstagram } from "react-icons/io5";
import { IoShareSocialSharp } from "react-icons/io5";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazyload";
import style from "./card2.module.css";

const Card2 = ({ data }) => {
  const fields = useSelector((state) => state.cardFiled);
  const shareCardHandler = async () => {
    await navigator.share({
      title: "share card link",
      URL: "/",
      text: "share",
    });
  };

  return (
    <div className={style.main}>
      <section className={style.section}>
        <div className={style.profileSec}>
          <LazyLoad height={190} offset={100}>
            <img
              src={
                data?.user_profile
                  ? data.user_profile
                  : fields.user_profile
                  ? fields.user_profile
                  : userProfile2
              }
              alt="user"
              className={style.img}
            />
          </LazyLoad>
        </div>
        <div className={style.contentSec}>
          <p>
            {data?.user_designation
              ? data.user_designation
              : fields.user_designation
              ? fields.user_designation
              : "Technologent"}
          </p>
          <h1>
            {data?.user_name
              ? data.user_name
              : fields.user_name
              ? fields.user_name
              : "Robyn H. Perry"}
          </h1>
          <p>
            {data?.user_post
              ? data.user_post
              : fields.user_post
              ? fields.user_post
              : "CEO"}
          </p>
        </div>

        <div className={style.linkSec}>
          <Link
            to={data?.link_mobile ? data.link_mobile : "#"}
            className={style.link}
          >
            <IoCall />
          </Link>
          <Link
            to={data?.link_facebook ? data.link_facebook : "#"}
            className={style.link}
          >
            <IoLogoFacebook />
          </Link>
          <Link
            to={data?.link_twitter ? data.link_twitter : "#"}
            className={style.link}
          >
            <IoLogoTwitter />
          </Link>
          <Link
            to={data?.link_youtube ? data.link_youtube : "#"}
            className={style.link}
          >
            <IoLogoYoutube />
          </Link>
          <Link
            to={data?.link_whatsapp ? data.link_whatsapp : "#"}
            className={style.link}
          >
            <IoLogoWhatsapp />
          </Link>
        </div>

        <div className={style.aboutSec}>
          <div className={style.topBar}></div>
          <div className={style.aboutContent}>
            <p>About US</p>
            <p>
              {data?.user_bio
                ? data.user_bio
                : fields.user_bio
                ? fields.user_bio
                : " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo"}
            </p>
          </div>
        </div>

        <div className={style.linkSecBottom}>
          <Link
            to={data?.link_facebook ? data.link_facebook : "#"}
            className={style.link}
          >
            <IoLogoFacebook />
          </Link>
          <Link
            to={data?.link_twitter ? data.link_twitter : "#"}
            className={style.link}
          >
            <IoLogoTwitter />
          </Link>
          <Link
            to={data?.link_youtube ? data.link_youtube : "#"}
            className={style.link}
          >
            <IoLogoYoutube />
          </Link>
          <Link
            to={data?.link_insta ? data.link_insta : "#"}
            className={style.link}
          >
            <IoLogoInstagram />
          </Link>
          <Link onClick={shareCardHandler} className={style.link}>
            <IoShareSocialSharp />
          </Link>
        </div>
      </section>
    </div>
  );
};

export default Card2;
