import React from "react";
import logo from "../../../../assets/cards/card30/logo.png";
import bg from "../../../../assets/cards/card30/bg.jpg";
import style from "./card31.module.css";
import { useSelector } from "react-redux";
import profile from "../../../../assets/cards/card30/profile.png";
import UserProfile from "../common/user-profile-com/UserProfile";
import UserBrandLogo from "../common/user-logo-com/UserBrandLogo";
import UserShowName from "../common/UserShowName";
import { CiCalendarDate } from "react-icons/ci";
import UserShowDesigination from "../common/UserShowDesigination";
import UserMobileNum from "../common/UserMobileNum";
import { IoCafe, IoCall } from "react-icons/io5";
import { FaMessage } from "react-icons/fa6";
import UserEmail from "../common/UserEmail";
import { MdEmail } from "react-icons/md";
import UserWebsite from "../common/UserWebsite";
import { FaFacebook, FaInstagram, FaInternetExplorer } from "react-icons/fa";
import UserFacebook from "../common/UserFacebook";
import UserInsta from "../common/UserInsta";

const Card31 = ({ data }) => {
  const fields = useSelector((state) => state.cardFiled);
  return (
    <div className={style.main}>
      <div className={style.bg}>
        <img src={bg} className={style.img} />
      </div>
      <div className={style.profile_section}>
        <UserProfile
          data={data}
          fields={fields}
          profile={profile}
          deco={style.profile}
        />
        <UserBrandLogo
          data={data}
          fields={fields}
          logo={logo}
          deco={style.logo}
        />
      </div>
      <div className={style.name_section}>
        <UserShowName
          data={data}
          fields={fields}
          name="Eric Wang"
          deco={style.name}
        />
      </div>
      <div className={style.desigination_section}>
        <CiCalendarDate className={style.date} />
        <UserShowDesigination
          data={data}
          fields={fields}
          desigination="Sales Advisor"
          deco={style.desigination}
        />
      </div>

      <div className={style.actions}>
        <button className={style.btn}>Save Contact</button>
      </div>

      <div className={style.links}>
        <UserMobileNum data={data} fields={fields} mobile="">
          <div className={style.item}>
            <IoCall className={style.icon} />
            <span className={style.text}>Call Vai</span>
          </div>
        </UserMobileNum>
        <UserMobileNum data={data} fields={fields} mobile="">
          <div className={style.item}>
            <FaMessage className={style.icon} />
            <span className={style.text}>Text Me</span>
          </div>
        </UserMobileNum>
        <UserEmail data={data} fields={fields} email="">
          <div className={style.item}>
            <MdEmail className={style.icon} />
            <span className={style.text}>Email</span>
          </div>
        </UserEmail>
      </div>
      <div className={style.links}>
        <UserWebsite data={data} fields={fields} website="">
          <div className={style.item}>
            <FaInternetExplorer className={style.icon} />
            <span className={style.text}>Website</span>
          </div>
        </UserWebsite>
        <UserFacebook data={data} fields={fields} facebook="">
          <div className={style.item}>
            <FaFacebook className={style.icon} />
            <span className={style.text}>Facebook</span>
          </div>
        </UserFacebook>
        <UserInsta data={data} fields={fields} insta="">
          <div className={style.item}>
            <FaInstagram className={style.icon} />
            <span className={style.text}>Instagram</span>
          </div>
        </UserInsta>
      </div>

      <div className={style.share}>Share</div>
      <div className={style.actions2}>
        <button className={style.media1}>
          <FaFacebook className={style.media_icon} />
          <span>Facebook</span>
        </button>
        <button className={style.media2}>
          <FaInstagram className={style.media_icon} />
          <span>Instagram</span>
        </button>
      </div>
    </div>
  );
};

export default Card31;
