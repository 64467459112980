import React from "react";

const UserShowWebsite = ({ data, fields, website, deco }) => {
  return (
    <div className={deco}>
      {data?.link_website
        ? data.link_website
        : fields.link_website
        ? fields.link_website
        : website}
    </div>
  );
};

export default UserShowWebsite;
