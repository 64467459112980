import React from "react";
import { FaPlus } from "react-icons/fa";
import { PiArrowFatLineUpDuotone } from "react-icons/pi";
import { MdDashboardCustomize } from "react-icons/md";
import { useSelector } from "react-redux";
import logo from "../../../../assets/cards/card8/logo.png";
import profile from "../../../../assets/cards/card8/profil.jpg";
import banner1 from "../../../../assets/cards/card8/user1.jpg";
import banner2 from "../../../../assets/cards/card8/user2.jpg";
import banner3 from "../../../../assets/cards/card8/user3.jpg";
import { RiFacebookCircleLine } from "react-icons/ri";
import { FaYoutube } from "react-icons/fa6";
import { FaTwitter } from "react-icons/fa";

// common components
import UserBrandLogo from "../common/user-logo-com/UserBrandLogo";
import UserProfile from "../common/user-profile-com/UserProfile";
import UserShowName from "../common/UserShowName";
import UserShowDesigination from "../common/UserShowDesigination";
import UserFacebook from "../common/UserFacebook";
import UserTwitter from "../common/UserTwitter";
import UserYoutube from "../common/UserYoutube";
import style from "./card8.module.css";
import UserBanner from "../common/UserBanner";
import UserBanner2 from "../common/UserBanner2";
import UserBanner3 from "../common/UserBanner3";
import UserMobileNum from "../common/UserMobileNum";

const Card8 = ({ data }) => {
  const fields = useSelector((state) => state.cardFiled);
  return (
    <div className={style.main}>
      <div className={style.header}>
        <UserBrandLogo
          data={data}
          fields={fields}
          logo={logo}
          deco={style.logo}
        />
      </div>
      <div className={style.user_name}>
        <UserProfile
          data={data}
          fields={fields}
          profile={profile}
          deco={style.profile_img}
        />
          <div className={style.desigination_section}>
        <UserShowName
          data={data}
          fields={fields}
          name="Mario K. Dilon"
          deco={style.name}
        />
        <UserShowDesigination
          data={data}
          fields={fields}
          desigination="Owner/CEO"
          deco={style.desigination}
        />
      </div>
      </div>
    
      <div className={style.links}>
        <UserFacebook data={data} fields={fields} facebook="">
          <RiFacebookCircleLine className={style.icon} />
        </UserFacebook>
        <UserYoutube data={data} fields={fields} youtube="">
          <FaYoutube className={style.icon} />
        </UserYoutube>
        <UserTwitter data={data} fields={fields} twitter="">
          <FaTwitter className={style.icon} />
        </UserTwitter>
    
      </div>
      <div className={style.banners}>
          <div className={style.banner1}>
            <UserBanner
              data={data}
              fields={fields}
              banner={banner1}
              deco={style.banner_img1}
            />
              <UserBanner2
              data={data}
              fields={fields}
              banner2={banner2}
              deco={style.banner_img2}
            />
          </div>
          <div className={style.banner_2}>
          
            <UserBanner3
              data={data}
              fields={fields}
              banner3={banner3}
              deco={style.banner_img3}
            />
          </div>
        </div>
      <div className={style.bottom}>
        <div className={style.col1}>
          <MdDashboardCustomize className={style.iconB} />
          <PiArrowFatLineUpDuotone className={style.iconB} />
        </div>
        <div className={style.col2}>
            <span className={style.contact} > Add To Contact </span>
          <UserMobileNum data={data} fields={fields} mobile="dsd">
            <FaPlus className={style.iconB} />
          </UserMobileNum>
        </div>
      </div>
      <div className={style.footer} >
        All Rights reserved
      </div>
    </div>
  );
};

export default Card8;
