import React, { useEffect, useState } from "react";
import style from "./homecounterSec.module.css";
import SingleCounter from "./SingleCounter";
import { useInView } from "react-intersection-observer";

const HomeCounterSec = () => {
  const { ref, inView } = useInView();

  const data = [
    {
      after: "K",
      operator: "+",
      value: inView ? "95" : 0,
      title: "ACTIVE CUSTOMERS",
    },
    {
      after: " ",
      operator: "%",
      value: inView ? "99.5" : 0,
      title: "CUSTOMER SATISFACTION",
    },
    {
      after: " ",
      operator: "%",
      value: inView ? "72.5" : 0,
      title: "YEARLY GROWTH",
    },
    {
      after: "B",
      operator: "+",
      value: inView ? "410" : 0,
      title: "MONEY MANAGED",
    },
  ];

  return (
    <div ref={ref} className={style.main}>
      <section className={style.section}>
        <div className={style.counter}>
          {data.map((num) => {
            return (
              <SingleCounter
                after={num.after}
                operator={num.operator}
                value={num.value}
                title={num.title}
              />
            );
          })}
        </div>
      </section>
    </div>
  );
};

export default HomeCounterSec;
