import React, { useEffect, useRef, useState } from "react";
import "react-credit-cards/es/styles-compiled.css";
import CreditCard from "react-credit-cards";
import style from "./checkout.module.css";
import { useSelector, useDispatch } from "react-redux";
import SendCreateCardForm from "../../../store/slices/actions/SendCreateCardForm";
import { fieldActions } from "../../../store/slices/card-fields-slice";
import { MDBSpinner } from "mdb-react-ui-kit";
import { useNavigate } from "react-router-dom";
import CardQrSec from "../card-qr-section/CardQrSec";
import swl from "sweetalert2";
const Checkout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [cardid, setCardId] = useState("");

  const [number, setNumber] = useState("");
  const [name, setName] = useState("");
  const [expiry, setExpiry] = useState("");
  const [cvc, setCvc] = useState("");
  const [focused, setFocused] = useState("");

  const cardData = useSelector((state) => state.cardFiled);
  const isLoading = useSelector((state) => state.ui.loading);

  const cardRef = useRef();

  useEffect(() => {
    cardRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  }, []);

  // Function to format credit card number with spaces every 4 digits
  const formatCardNumber = (value) => {
    return value
      .replace(/\s/g, "")
      .replace(/(\d{4})/g, "$1 ")
      .trim();
  };

  // Function to format expiry date with a slash after the second digit
  const formatExpiry = (value) => {
    return value
      .replace(/\s/g, "")
      .replace(/(\d{2})(\d{1,2})/, "$1/$2")
      .trim();
  };

  // Function to format cvc with a max length of 3 digits
  const formatCVC = (value) => {
    return value.replace(/\s/g, "").slice(0, 4);
  };

  const submitHandler = async (event) => {
    event.preventDefault();

    // const isEmpty = Object.values(cardData).every((value) => value === "");
    // if (isEmpty) {
    //   swl.fire({
    //     title: "opps",
    //     text: "nothing to pay please choice a card first",
    //     icon: "error",
    //   });
    //   return;
    // }

    // const filteredObj = {};

    // for (const key in cardData) {
    //   if (cardData[key] !== "") {
    //     filteredObj[key] = cardData[key];
    //   }
    // }

    const paymentData = {
      number,
      expiry,
      cvc: cvc.slice(0, 4),
      name,
      amount: 100,
    };

    const result = await SendCreateCardForm(
      cardData,
      paymentData,
      "660ee44d12af7cad5eaf0fcc",
      dispatch
    );
    if (result?.status) {
      // swl.fire({
      //   title: result.message,
      //   text: "payment confirmed",
      //   icon: "success",
      // });
      setOpen(true);
      dispatch(fieldActions.resetAllFields());
      setCardId(result?.card_id);
    }
  };

  return (
    <div className={style.main}>
      <CardQrSec id={cardid} open={open} setOpen={setOpen} />
      <section ref={cardRef} className={style.section}>
        <div className={style.col1}>
          <CreditCard
            number={number}
            name={name}
            expiry={expiry}
            cvc={cvc.slice(0, 4)}
            focused={focused}
          />
        </div>

        <form type="submit" onSubmit={submitHandler}>
          <div className={style.cardItems}>
            <input
              required
              maxLength={19}
              className={style.cardInput}
              type="tel"
              name="number"
              placeholder="Card Number"
              value={formatCardNumber(number)}
              onChange={(e) => setNumber(e.target.value)}
              onFocus={(e) => setFocused(e.target.name)}
            />
            <input
              required
              className={style.cardInput}
              type="text"
              name="name"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              onFocus={(e) => setFocused(e.target.name)}
            />
            <div className={style.inputSm}>
              <input
                required
                maxLength={5}
                className={style.cardInput}
                type="tel"
                name="expiry"
                placeholder="MM/YY Expiry"
                value={formatExpiry(expiry)}
                onChange={(e) => setExpiry(e.target.value)}
                onFocus={(e) => setFocused(e.target.name)}
              />
              <input
                required
                className={style.cardInput}
                type="tel"
                name="cvc"
                placeholder="CVC"
                value={formatCVC(cvc)}
                onChange={(e) => setCvc(e.target.value)}
                onFocus={(e) => setFocused(e.target.name)}
              />
            </div>
            <div>
              <p className={style.secureT}>
                everythning is secure and encrypted
              </p>
              <span className={style.amount}>
                payable amount: <span className={style.amt}>$556</span>
              </span>
              <button
                type="submit"
                disabled={isLoading}
                style={{ cursor: isLoading && "not-allowed" }}
                className={style.payBtn}
              >
                {isLoading && (
                  <MDBSpinner size="sm" color="light" className="me-2" />
                )}
                {isLoading ? "please wait" : " pay now"}
              </button>
            </div>
          </div>
        </form>
      </section>
    </div>
  );
};

export default Checkout;
