import React from "react";
import profile from "../../../../assets/cards/card29/profile.png";
import logo from "../../../../assets/cards/card29/logo.png";
import style from "./card30.module.css";
import UserBrandLogo from "../common/user-logo-com/UserBrandLogo";
import UserProfile from "../common/user-profile-com/UserProfile";
import UserShowName from "../common/UserShowName";
import UserShowDesigination from "../common/UserShowDesigination";
import UserMobileNum from "../common/UserMobileNum";
import { FaInternetExplorer, FaLinkedin, FaMobileAlt } from "react-icons/fa";
import UserEmail from "../common/UserEmail";
import { MdEmail } from "react-icons/md";
import UserLinkdin from "../common/UserLinkdin";
import { IoCall } from "react-icons/io5";
import UserWebsite from "../common/UserWebsite";
import { useSelector } from "react-redux";
import UserShowMobileNum from "../common/UserShowMobileNum";
import UserShowEmail from "../common/UserShowEmail";
import UserShowLinkdin from "../common/UserShowLinkdin";
import UserShowWebsite from "../common/UserShowWebsite";
const Card30 = ({ data }) => {
  const fields = useSelector((state) => state.cardFiled);

  return (
    <div className={style.main}>
      <div className={style.header}>
        <UserBrandLogo
          data={data}
          fields={fields}
          logo={logo}
          deco={style.logo}
        />
      </div>
      <div className={style.profile_section}>
      <div className={style.bar} ></div>
        <UserProfile
          data={data}
          fields={fields}
          deco={style.profile}
          profile={profile}
        />
   
      </div>

      <div className={style.name_section}>
        <UserShowName
          data={data}
          fields={fields}
          name="Bryan Scotte"
          deco={style.name}
        />
        <UserShowDesigination
          data={data}
          fields={fields}
          desigination="Account Expert  Thinkable Laptop Company"
          deco={style.desigination}
        />
      </div>
      <div className={style.links}>
        <UserMobileNum data={data} fields={fields} mobile="">
          <div className={style.item}>
            <FaMobileAlt className={style.icon} />
            <UserShowMobileNum
              data={data}
              fields={fields}
              mobile="(123)-555 675 444"
              deco={style.text}
            />
          </div>
        </UserMobileNum>
        <UserEmail data={data} fields={fields} email="">
          <div className={style.item}>
            <MdEmail className={style.icon} />
            <UserShowEmail
              data={data}
              fields={fields}
              email="your email"
              deco={style.text}
            />
          </div>
        </UserEmail>
        <UserLinkdin data={data} fields={fields} linkdin="">
          <div className={style.item}>
            <FaLinkedin className={style.icon} />
            <UserShowLinkdin
              data={data}
              fields={fields}
              linkdin="Connect with on Linkedin"
              deco={style.text}
            />
          </div>
        </UserLinkdin>
        <UserMobileNum data={data} fields={fields} mobile="">
          <div className={style.item}>
            <IoCall className={style.icon} />
            <UserShowMobileNum
              data={data}
              fields={fields}
              mobile="(123)-555 675 444 Call me"
              deco={style.text}
            />
          </div>
        </UserMobileNum>
        <UserWebsite data={data} fields={fields} website="">
          <div className={style.item}>
            <FaInternetExplorer className={style.icon} />
            <UserShowWebsite
              data={data}
              fields={fields}
              website="your web.com"
              deco={style.text}
            />
          </div>
        </UserWebsite>
      </div>
     <div className={style.actions} >
     <button className={style.btn}>Save Contact</button>
     </div>
    </div>
  );
};

export default Card30;
