import { Link, Outlet } from "react-router-dom";

const UserLocation = ({ data, fields, location, children }) => {
  // Added 'children' prop
  return (
    <Link
      to={
        data?.link_location
          ? data.link_location
          : fields.link_location
          ? fields.link_location
          : location
      }
    >
      <Outlet />
      {children}
    </Link>
  );
};

export default UserLocation;
