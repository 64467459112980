import React from "react";
import ContactForm from "./contact-form/ContactForm";
import HeroSec from "../about-us/hero-sec/HeroSec";
import Map from "./map/Map";
const ContactUs = () => {
  return (
    <div>
      <HeroSec
        pageTitle="Contact Us"
        pageDetail="Got questions or feedback? Reach out to us via phone, email, or our convenient contact form. We’re here to help!"
      />
      <ContactForm />
      <Map />
    </div>
  );
};

export default ContactUs;
