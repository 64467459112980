import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import style from "./paymentSelector.module.css";
import { FaPaypal } from "react-icons/fa";
import { FaRegCreditCard } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
const PaymentSelectorModal = ({ open, setOpen }) => {
  const [method, setMethod] = useState("card");
  const navigate = useNavigate();

  const methodHandler = (type) => {
    if (type === "card") {
      navigate("/pay");
    }
    setMethod(type);
  };

  return (
    <div className={style.main}>
      <Modal centered show={open} onHide={() => setOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Select Payment Method</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={style.payment_sections}>
            <div onClick={() => methodHandler("card")} className={style.item}>
              <input checked={method === "card"} type="radio" />
              <div className={style.pair}>
                <span className={style.title}>Credit/Debit card</span>
                <FaRegCreditCard className={style.icon} />
              </div>
            </div>

            <div onClick={() => methodHandler("paypal")} className={style.item}>
              <input checked={method === "paypal"} type="radio" />
              <div className={style.pair}>
                <span className={style.title}>Paypal</span>
                <FaPaypal className={style.icon} />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PaymentSelectorModal;
