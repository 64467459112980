import React from "react";
import style from "./textInputField.module.css";
import useUpdateCard from "../../../utils/useUpdateCard";

const UserMobileField = () => {
  const { dispatch, fieldActions } = useUpdateCard();
  const mobileChange = (event) => {
    dispatch(fieldActions.setUserMobile("tel:" + event.target.value));
  };
  return (
    <>
      <input
        type="tel"
        pattern="^\+?[0-9\-]+"
        placeholder="mobile number"
        className={style.textInput}
        onChange={mobileChange}
      />
    </>
  );
};

export default UserMobileField;
