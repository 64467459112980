import React from "react";
import useUpdateCard from "../../../utils/useUpdateCard";
import style from "./textInputField.module.css";
const UserEmailField = () => {
  const { dispatch, fieldActions } = useUpdateCard();

  const emailChange = (event) => {
    dispatch(fieldActions.setUserEmail(event.target.value));
  };

  return (
    <>
      <input
        type="email"
        placeholder="enter email address"
        className={style.textInput}
        onChange={emailChange}
      />
    </>
  );
};

export default UserEmailField;
