import apis from "../../../utils/apis";
import httpAction from "./httpAction";
import { fieldActions } from "../card-fields-slice";

const SendCreateCardForm = async (data, paymentData, userId, dispatch) => {
  // const isEmpty = Object.values(data).every((value) => value === "");
  // if (isEmpty) {
  //   console.log("All fields are empty. Request stopped.");
  //   return;
  // }


  const apisList = apis();
  const data2 = {
    url: apisList.newCardCreate,
    method: "POST",
    body: { data: data, userId: userId, paymentData },
  };

  const result = await dispatch(httpAction(data2));

  return result;
};

export default SendCreateCardForm;
