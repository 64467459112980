import React from "react";
import style from "./singlePlane.module.css";
import { IoCheckmarkOutline } from "react-icons/io5";
import CtaButton from "../../ui/cta/CtaButton";
const SinglePlan = ({
  bg,
  Ctaclr,
  CtaBg,
  price,
  planeT,
  detail,
  f1,
  f2,
  f3,
  planClr,
  priceClr,
  optionClr,
  otherClr,
}) => {
  return (
    <div className={style.main}>
      <div className={style.section}>
        <div className={style.card} style={{ backgroundColor: bg ? bg : "" }}>
          <div className={style.item}>
            <p
              className={style.price}
              style={{ color: priceClr ? priceClr : "" }}
            >
              ${price}
            </p>
            <p
              className={style.duration}
              style={{ color: optionClr ? otherClr : "" }}
            >
              /Month
            </p>
          </div>
          <p className={style.basic} style={{ color: planClr ? planClr : "" }}>
            {planeT}
          </p>
          <p
            className={style.affordable}
            style={{ color: otherClr ? otherClr : "" }}
          >
            {detail}
          </p>
          <div className={style.bar}></div>
          <p
            className={style.includes}
            style={{ color: optionClr ? optionClr : "" }}
          >
            What's included?
          </p>
          <div className={style.items}>
            <IoCheckmarkOutline
              style={{ color: otherClr ? otherClr : "" }}
              className={style.check}
            />
            <p
              className={style.check1}
              style={{ color: otherClr ? otherClr : "" }}
            >
              {f1}
            </p>
          </div>
          <div className={style.items}>
            <IoCheckmarkOutline
              style={{ color: otherClr ? otherClr : "" }}
              className={style.check}
            />
            <p
              className={style.check1}
              style={{ color: otherClr ? otherClr : "" }}
            >
              {f2}
            </p>
          </div>
          <div className={style.items}>
            <IoCheckmarkOutline
              style={{ color: otherClr ? otherClr : "" }}
              className={style.check}
            />
            <p
              className={style.check1}
              style={{ color: otherClr ? otherClr : "" }}
            >
              {f3}
            </p>
          </div>
          <div className={style.action}>
            <CtaButton
              bg={CtaBg ? CtaBg : "black"}
              width="100%"
              animation={true}
              title="get startted"
              clr={Ctaclr ? Ctaclr : "white"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SinglePlan;
