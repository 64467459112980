import React from "react";
import user from "../../../../assets/cards/card4/user.jpg";
import logo from "../../../../assets/cards/card4/logo.png";
import { IoCall } from "react-icons/io5";
import { FaEnvelopeOpenText } from "react-icons/fa";
import { FaMailBulk } from "react-icons/fa";
import { FaInternetExplorer } from "react-icons/fa6";
import { FaLocationArrow } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa6";
import { FaTwitter } from "react-icons/fa";
import { useSelector } from "react-redux";
import style from "./card4.module.css";

// common components
import UserBrandLogo from "../common/user-logo-com/UserBrandLogo";
import UserProfile from "../common/user-profile-com/UserProfile";
import UserName from "../common/UserName";
import UserCall from "../common/UserCall";
import UserEmail from "../common/UserEmail";
import UserWebsite from "../common/UserWebsite";
import UserLocation from "../common/UserLocation";
import UserYoutube from "../common/UserYoutube";
import UserLinkdin from "../common/UserLinkdin";
import UserTwitter from "../common/UserTwitter";

const Card4 = ({ data }) => {
  const fields = useSelector((state) => state.cardFiled);

  return (
    <div className={style.main}>
      <div className={style.header}>
        <UserBrandLogo
          deco={style.user_bran_logo}
          data={data}
          fields={fields}
          logo={logo}
        />
      </div>
      <div className={style.user_profile_section}>
        <div>
          <UserProfile
            deco={style.user_profile}
            data={data}
            fields={fields}
            profile={user}
          />
        </div>
        <div>
          <UserName
            deco={style.name}
            data={data}
            fields={fields}
            name="Chris M. Laflamme"
          />
        </div>
      </div>
      <div className={style.icons}>
        <div>
          <UserCall data={data} fields={fields} call="">
            <IoCall className={style.icon} />
          </UserCall>
          <UserEmail data={data} fields={fields} email="">
            <FaEnvelopeOpenText className={style.icon} />
          </UserEmail>
          <UserEmail data={data} fields={fields} email="">
            <FaMailBulk className={style.icon} />
          </UserEmail>
          <UserWebsite data={data} fields={fields} website="">
            <FaInternetExplorer className={style.icon} />
          </UserWebsite>
        </div>
        <div>
          <UserLocation data={data} fields={fields} location="">
            <FaLocationArrow className={style.icon} />
          </UserLocation>
          <UserYoutube data={data} fields={fields} youtube="">
            <FaYoutube className={style.icon} />
          </UserYoutube>
          <UserLinkdin data={data} fields={fields} linkdin="">
            <FaLinkedin className={style.icon} />
          </UserLinkdin>
          <UserTwitter data={data} fields={fields} twitter="">
            <FaTwitter className={style.icon} />
          </UserTwitter>
        </div>
        <div className={style.bar}></div>
      </div>
      <div className={style.footer} >
        <span>All rights reserved</span>
      </div>
    </div>
  );
};

export default Card4;
