import React, { useRef, useState } from "react";

import style from "./shop.module.css";

import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import cardList from "../../utils/cardList";
import formList from "../../utils/formList";
import LoadingSpinner from "../ui/LoadingSpinner";
import { fieldActions } from "../../store/slices/card-fields-slice";

import { FaArrowAltCircleLeft } from "react-icons/fa";
import { FaArrowAltCircleRight } from "react-icons/fa";
// slides

import slid1 from "../../assets/cards/card1/card1.jpg";
import slide1 from "../../assets/slide-card-1.PNG";
import slide4 from "../../assets/cards/card4/card4.jpg";
import slide5 from "../../assets/cards/card5/card5.jpg";
import slide6 from "../../assets/cards/card6/card6.jpg";
import slide7 from "../../assets/cards/card7/card7.jpg";
import slide8 from "../../assets/cards/card8/card8.jpg";
import slide9 from "../../assets/cards/card9/card9.jpg";
import slide10 from "../../assets/cards/card10/card10.jpg";
import slide11 from "../../assets/cards/card11/card11.jpg";
import slide12 from "../../assets/cards/card12/card12.jpg";
import slide13 from "../../assets/cards/card13/card13.jpg";
import slide14 from "../../assets/cards/card14/card14.jpg";
import slide15 from "../../assets/cards/card15/card15.jpg";
import slide16 from "../../assets/cards/card16/card16.jpg";
import slide17 from "../../assets/cards/card17/card17.jpg";
import slide18 from "../../assets/cards/card18/card18.jpg";
import slide19 from "../../assets/cards/card19/slide19.jpg";
import slide20 from "../../assets/cards/card20/card20.jpg";
import slide21 from "../../assets/cards/card21/card21.jpg";
import slide22 from "../../assets/cards/card23/card21.jpg";
import slide23 from "../../assets/cards/card24/card24.jpg";
import slide24 from "../../assets/cards/card25/card25.jpg";
import slide25 from "../../assets/cards/card26/card26.jpg";
import slide26 from "../../assets/cards/card27/card27.jpg";
import slide27 from "../../assets/cards/card28/card28.jpg";
import slide28 from "../../assets/cards/card29/card29.jpg";
import slide29 from "../../assets/cards/card30/card30.jpg";
import slide30 from "../../assets/cards/card31/card31.jpg";
import slide31 from "../../assets/cards/card32/card32.jpg";
import slide32 from "../../assets/cards/card33/card33.jpg";
import slide33 from "../../assets/cards/card34/card35.jpg";
let isInitial = false;
const Shop = () => {
  const sliderRef = useRef(null);

  const rightClickHandler = () => {
    if (sliderRef.current) {
      const offsetWidth = sliderRef.current.offsetWidth;
      const scrollAmount = offsetWidth;
      const currentPosition = sliderRef.current.scrollLeft;
      const targetPosition = currentPosition + scrollAmount;
      sliderRef.current.scrollTo({
        left: targetPosition,
        behavior: "smooth",
      });
    }
  };

  const leftClickHandler = () => {
    if (sliderRef.current) {
      const offsetWidth = sliderRef.current.offsetWidth;
      const scrollAmount = offsetWidth;
      const currentPosition = sliderRef.current.scrollLeft;
      const targetPosition = currentPosition + scrollAmount;
      sliderRef.current.scrollTo({
        left: -targetPosition,
        behavior: "smooth",
      });
    }
  };

  const slideArray = [
    slid1,
    slide1,
    slide4,
    slide5,
    slide6,
    slide7,
    slide8,
    slide9,
    slide10,
    slide11,
    slide12,
    slide13,
    slide14,
    slide15,
    slide16,
    slide17,
    slide18,
    slide19,
    slide20,
    slide21,
    slide22,
    slide23,
    slide24,
    slide25,
    slide26,
    slide27,
    slide28,
    slide29,
    slide30,
    slide31,
    slide32,
    slide33,
  ];

  const dispatch = useDispatch();

  const [selected, setSelected] = useState("");
  const [selectedForm, setSelectedForm] = useState("");

  const isLoading = useSelector((state) => state.ui.loading);

  const cardRef = useRef();
  const isAuth = useSelector((state) => state.auth.login);
  const navigate = useNavigate();

  const selectedHandler = (index) => {
    // if (!isAuth) {
    //   const ele = document.getElementById("layout");
    //   ele.style.transform = "translateX(-100%)";
    //   ele.style.opacity = 0;
    //   ele.style.transition = "all 0.3s ease";

    //   setTimeout(() => {
    //     navigate("/login");
    //   }, 200);
    //   return;
    // }

    const list = cardList();
    const formProvide = formList();
    setSelected(list[index]);
    setSelectedForm(formProvide[index]);
    cardRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    isInitial = true;
    dispatch(fieldActions.setCardIndex(index));
  };

  return (
    <div className={style.main}>
      <section className={style.section}>
        <div className={style.slider_container}>
          <FaArrowAltCircleLeft
            onClick={leftClickHandler}
            className={style.btn1}
          />
          <FaArrowAltCircleRight
            onClick={rightClickHandler}
            className={style.btn2}
          />
          <div ref={sliderRef} className={style.slider}>
            {slideArray.map((slide, index) => {
              return (
                <div
                  key={index}
                  onClick={() => selectedHandler(index)}
                  className={style.slide}
                >
                  <img src={slide} alt="capture" className={style.img} />
                </div>
              );
            })}
          </div>
        </div>

        <div className={selected ? style.rowExp : style.row}>
          <div className={style.col1}>{selectedForm}</div>
          <div ref={cardRef} className={style.col2}>
            {isLoading && (
              <div className={style.spin}>
                <LoadingSpinner />
              </div>
            )}
            {selected}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Shop;
