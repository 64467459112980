import style from "./textInputField.module.css";
import useUpdateCard from "../../../utils/useUpdateCard";
const UserDesginationField = () => {
  const { dispatch, fieldActions } = useUpdateCard();
  const designationChange = (event) => {
    dispatch(fieldActions.setUserDesignation(event.target.value));
  };

  return (
    <>
      <input
        onChange={designationChange}
        type="text"
        placeholder="enter designation"
        className={style.textInput}
      />
    </>
  );
};

export default UserDesginationField;
