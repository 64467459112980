import React from 'react'
import Footer from './footer/Footer'
import Header from './header/Header'
import style from './layout.module.css'
import {Outlet} from 'react-router-dom'
const Layout = () => {
  return (
    <div id='layout' >
      <Header/>
      <Outlet/>
      <Footer/>
    </div>
  )
}

export default Layout
