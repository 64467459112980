import React from "react";

const UserBioShow = ({ data, fields, bio, deco }) => {
  return (
    <>
      <span className={deco}>
        {data?.user_bio
          ? data.user_bio
          : fields.user_bio
          ? fields.user_bio
          : bio}
      </span>
    </>
  );
};

export default UserBioShow;
