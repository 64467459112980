import React from "react";
import style from "./card16.module.css";
import profile from "../../../../assets/cards/card16/profile.jpg";
import logo from "../../../../assets/cards/card16/logo.png";

import { IoCall } from "react-icons/io5";
import { FaMobileAlt } from "react-icons/fa";
import { MdOutlineMailOutline } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa";
import { FaLocationArrow } from "react-icons/fa6";
import { MdDashboard } from "react-icons/md";
import { FaDownload } from "react-icons/fa";
import { FaShareAlt } from "react-icons/fa";
import { IoPerson } from "react-icons/io5";
import { FaInstagramSquare } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";
import { FaSpotify } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaMusic } from "react-icons/fa";
import { FaDeezer } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FaInternetExplorer } from "react-icons/fa6";
import { useSelector } from "react-redux";
import UserProfile from "../common/user-profile-com/UserProfile";
import UserShowName from "../common/UserShowName";
import UserShowDesigination from "../common/UserShowDesigination";
import UserBrandLogo from "../common/user-logo-com/UserBrandLogo";
import UserBioShow from "../common/UserBioShow";
import UserMobileNum from "../common/UserMobileNum";
import UserEmail from "../common/UserEmail";
import UserWebsite from "../common/UserWebsite";
import UserWhatsapp from "../common/UserWhatsapp";
import UserFacebook from "../common/UserFacebook";
import UserInsta from "../common/UserInsta";
const Card16 = ({ data }) => {
  const fields = useSelector((state) => state.cardFiled);
  return (
    <div className={style.main}>
      <div className={style.profile_section}>
        <UserProfile
          data={data}
          fields={fields}
          profile={profile}
          deco={style.profile}
        />

        <div className={style.name_section}>
          <UserBrandLogo
            data={data}
            fields={fields}
            logo={logo}
            deco={style.logo}
          />
          <UserShowName
            data={data}
            fields={fields}
            name="Linkin Park"
            deco={style.name}
          />
          <UserShowDesigination
            data={data}
            fields={fields}
            desigination="CEO"
            deco={style.desigination}
          />
        </div>
      </div>

      <div className={style.about_section}>
        <UserBioShow
          data={data}
          fields={fields}
          bio="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo."
          deco={style.bio}
        />
      </div>
      <div className={style.links}>
        <UserMobileNum data={data} fields={fields} mobile="">
          <div className={style.item}>
            <FaMobileAlt className={style.icon} />
            <span className={style.title}>Mobile</span>
          </div>
        </UserMobileNum>
        <UserEmail data={data} fields={fields} email="">
          <div className={style.item}>
            <MdOutlineMailOutline className={style.icon} />
            <span className={style.title}>Email</span>
          </div>
        </UserEmail>
        <UserWebsite data={data} fields={fields} website="">
          <div className={style.item}>
            <FaInternetExplorer className={style.icon} />
            <span className={style.title}>Website</span>
          </div>
        </UserWebsite>
      </div>
      <div className={style.links}>
        <UserWhatsapp data={data} fields={fields} whatsapp="">
          <div className={style.item}>
            <FaWhatsapp className={style.icon} />
            <span className={style.title}>Whatsapp</span>
          </div>
        </UserWhatsapp>
        <UserFacebook data={data} fields={fields} facebook="">
          <div className={style.item}>
            <FaFacebookF className={style.icon} />
            <span className={style.title}>Facebook</span>
          </div>
        </UserFacebook>
        <UserInsta data={data} fields={fields} insta="">
          <div className={style.item}>
            <FaInstagramSquare className={style.icon} />
            <span className={style.title}>Instagram</span>
          </div>
        </UserInsta>
      </div>
      <div className={style.music_links}>
        <div className={style.music_title}>Listen Us On</div>
        <div className={style.Links_color}>
          <FaYoutube className={style.color2} />
          <FaSpotify className={style.color3} />
          <FaMusic className={style.color5} />
          <FaDeezer className={style.color4} />
        </div>
      </div>
      <div className={style.contact}>
        <button className={style.btn}>Add To Contact</button>
      </div>
      <div className={style.footer}>All Rights reserved</div>
    </div>
  );
};

export default Card16;
