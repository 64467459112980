import React, { useEffect, useState } from "react";
import style from "./loginForm.module.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { authActions } from "../../../store/slices/auth-slice";
const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailErr, setEmailErr] = useState(false);

  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const emailChane = (event) => {
    setEmail(event.target.value);
  };
  const passChange = (event) => {
    setPassword(event.target.value);
  };

  useEffect(() => {
    if (emailPattern.test(email)) {
      setEmailErr(false);
    }
  }, [email]);

  const submitHandler = (event) => {
    event.preventDefault();

    if (!emailPattern.test(email)) {
      setEmailErr(true);
      return;
    }

    if (email && password.trim().length > 6) {
      dispatch(authActions.setLoagin(true));
      navigate("/");
    }
  };

  return (
    <div className={style.main}>
      <section className={style.section}>
        <div className={style.formSec}>
          <form type="submit" onSubmit={submitHandler}>
            <div className={style.formItem}>
              <label>email</label>
              <input
                onChange={emailChane}
                placeholder="Email address"
                required
                type="email"
                className={emailErr ? style.error : style.input}
              />
            </div>
            <div className={style.formItem}>
              <label>password</label>
              <input
                onChange={passChange}
                placeholder="Password "
                required
                type="password"
                className={style.input}
              />
            </div>
            <button className={style.loginBtn} type="submit">
              login
            </button>
          </form>

          <div className={style.bar}></div>
          <div className={style.links}>
            <Link to={"/register"} className={style.link}>
              create new account?
            </Link>
            <Link className={style.link}>forgot password?</Link>
          </div>
        </div>
      </section>
    </div>
  );
};

export default LoginForm;
