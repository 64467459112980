import React, { useRef } from "react";
import style from "./vision.module.css";
import card from "../../../assets/about-us-page/vision-sec/card.jpg";
import CtaButton from "../../ui/cta/CtaButton";
import { useInView, motion } from "framer-motion";
const VisionSec = () => {
  const ref = useRef();
  const inView = useInView(ref, { once: true });

  return (
    <div className={style.main}>
      <section className={style.section}>
        <div ref={ref} className={style.row}>
          <motion.div
            initial={{ y: 300, opacity: 0 }}
            animate={{ y: inView ? 0 : 300, opacity: inView ? 1 : 0 }}
            transition={{ duration: 1.5 }}
            className={style.col}
          >
            <div className={style.image}>
              <img src={card} className={style.img} />
            </div>
          </motion.div>
          <motion.div
            initial={{ y: 400, opacity: 0 }}
            animate={{ y: inView ? 0 : 400, opacity: inView ? 1 : 0 }}
            transition={{ duration: 1 }}
            className={style.col}
          >
            <p className={style.title}>Our Vision</p>
            <p className={style.detail}>
              Our mission is to empower professionals like you to make lasting
              connections, stand out from the crowd, and leave a memorable
              impression on everyone you meet. Whether you’re networking at a
              conference, meeting a potential client, or connecting with
              colleagues, our NFC business cards ensure that you’re always
              prepared to make a meaningful connection.
            </p>
            <CtaButton
              title="Learn More"
              animation={true}
              bg="black"
              clr="white"
              width="120px"
            />
          </motion.div>
        </div>
      </section>
    </div>
  );
};

export default VisionSec;
