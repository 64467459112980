import React from "react";
import style from "./feature.module.css";
const SingleFeature = ({ title, detail, icon }) => {
  return (
    <div className={style.main}>
      <div className={style.card}>
        <div className={style.outer}>
          <div className={style.content}>
            <div className={style.conditionalIcon}>
              <img className={style.condIcon} src={icon} alt="icon" />
            </div>
            <p className={style.innderTitle}>{title}</p>
            <p className={style.detail}>{detail}</p>
          </div>
          <div className={style.box}>
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img className={style.icon} src={icon} alt="icon" />
              </div>
              <p className={style.title}>{title}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleFeature;
