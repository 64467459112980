import React, { useRef } from "react";
import style from "./planeSec.module.css";
import SinglePlan from "./SinglePlan";
import { useInView, motion } from "framer-motion";
const HomePlanSec = () => {
  const ref = useRef();
  const inView = useInView(ref, { once: true });
  const boxVarienrt = {
    hidden: { y: 600, opacity: 0 },
    visible: {
      y: inView ? 0 : 600,
      opacity: inView ? 1 : 0,

    transition: {
        duration: 2.1,
      },
    },
  };

  return (
    <div className={style.main}>
      <div ref={ref} className={style.section}>
        <div className={style.head}>
          <p className={style.pTitle}>PRICING PLAN</p>
          <p className={style.pHeading}>Choose Your Plan</p>
          <p className={style.pDetail}>
            Your journey begins here. ‘Choose Your Plan’ and take the first step
            <br />
            towards personalized NFC solutions tailored to your needs.
          </p>
        </div>
        <motion.div
          variants={boxVarienrt}
          initial="hidden"
          animate="visible"
          className={style.plans}
        >
          <SinglePlan
            price="25"
            planeT="Basic Plan"
            detail="Affordable and essential. Ideal for individuals and small businesses."
            f1="Up to 3 NCF cards"
            f2="Secure personal file"
            f3="24/7 full support"
          />
          <SinglePlan
            priceClr="white"
            planClr="white"
            optionClr="white"
            otherClr="#dad7da"
            price="36"
            planeT="Standard Plan"
            detail="Our Standard Plan delivers essential features, affordability, and reliability. It’s designed for ease of use, scalability, and comes with responsive customer support"
            f1="Up to 6 NFC cards"
            f2="Secure personal file"
            f3="24/7 full support"
            bg="black"
            CtaBg="white"
            Ctaclr="black"
          />
          <SinglePlan
            price="54"
            planeT="Deluxe Plan"
            detail="Experience the pinnacle of luxury and convenience. Our Deluxe Plan offers exclusive access, premium services, and tailored solutions for an unparalleled experience. Join us for the very best."
            f1="Up to 10 NFC cards"
            f2="Secure personal file"
            f3="24/7 full support"
          />
        </motion.div>
      </div>
    </div>
  );
};

export default HomePlanSec;
