import React from "react";

const UserShowLocation = ({ data, fields, location, deco }) => {


  return (
    <div className={deco}>
      {data?.link_location
        ? data.link_location
        : fields.link_location
        ? fields.link_location
        : location}
    </div>
  );
};

export default UserShowLocation;
