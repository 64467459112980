import React from "react";
import style from "./card10.module.css";
import { FaPlus } from "react-icons/fa";
import { PiArrowFatLineUpDuotone } from "react-icons/pi";
import { MdDashboardCustomize } from "react-icons/md";
import UserMobileNum from "../common/UserMobileNum";
import { useSelector } from "react-redux";
import profile from "../../../../assets/cards/card10/profile.jpg";
import banner from "../../../../assets/cards/card10/banner.jpg";
import UserProfile from "../common/user-profile-com/UserProfile";
import UserShowName from "../common/UserShowName";
import UserShowDesigination from "../common/UserShowDesigination";
import { FaMobileAlt } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import { FaEnvelopeOpenText } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import UserEmail from "../common/UserEmail";
import UserWhatsapp from "../common/UserWhatsapp";
import UserBioShow from "../common/UserBioShow";
import UserBanner from "../common/UserBanner";

const Card10 = ({ data }) => {
  const fields = useSelector((state) => state.cardFiled);
  return (
    <div className={style.main}>
      <div className={style.profile_sec}>
        <UserProfile
          data={data}
          fields={fields}
          profile={profile}
          deco={style.img}
        />
        <div className={style.name_section}>
          <UserShowName
            data={data}
            fields={fields}
            name="Michael Fassbender"
            deco={style.name}
          />
          <UserShowDesigination
            data={data}
            fields={fields}
            desigination="Marketing Manager"
            deco={style.desiginiation}
          />
        </div>
      </div>
      <div className={style.hero}>
        <div className={style.links}>
          <UserMobileNum data={data} fields={fields} mobile="">
            <FaMobileAlt className={style.icon} />
          </UserMobileNum>
          <UserEmail data={data} fields={fields} email="">
            <MdOutlineEmail className={style.icon} />
          </UserEmail>
          <UserEmail data={data} fields={fields} email="">
            <FaEnvelopeOpenText className={style.icon} />
          </UserEmail>
          <UserWhatsapp data={data} fields={fields} whatsapp="">
            <FaWhatsapp className={style.icon} />
          </UserWhatsapp>
        </div>
        <div className={style.user_bio}>
            <span className={style.about_title} >About Me</span>
          <UserBioShow
            data={data}
            fields={fields}
            bio="Hi. I am Michael, working as marketing manager."
            deco={style.bio}
          />
        </div>

        <div className={style.user_banner}>
          <UserBanner
            data={data}
            fields={fields}
            banner={banner}
            deco={style.banner}
          />
        </div>
      </div>
      <div className={style.bottom}>
        <div className={style.col1}>
          <MdDashboardCustomize className={style.iconB} />
          <PiArrowFatLineUpDuotone className={style.iconB} />
        </div>
        <div className={style.col2}>
          <span className={style.contact}> Add To Contact </span>
          <UserMobileNum data={data} fields={fields} mobile="">
            <FaPlus className={style.iconB} />
          </UserMobileNum>
        </div>
      </div>
      <div className={style.footer} >
        All Rights Reserved
      </div>
    </div>
  );
};

export default Card10;
