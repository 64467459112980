import React from "react";
import style from "./textInputField.module.css";
import useUpdateCard from "../../../utils/useUpdateCard";

const UserPaypalLinkField = () => {
  const { dispatch, fieldActions } = useUpdateCard();
  const paypalChange = (event) => {
    dispatch(fieldActions.setPaypal(event.target.value));
  };
  return (
    <>
      <input
        type="text"
        placeholder="Paypal account"
        className={style.textInput}
        onChange={paypalChange}
      />
    </>
  );
};

export default UserPaypalLinkField;
