import { Link, Outlet } from "react-router-dom";

const UserPintrest = ({ data, fields, pintrest, children }) => {
  // Added 'children' prop
  return (
    <Link
      to={
        data?.link_pintrest
          ? data.link_pintrest
          : fields.link_pintrest
          ? fields.link_pintrest
          : pintrest
      }
    >
      <Outlet />
      {children}
    </Link>
  );
};

export default UserPintrest;
