import React from "react";
import { useSelector } from "react-redux";
import profile from "../../../../assets/cards/card18/profile.png";
import style from "./card18.module.css";
import UserProfile from "../common/user-profile-com/UserProfile";
import UserShowName from "../common/UserShowName";
import UserShowDesigination from "../common/UserShowDesigination";
import UserMobileNum from "../common/UserMobileNum";
import { IoCall } from "react-icons/io5";
import UserWhatsapp from "../common/UserWhatsapp";
import { FaInternetExplorer, FaSave, FaWhatsapp } from "react-icons/fa";
import UserEmail from "../common/UserEmail";
import { MdEmail } from "react-icons/md";
import UserWebsite from "../common/UserWebsite";
import UserLocation from "../common/UserLocation";
import { FaLocationArrow } from "react-icons/fa6";
import UserBioShow from "../common/UserBioShow";
import UserShowLocation from "../common/UserShowLocation";
const Card18 = ({ data }) => {
  const fields = useSelector((state) => state.cardFiled);
  return (
    <div className={style.main}>
      <div className={style.header}></div>
      <div className={style.profile_section}>
        <UserProfile
          data={data}
          fields={fields}
          profile={profile}
          deco={style.profile}
        />
      </div>
      <div className={style.name_section}>
        <UserShowName
          data={data}
          fields={fields}
          name="Jennifer Lim"
          deco={style.name}
        />
        <UserShowDesigination
          data={data}
          fields={fields}
          deco={style.desigination}
          desigination="Business Development Director"
        />
      </div>
      <div className={style.links}>
        <UserMobileNum data={data} fields={fields} mobile="">
          <IoCall className={style.icon} />
        </UserMobileNum>
        <UserWhatsapp data={data} fields={fields} whatsapp="">
          <FaWhatsapp className={style.icon} />
        </UserWhatsapp>
        <UserEmail data={data} fields={fields} email="">
          <MdEmail className={style.icon} />
        </UserEmail>
      </div>
      <div className={style.links}>
        <UserWebsite data={data} fields={fields} website="">
          <FaInternetExplorer className={style.icon} />
        </UserWebsite>
        <UserLocation data={data} fields={fields} location="">
          <FaLocationArrow className={style.icon} />
        </UserLocation>
        <FaSave className={style.icon} />
      </div>
      <div className={style.about_section}>
        <div className={style.about_title}>About Me</div>

        <UserShowLocation
          data={data}
          fields={fields}
          deco={style.location}
          location="Street Number, Street Name,
Town, Postal Code,
State, province, Country"
        />
      </div>
      <div className={style.footer}>All Rights Reserved</div>
    </div>
  );
};

export default Card18;
