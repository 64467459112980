import React from "react";

const UserShowMobileNum = ({ data, fields, mobile, deco }) => {
  return (
    <div className={deco}>
      {data?.link_mobile
        ? data.link_mobile
        : fields.link_mobile
        ? fields.link_mobile
        : mobile}
    </div>
  );
};

export default UserShowMobileNum;
