import React from "react";
import UserNameFiled from "../../../ui/card-fileds/UserNameFiled";
import UserEmailField from "../../../ui/card-fileds/UserEmailField";
import UserWebsiteLinkField from "../../../ui/card-fileds/UserWebsiteLinkField";
import UserLocationField from "../../../ui/card-fileds/UserLocationFeild";
import UserTwitterLinkField from "../../../ui/card-fileds/UserTwitterLinkField";
import UserBrandPictureField from "../../../ui/card-fileds/UserBrandPictureField";
import UserProfileImgField from "../../../ui/card-fileds/UserProfileImgField";
import UserMobileField from "../../../ui/card-fileds/UserMobileField";
import UserDesginationField from "../../../ui/card-fileds/UserDesginationField";
import UserFacebookLinkField from "../../../ui/card-fileds/UserFacebookLinkField";
import UserInstaLinkField from "../../../ui/card-fileds/UserInstaLinkField";
import UserPintrestLinkField from "../../../ui/card-fileds/UserPintrestLinkField";
import UserGoogleFild from "../../../ui/card-fileds/UserGoogleField";
import PaymentModalOpner from "./payment-model-opner/PaymentModalOpner";

const CardForm4 = () => {
  return (
    <div>
      <UserNameFiled />
      <UserDesginationField />
      <UserMobileField />
      <UserLocationField />
      <UserEmailField />
      <UserWebsiteLinkField />
      <div style={{ display: "flex" }}>
        <UserBrandPictureField />
        <UserProfileImgField />
      </div>
      <UserFacebookLinkField />
      <UserTwitterLinkField />
      <UserInstaLinkField />
      <UserGoogleFild />
      <UserTwitterLinkField />
      <UserPintrestLinkField />
      <div>
        <PaymentModalOpner />
      </div>
    </div>
  );
};

export default CardForm4;
