import { Link, Outlet } from "react-router-dom";

const UserWebsite = ({ data, fields, website, children }) => {
  // Added 'children' prop
  return (
    <Link
      to={
        data?.link_website
          ? data.link_website
          : fields.link_website
          ? fields.link_website
          : website
      }
    >
      <Outlet />
      {children}
    </Link>
  );
};

export default UserWebsite;
