import React from "react";
import { MdOutlineContactMail } from "react-icons/md";
import profile from "../../../../assets/cards/card15/profile.png";
import logo from "../../../../assets/cards/card9/logo.png";
import style from "./card15.module.css";
import { IoCall } from "react-icons/io5";
import { FaMobileAlt } from "react-icons/fa";
import { MdOutlineMailOutline } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa";
import { FaLocationArrow } from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa";
import { MdDashboard } from "react-icons/md";
import { FaDownload } from "react-icons/fa";
import { FaShareAlt } from "react-icons/fa";
import { IoPerson } from "react-icons/io5";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";

import { useSelector } from "react-redux";
import UserBrandLogo from "../common/user-logo-com/UserBrandLogo";
import UserProfile from "../common/user-profile-com/UserProfile";
import UserName from "../common/UserName";
import UserShowName from "../common/UserShowName";
import UserShowDesigination from "../common/UserShowDesigination";
import UserShowMobileNum from "../common/UserShowMobileNum";
import UserShowEmail from "../common/UserShowEmail";
import UserShowLocation from "../common/UserShowLocation";
import UserShowFacebook from "../common/UserShowFacebook";
const Card15 = ({ data }) => {
  const fields = useSelector((state) => state.cardFiled);
  return (
    <div className={style.main}>
      <div className={style.header}>
        <UserBrandLogo
          data={data}
          fields={fields}
          logo={logo}
          deco={style.logo}
        />
      </div>
      <div className={style.profile_section}>
        <UserProfile
          data={data}
          fields={fields}
          profile={profile}
          deco={style.profile}
        />
      </div>
      <div className={style.name_section}>
        <UserShowName
          data={data}
          fields={fields}
          name="Luna Charlotte"
          deco={style.name}
        />
        <UserShowDesigination
          data={data}
          fields={fields}
          desigination="CEO"
          deco={style.desigination}
        />
      </div>
      <div className={style.contect}>
        <button className={style.btn}>
          <MdOutlineContactMail className={style.contact_icon} /> save me as a
          Contact
        </button>
      </div>
      <div className={style.links}>
        <div className={style.item}>
          <FaMobileAlt className={style.icon} />
          <UserShowMobileNum
            data={data}
            fields={fields}
            mobile="+123 456 7890"
            deco={style.title}
          />
        </div>
        <div className={style.item}>
          <IoCall className={style.icon} />
          <UserShowMobileNum
            data={data}
            fields={fields}
            mobile="+123 456 7890"
            deco={style.title}
          />
        </div>
      </div>
      <div className={style.links}>
        <div className={style.item}>
          <MdOutlineMailOutline className={style.icon} />
          <UserShowEmail
            deco={style.title}
            data={data}
            fields={fields}
            email="yourmail"
          />
        </div>
        <div className={style.item}>
          <FaLocationArrow className={style.icon} />
          <UserShowLocation
            data={data}
            fields={fields}
            location="01, your street"
            deco={style.title}
          />
        </div>
      </div>
      <div className={style.links}>
        <div className={style.item}>
          <FaWhatsapp className={style.icon} />
          <UserShowMobileNum
            data={data}
            fields={fields}
            mobile="+123 456 7890"
            deco={style.title}
          />
        </div>
        <div className={style.item}>
          <FaFacebookF className={style.icon} />
          <UserShowFacebook
            data={data}
            fields={fields}
            facebook="01, your street"
            deco={style.title}
          />
        </div>
      </div>
      <div className={style.actions}>
        <button>
          <IoPerson className={style.contact_icon} /> About me
        </button>
        <button>
          <IoIosArrowDown className={style.contact_icon} /> Read More
        </button>
      </div>
      <div className={style.footer}>All Rights Reserved</div>
    </div>
  );
};

export default Card15;
