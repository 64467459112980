import React from "react";
import style from "./aboutus.module.css";
import HeroSec from "./hero-sec/HeroSec";
import WellcomeSec from "./wellcome-sec/WellcomeSec";
import SliderSec from "./slider-sec/SliderSec";
import VisionSec from "./vision-sec/VisionSec";
import HomeTestimonialSec from "../home/home-testimonial-section/HomeTestimonialSec";
const AboutUs = () => {
  return (
    <div className={style.main}>
      <HeroSec
        pageDetail="helps users understand who they are and what they stand for. This section often serves to establish credibility, build trust, and create a connection with the audience."
        pageTitle="About Us"
      />
      <WellcomeSec />
      <SliderSec />
      <VisionSec />
      <HomeTestimonialSec bg="white" text="black" light="gray" quote="black" />
    </div>
  );
};

export default AboutUs;
