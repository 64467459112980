import React from "react";
import style from "./card23.module.css";
import { useSelector } from "react-redux";
import profile from "../../../../assets/cards/card24/profile.png";
import logo from "../../../../assets/cards/card24/logo.png";
import UserBrandLogo from "../common/user-logo-com/UserBrandLogo";
import UserShowName from "../common/UserShowName";
import UserShowDesigination from "../common/UserShowDesigination";
import UserProfile from "../common/user-profile-com/UserProfile";
import UserBioShow from "../common/UserBioShow";
import UserMobileNum from "../common/UserMobileNum";
import { FaMobileAlt } from "react-icons/fa";
import UserShowMobileNum from "../common/UserShowMobileNum";
import UserEmail from "../common/UserEmail";
import { MdEmail } from "react-icons/md";
import UserShowEmail from "../common/UserShowEmail";
import UserWebsite from "../common/UserWebsite";
import { FaInternetExplorer } from "react-icons/fa6";
import UserShowWebsite from "../common/UserShowWebsite";
const CArd23 = ({ data }) => {
  const fields = useSelector((state) => state.cardFiled);
  return (
    <div className={style.main}>
      <div className={style.header}>
        <UserBrandLogo
          data={data}
          fields={fields}
          deco={style.logo}
          logo={logo}
        />

        <UserShowName
          data={data}
          fields={fields}
          name="Tina Smith"
          deco={style.name}
        />
        <UserShowDesigination
          data={data}
          fields={fields}
          desigination="Teacher"
          deco={style.desigination}
        />
         <div className={style.profile_section}>
          <UserProfile
            data={data}
            fields={fields}
            profile={profile}
            deco={style.profile}
          />
        </div>
      </div>
    
      <div className={style.about_section}>
        <div className={style.heading}>About Us</div>
        <UserBioShow
          data={data}
          fields={fields}
          bio="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo."
          deco={style.bio}
        />
      </div>
      <div className={style.links}>
        <div className={style.item}>
          <UserMobileNum data={data} fields={fields} mobile="">
            <FaMobileAlt className={style.icon} />
          </UserMobileNum>
          <UserShowMobileNum
            data={data}
            fields={fields}
            mobile="+93784784832"
            deco={style.title}
          />
        </div>
        <div className={style.item}>
          <UserEmail data={data} fields={fields} email="">
            <MdEmail className={style.icon} />
          </UserEmail>
          <UserShowEmail
            data={data}
            fields={fields}
            deco={style.title}
            email="youremail@email.com"
          />
        </div>
        <div className={style.item}>
          <UserWebsite data={data} fields={fields} website="">
            <FaInternetExplorer className={style.icon} />
          </UserWebsite>
          <UserShowWebsite
            data={data}
            fields={fields}
            website="yoursite.com"
            deco={style.title}
          />
        </div>
      </div>
    </div>
  );
};

export default CArd23;
