import React from "react";

const UserProfile = ({ data, fields, profile, deco }) => {
  return (
    <>
      <img
        className={deco}
        src={
          data?.user_profile
            ? data.user_profile
            : fields.user_profile
            ? fields.user_profile
            : profile
        }
      />
    </>
  );
};

export default UserProfile;
