import React from "react";
import style from "./card11.module.css";
import profile from "../../../../assets/cards/card11/profile.png";
import banner from "../../../../assets/cards/card11/banner.jpg";
import { FaMobileAlt } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import { FaEnvelopeOpenText } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import UserEmail from "../common/UserEmail";
import UserWhatsapp from "../common/UserWhatsapp";
import UserMobileNum from "../common/UserMobileNum";
import { useSelector } from "react-redux";
import UserProfile from "../common/user-profile-com/UserProfile";
import UserShowName from "../common/UserShowName";
import UserShowDesigination from "../common/UserShowDesigination";
import UserBanner from "../common/UserBanner";

const Card11 = ({ data }) => {
  const fields = useSelector((state) => state.cardFiled);
  return (
    <div className={style.main}>
      <div className={style.user_profile_section}>
        <UserProfile
          data={data}
          fields={fields}
          profile={profile}
          deco={style.profile_image}
        />
      </div>
      <div className={style.user_name_section}>
        <UserShowName
          data={data}
          fields={fields}
          name="Linda Johnson"
          deco={style.name}
        />
      </div>
      <div className={style.bar}></div>
      <div className={style.user_desigination_section}>
        <UserShowDesigination
          data={data}
          fields={fields}
          desigination="Owner/CEO"
          deco={style.desigination}
        />
      </div>

      <div className={style.links}>
        <UserMobileNum data={data} fields={fields} mobile="">
          <FaMobileAlt className={style.icon} />
        </UserMobileNum>
        <UserEmail data={data} fields={fields} email="">
          <MdOutlineEmail className={style.icon} />
        </UserEmail>
        <UserEmail data={data} fields={fields} email="">
          <FaEnvelopeOpenText className={style.icon} />
        </UserEmail>
        <UserWhatsapp data={data} fields={fields} whatsapp="">
          <FaWhatsapp className={style.icon} />
        </UserWhatsapp>
      </div>
      <div className={style.user_banner_section}>
        <UserBanner
          data={data}
          fields={fields}
          banner={banner}
          deco={style.banner}
        />
      </div>
      <div className={style.footer}>All Rights Reserved</div>
    </div>
  );
};

export default Card11;
