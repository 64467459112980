import React from "react";
import style from "./card28.module.css";
import { useSelector } from "react-redux";
import profile from "../../../../assets/cards/card27/profile.png";
import facebook from "../../../../assets/cards/card27/icon-facebook.png";
import twitter from "../../../../assets/cards/card27/icon-twitter.png";
import insta from "../../../../assets/cards/card27/icon-insta.png";
import location from "../../../../assets/cards/card27/icon-location.png";
import safari from "../../../../assets/cards/card27/icon-safari.png";
import email from "../../../../assets/cards/card27/icon-email.png";
import call from "../../../../assets/cards/card27/icon-call.png";
import UserProfile from "../common/user-profile-com/UserProfile";
import UserShowName from "../common/UserShowName";
import UserShowDesigination from "../common/UserShowDesigination";
import UserShowEmail from "../common/UserShowEmail";
import UserWebsite from "../common/UserWebsite";
import UserMobileNum from "../common/UserMobileNum";
import UserEmail from "../common/UserEmail";
import UserLocation from "../common/UserLocation";
import UserInsta from "../common/UserInsta";
import UserFacebook from "../common/UserFacebook";
import UserTwitter from "../common/UserTwitter";
import UserShowWebsite from "../common/UserShowWebsite";

const Card28 = ({ data }) => {
  const fields = useSelector((state) => state.cardFiled);
  return (
    <div className={style.main}>
      <div className={style.header}>
        <div className={style.profile_section}>
          <UserProfile
            data={data}
            fields={fields}
            profile={profile}
            deco={style.profile}
          />
        </div>
      </div>

      <div className={style.name_section}>
        <UserShowName
          data={data}
          fields={fields}
          name="Jessica"
          deco={style.name}
        />
        <UserShowDesigination
          data={data}
          fields={fields}
          desigination="Founder/CEO"
          deco={style.desigination}
        />
        <UserShowEmail
          data={data}
          fields={fields}
          email="email@email.com"
          deco={style.desigination}
        />
      </div>
      <div className={style.action}>
        <button className={style.btn}>Save To Contact</button>
      </div>

      <div className={style.web_section}>
        <div className={style.col}>
          <UserWebsite data={data} fields={fields} website="">
            <img src={safari} className={style.color_icon} />
          </UserWebsite>
        </div>
        <div className={style.col}>
          <h4>Our Website</h4>
          <UserShowWebsite
            data={data}
            fields={fields}
            website="website.con"
            deco={style.web}
          />
        </div>
      </div>
      <div className={style.links}>
        <UserMobileNum data={data} fields={fields} mobile="">
          <div className={style.item}>
            <img src={call} className={style.color_icon} />
            <span className={style.title}>Call</span>
          </div>
        </UserMobileNum>
        <UserEmail data={data} fields={fields} email="">
          <div className={style.item}>
            <img src={email} className={style.color_icon} />
            <span className={style.title}>Email</span>
          </div>
        </UserEmail>
        <UserLocation data={data} fields={fields} location="">
          <div className={style.item}>
            <img src={location} className={style.color_icon} />
            <span className={style.title}>Location</span>
          </div>
        </UserLocation>
      </div>
      <div className={style.links}>
        <UserInsta data={data} fields={fields} insta="">
          <div className={style.item}>
            <img src={insta} className={style.color_icon} />
            <span className={style.title}>Instagram</span>
          </div>
        </UserInsta>
        <UserFacebook data={data} fields={fields} facebook="">
          <div className={style.item}>
            <img src={facebook} className={style.color_icon} />
            <span className={style.title}>Facebook</span>
          </div>
        </UserFacebook>
        <UserTwitter data={data} fields={fields} twitter="">
          <div className={style.item}>
            <img src={twitter} className={style.color_icon} />
            <span className={style.title}>Twitter</span>
          </div>
        </UserTwitter>
      </div>
    </div>
  );
};

export default Card28;
