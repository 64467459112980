import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";

import Layout from "./components/layout/Layout";
import Checkout from "./components/shop/checkout/Checkout";
import AboutUs from "./components/about-us/AboutUs";
import HowToUse from "./components/how-to-use-page-sections/HowToUse";
import ContactUs from "./components/contact-us-page/ContactUs";
import CardDetail from "./components/user/card/card-detail/CardDetail";

import Card2 from "./components/products/cards/card2/Card2";
import Card1 from "./components/products/cards/card1/Card1";
//user pages
import Login from "./pages/Login";
import Register from "./pages/Register";

import Shop from "./components/shop/Shop";

import Home from "./components/home/Home";
import Test from "./components/Test";
const App = () => {
  useEffect(() => {
    window.scrollTo({
      top: "0",
      behavior: "smooth",
    });
  }, []);

  return (
    <Routes>
      <Route path="/card" element={<Card1 />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route element={<Layout />}>
        <Route path="/shop" element={<Shop />} />
        <Route path="/pay" element={<Checkout />} />
        <Route path="/" element={<Home />} />
        <Route path="/test" element={<Test />} />
        <Route path="/about/us" element={<AboutUs />} />
        <Route path="/how-to-use" element={<HowToUse />} />
        <Route path="/contact/us" element={<ContactUs />} />
      </Route>
      <Route path="/card/detail" element={<CardDetail />} />
    </Routes>
  );
};

export default App;
