import React from "react";
import style from "./feature.module.css";
import SingleFeature from "./single-feature/SingleFeature";
import featureArray from "./feature-data-array/FeatureArray";
import flipImg from "../../assets/flip.png";

const FeactureSection = () => {
  return (
    <div className={style.main}>
      <section className={style.section}>
        <p className={style.title}>OUR FEATURES</p>
        <p className={style.heading}>Enjoy a Better Experiences</p>
        <div className={style.row}>
          <div className={style.col1}>
            {featureArray.slice(0, 3).map((fet, index) => {
              return (
                <SingleFeature
                  key={index}
                  icon={fet.icon}
                  title={fet.title}
                  detail={fet.detail}
                />
              );
            })}
          </div>
          <div className={style.col - 2}>
            <img src={flipImg} alt="flip" className={style.flip} />
          </div>

          <div className={style.col3}>
            {featureArray.slice(3, 6).map((fet, index) => {
              return (
                <SingleFeature
                  key={index}
                  icon={fet.icon}
                  title={fet.title}
                  detail={fet.detail}
                />
              );
            })}
          </div>
        </div>
      </section>
    </div>
  );
};

export default FeactureSection;
