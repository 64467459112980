import React from "react";
import useUpdateCard from "../../../utils/useUpdateCard";
import style from "./textInputField.module.css";
const UserWebsiteLinkField = () => {
  const { dispatch, fieldActions } = useUpdateCard();

  const websiteChange = (event) => {
    dispatch(fieldActions.setUserWebsite(event.target.value));
  };

  return (
    <>
      <input
        type="text"
        placeholder="enter website link"
        className={style.textInput}
        onChange={websiteChange}
      />
    </>
  );
};

export default UserWebsiteLinkField;
