import React, { useEffect, useRef, useState } from "react";
import useUpdateCard from "../../../utils/useUpdateCard";
import { AiOutlineCloudUpload } from "react-icons/ai";
import style from "./textInputField.module.css";
import sendCreateCardPicture from "../../../store/slices/actions/sendCreateCardPicture";
const UserProfileImgField = () => {
  const { dispatch, fieldActions } = useUpdateCard();
  const fileRef = useRef();
  const [profile, setProfile] = useState();
  const profileChange = (event) => {
    const file = event.target.files[0];
    setProfile(file);
  };
  const uploadProfile = async () => {
    const result = await sendCreateCardPicture(profile, dispatch);
    if (result?.status) {
      dispatch(fieldActions.setUserProfileImg(result?.imgPath));
    }
  };

  useEffect(() => {
    if (profile) {
      uploadProfile();
    }
  }, [profile]);

  const fileClick = () => {
    fileRef.current.click();
  };

  return (
    <>
      <input
        ref={fileRef}
        type="file"
        accept="image/jpg,image/png,image/jpeg"
        style={{ display: "none" }}
        onChange={profileChange}
      />
      <button className={style.uploadButton} onClick={fileClick}>
        upload profile <AiOutlineCloudUpload />
      </button>
    </>
  );
};

export default UserProfileImgField;
