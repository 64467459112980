import React from "react";
import style from "./textInputField.module.css";
import useUpdateCard from "../../../utils/useUpdateCard";

const UserGoogleField = () => {
  const { dispatch, fieldActions } = useUpdateCard();
  const googleChange = (event) => {
    dispatch(fieldActions.setGoole(event.target.value));
  };
  return (
    <>
      <input
        type="url"
        placeholder="google account link"
        className={style.textInput}
        onChange={googleChange}
      />
    </>
  );
};

export default UserGoogleField;
