import Card2Form from "../components/products/cards/card2/Card2Form";
import Card1Form from "../components/products/cards/card2/Card1Form";
import CardForm3 from "../components/products/cards/card-forms/CardForm3";
import CardForm4 from "../components/products/cards/card-forms/CardForm4";
import Card5Form from "../components/products/cards/card-forms/Card5Form";
import CardForm6 from "../components/products/cards/card-forms/CardForm6";

const formList = () => {
  const list = [
    <Card2Form />,
    <Card1Form />,
    <CardForm3 />,
    <CardForm4 />,
    <Card5Form />,
    <CardForm6 />,
  ];

  return list;
};

export default formList;
