import React from "react";
import style from "./card26.module.css";
import profile from "../../../../assets/cards/card26/profile.png";
import logo from "../../../../assets/cards/card26/logo.png";
import UserBrandLogo from "../common/user-logo-com/UserBrandLogo";
import UserProfile from "../common/user-profile-com/UserProfile";
import UserShowName from "../common/UserShowName";
import UserShowDesigination from "../common/UserShowDesigination";
import UserMobileNum from "../common/UserMobileNum";
import {
  FaFacebook,
  FaInstagram,
  FaLocationArrow,
  FaMobileAlt,
  FaTwitch,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import UserEmail from "../common/UserEmail";
import { MdEmail } from "react-icons/md";
import UserLocation from "../common/UserLocation";
import UserBioShow from "../common/UserBioShow";
import UserShowMobileNum from "../common/UserShowMobileNum";
import UserShowEmail from "../common/UserShowEmail";
import { useSelector } from "react-redux";
import UserShowWebsite from "../common/UserShowWebsite";
import UserFacebook from "../common/UserFacebook";
import UserTwitter from "../common/UserTwitter";
import UserYoutube from "../common/UserYoutube";
import UserInsta from "../common/UserInsta";
const Card26 = ({ data }) => {
  const fields = useSelector((state) => state.cardFiled);

  return (
    <div className={style.main}>
      <div className={style.header}>
        <div className={style.logo_section}>
          <UserBrandLogo
            data={data}
            fields={fields}
            logo={logo}
            deco={style.logo}
          />
        </div>
        <div className={style.profile_section}>
          <UserProfile
            data={data}
            fields={fields}
            profile={profile}
            deco={style.profile}
          />
        </div>
        <div className={style.name_section}>
          <UserShowName
            data={data}
            fields={fields}
            name="Janet McGee"
            deco={style.name}
          />
          <UserShowDesigination
            data={data}
            fields={fields}
            deco={style.desigination}
            desigination="Founder, CEO
McGee Securty Corp"
          />
        </div>
      </div>

      <div className={style.links1}>
        <UserMobileNum data={data} fields={fields} mobile="">
          <FaMobileAlt className={style.icon1} />
        </UserMobileNum>
        <UserEmail data={data} fields={fields} email="">
          <MdEmail className={style.icon1} />
        </UserEmail>
        <UserLocation data={data} fields={fields} location="">
          <FaLocationArrow className={style.icon1} />
        </UserLocation>
      </div>
      <div className={style.bio_section}>
        <UserBioShow
          data={data}
          fields={fields}
          deco={style.bio}
          bio="Hi, I head on the best cybersecurity firm in the US."
        />
      </div>
      <div className={style.bar}></div>
      <div className={style.item}>
        <span className={style.title}>Mobil</span>
        <UserShowMobileNum
          data={data}
          fields={fields}
          deco={style.text}
          mobile="+0849383"
        />
      </div>
      <div className={style.item}>
        <span className={style.title}>Work</span>
        <UserShowMobileNum
          data={data}
          fields={fields}
          deco={style.text}
          mobile="+0849383"
        />
      </div>
      <div className={style.item}>
        <span className={style.title}>Email</span>
        <UserShowEmail
          data={data}
          fields={fields}
          deco={style.text}
          email="your email"
        />
      </div>
      <div className={style.bar}></div>
      <div className={style.item}>
        <span className={style.title}>Website</span>
        <UserShowWebsite
          data={data}
          fields={fields}
          website="your website"
          deco={style.text}
        />
      </div>
      <div className={style.bar}></div>
      <span className={style.connect}>Connect with me on</span>
      <div className={style.links}>
        <UserFacebook data={data} fields={fields} facebook="">
          <FaFacebook className={style.icon_facebook} />
        </UserFacebook>
        <UserTwitter data={data} fields={fields} twitter="">
          <FaTwitter className={style.icon_twitter} />
        </UserTwitter>
        <UserYoutube data={data} fields={fields} youtube="">
          <FaYoutube className={style.icon_youtube} />
        </UserYoutube>
        <UserInsta data={data} fields={fields} insta="">
          <FaInstagram className={style.icon_insta} />
        </UserInsta>
      </div>
    </div>
  );
};

export default Card26;
