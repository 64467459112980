import Card2 from "../components/products/cards/card2/Card2";
import Card1 from "../components/products/cards/card1/Card1";
import Card4 from "../components/products/cards/card4/Card4";
import Card5 from "../components/products/cards/card5/Card5";
import Card6 from "../components/products/cards/card6/Card6";
import Card7 from "../components/products/cards/card7/Card7";
import Card8 from "../components/products/cards/card8/Card8";
import Card9 from "../components/products/cards/card9/Card9";
import Card10 from "../components/products/cards/card10/Card10";
import Card11 from "../components/products/cards/card11/Card11";
import Card12 from "../components/products/cards/card12/Card12";
import Card13 from "../components/products/cards/card13/Card13";
import Card14 from "../components/products/cards/card14/Card14";
import Card15 from "../components/products/cards/card15/Card15";
import Card16 from "../components/products/cards/card16/Card16";
import Card17 from "../components/products/cards/card17/Card17";
import Card18 from "../components/products/cards/card18/Card18";
import Card19 from "../components/products/cards/card19/Card19";
import Card20 from "../components/products/cards/card20/Card20";
import Card21 from "../components/products/cards/card21/Card21";
import Card22 from "../components/products/cards/card22/Card22";
import Card23 from "../components/products/cards/card23/Card23";
import Card25 from "../components/products/cards/card25/Card25";
import Card26 from "../components/products/cards/card26/Card26";
import Card28 from "../components/products/cards/card28/Card28";
import Card29 from "../components/products/cards/card29/Card29";
import Card30 from "../components/products/cards/card30/Card30";
import Card31 from "../components/products/cards/card31/Card31";
import Card32 from "../components/products/cards/card32/Card32";
import Card33 from "../components/products/cards/card33/Card33";
import Card34 from "../components/products/cards/card34/Card34";
import Card35 from "../components/products/cards/card35/Card35";
const cardList = (data) => {
  const list = [
    <Card2 data={data} />,
    <Card1 data={data} />,
    <Card4 data={data} />,
    <Card5 data={data} />,
    <Card6 data={data} />,
    <Card7 data={data} />,
    <Card8 data={data} />,
    <Card9 data={data} />,
    <Card10 data={data} />,
    <Card11 data={data} />,
    <Card12 data={data} />,
    <Card13 data={data} />,
    <Card14 data={data} />,
    <Card15 data={data} />,
    <Card16 data={data} />,
    <Card17 data={data} />,
    <Card18 data={data} />,
    <Card19 data={data} />,
    <Card20 data={data} />,
    <Card21 data={data} />,
    <Card22 data={data} />,
    <Card23 data={data} />,
    <Card25 data={data} />,
    <Card26 data={data} />,
    <Card28 data={data} />,
    <Card29 data={data} />,
    <Card30 data={data} />,
    <Card31 data={data} />,
    <Card32 data={data} />,
    <Card33 data={data} />,
    <Card34 data={data} />,
    <Card35 data={data} />,
  ];

  return list;
};

export default cardList;
