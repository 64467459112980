import React from "react";
import style from "./card13.module.css";
import { IoCall } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa";
import { FaLocationArrow } from "react-icons/fa6";
import { FaInternetExplorer } from "react-icons/fa6";
import { FaInstagramSquare } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import facebook from "../../../../assets/cards/card13/facebook.png";
import twitter from "../../../../assets/cards/card13/twitter.png";
import banner from "../../../../assets/cards/card13/banner1.jpg";
import banner1 from "../../../../assets/cards/card13/banner2.jpeg";
import { FaShareAltSquare } from "react-icons/fa";
import { FaFacebookSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import profile from "../../../../assets/cards/card13/profile.png";
import { useSelector } from "react-redux";
import UserShowName from "../common/UserShowName";
import UserShowDesigination from "../common/UserShowDesigination";
import UserProfile from "../common/user-profile-com/UserProfile";
import UserBioShow from "../common/UserBioShow";
import UserMobileNum from "../common/UserMobileNum";
import UserEmail from "../common/UserEmail";
import UserFacebook from "../common/UserFacebook";
import UserLocation from "../common/UserLocation";
import UserWebsite from "../common/UserWebsite";
import UserBanner from "../common/UserBanner";
import UserBanner2 from "../common/UserBanner2";
import UserTwitter from "../common/UserTwitter";
import UserLinkdin from "../common/UserLinkdin";
const Card13 = ({ data }) => {
  const fields = useSelector((state) => state.cardFiled);
  return (
    <div className={style.main}>
      <div className={style.header}>
        <div className={style.name_section}>
          <UserShowName
            data={data}
            fields={fields}
            name="John Doe"
            deco={style.name}
          />
          <UserShowDesigination
            data={data}
            fields={fields}
            desigination="CEO"
            deco={style.desigination}
          />
        </div>
        <div className={style.profile_section}>
          <UserProfile
            data={data}
            fields={fields}
            profile={profile}
            deco={style.profile}
          />
          <UserBioShow
            data={data}
            fields={fields}
            bio="DJ TECHNOLOGY"
            deco={style.short_bio}
          />
        </div>
      </div>
      <div className={style.links}>
        <UserMobileNum data={data} fields={fields} mobile="">
          <IoCall className={style.icon} />
        </UserMobileNum>
        <UserEmail data={data} fields={fields} email="">
          <MdEmail className={style.icon} />
        </UserEmail>
        <UserFacebook data={data} fields={fields} facebook="">
          <FaFacebookSquare className={style.icon} />
        </UserFacebook>
      </div>
      <div className={style.links}>
        <UserLocation data={data} fields={fields} location="">
          <FaLocationArrow className={style.icon} />
        </UserLocation>
        <UserWebsite data={data} fields={fields} website="">
          <FaInternetExplorer className={style.icon} />
        </UserWebsite>
      </div>
      <div className={style.bar}></div>
      <div className={style.about_section}>
        <div className={style.heading}>About Me</div>
        <UserBioShow
          data={data}
          fields={fields}
          bio="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo."
          deco={style.bio}
        />
        <UserBanner
          data={data}
          fields={fields}
          banner={banner}
          deco={style.banner}
        />
        <UserBanner2
          data={data}
          fields={fields}
          banner2={banner1}
          deco={style.banner}
        />
      </div>
      <div className={style.bottom_links}>
        <UserFacebook data={data} fields={fields} facebook="">
          <FaFacebookSquare className={style.icon} />
        </UserFacebook>
        <UserTwitter data={data} fields={fields} twitter="">
          <FaTwitter className={style.icon} />
        </UserTwitter>
        <UserLinkdin data={data} fields={fields} linkdin="">
          <FaLinkedin className={style.icon} />
        </UserLinkdin>
        <FaShareAltSquare className={style.icon} />
      </div>
      <div className={style.footer}>All Rights Reserved</div>
    </div>
  );
};

export default Card13;
