import React, { useRef } from "react";
import style from "./homeachievement.module.css";
import HomeCounterSec from "../home-counter-section/HomeCounterSec";
import card from "../../../assets/home-achievement-sec/card.jpg";
import { useInView, motion } from "framer-motion";

const HomeAvchievementSec = () => {
  const ref = useRef();
  const inView = useInView(ref, { once: true });
  const boxVarients = {
    hidden: { y: 400, opacity: 0 },
    visible: {
      y: inView ? 0 : 400,
      opacity: inView ? 1 : 0,

      transition: {
        duration: 1.5,
      },
    },
  };

  return (
    <div className={style.main}>
      <motion.section
        ref={ref}
        variants={boxVarients}
        initial="hidden"
        animate="visible"
        className={style.section}
      >
        <p className={style.pageTitle}>ACHIEVEMENTS</p>
        <p className={style.pageDetail}>The Next Generation Technology</p>
        <div className={style.row}>
          <div className={style.col1}>
            <HomeCounterSec />
          </div>
          <div className={style.col2}>
            <div className={style.imgSection}>
              <img src={card} alt="card" className={style.img} />
            </div>
          </div>
        </div>
      </motion.section>
    </div>
  );
};

export default HomeAvchievementSec;
