import React from "react";
import style from "./textInputField.module.css";
import useUpdateCard from "../../../utils/useUpdateCard";

const UserLinkdinLinkField = () => {
  const { dispatch, fieldActions } = useUpdateCard();
  const linkdinChange = (event) => {
    dispatch(fieldActions.setUserLinkdin(event.target.value));
  };
  return (
    <>
      <input
        type="text"
        placeholder="linkdin account link"
        className={style.textInput}
        onChange={linkdinChange}
      />
    </>
  );
};

export default UserLinkdinLinkField;
