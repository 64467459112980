import { Link, Outlet } from "react-router-dom";

const UserWhatsapp = ({ data, fields, whatsapp, children }) => {
  // Added 'children' prop
  return (
    <Link
      to={
        data?.link_whatsapp
          ? data.link_whatsapp
          : fields.link_whatsapp
          ? fields.link_whatsapp
          : whatsapp
      }
    >
      <Outlet />
      {children}
    </Link>
  );
};

export default UserWhatsapp;
