import React from "react";
import { motion } from "framer-motion";
import Card1 from "./products/cards/card1/Card1";
import logo from "../assets/flip.png";
import Card2 from "./products/cards/card2/Card2";
const Test = () => {
  const box = {
    hidden: {
      y: "100vh",
      opacity: 0,
    },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        // when: "beforeChildren",

        staggerChildren: 0.5,
        duration: 2,
      },
    },
  };

  const child = {
    hidden: { y: 100, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  const data = {
    user_name: "ahmad",
    link_facebook:'https://google.com',
    link_whatsapp:'03104641760'
  };

  return (
    <div>
      <motion.div
        variants={box}
        initial="hidden"
        animate="visible"
        style={{
          margin: "6rem auto",
          width: "100%",
          backgroundColor: "seagreen",
          padding: "2rem",
          height: "600px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {[1, 2, 3].map((num, index) => {
          return (
            <motion.li
              variants={child}
              key={index}
              style={{
                width: "100px",
                height: "100px",
                margin: "1rem",
                listStyle: "none",
                backgroundColor: "white",
              }}
              transition={{ duration: 1 }}
            ></motion.li>
          );
        })}

        <motion.div
          variants={child}
          transition={{ duration: 3 }}
          style={{
            width: "100%",
            height: "200px",
            backgroundColor: "black",
          }}
        ></motion.div>
      </motion.div>
      <motion.div
        style={{
          width: "100px",
          height: "100px",
          backgroundColor: "red",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)", // Corrected typo and format
        }}
        animate={{
          y: [0, -40, 0],
          x: [0, -200, 0],
          borderRadius: [0, "30%", "50%"],
        }}
        transition={{
          ease: "linear",
          duration: 10,
          repeat: Infinity,
          times: [2, 2, 1, 0],
        }}
      ></motion.div>

      <Card1 data={data} />
    </div>
  );
};

export default Test;
