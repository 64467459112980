import React, { useRef } from "react";
import style from "./flipsection.module.css";
import right1 from "../../assets/hom-section-flip/right-1.png";
import right2 from "../../assets/hom-section-flip/right2.png";
import left1 from "../../assets/hom-section-flip/left1.png";
import lemft2 from "../../assets/hom-section-flip/left2.png";
import middel from "../../assets/hom-section-flip/middle.png";
import { motion, useScroll, useTransform } from "framer-motion";

const FlipSection = () => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["0 1", "1 1"],
  });

  // Dynamically adjust width based on scroll position
  const lowerSecWidth = useTransform(scrollYProgress, [0, 1], ["0%", "65%"]);
  const middleSecWidth = useTransform(scrollYProgress, [0, 1], ["0%", "55%"]);



  return (
    <div className={style.main}>
      <section className={style.section}>
        <p className={style.heiding}>Hold on to your brand's power.</p>
        <p className={style.detail}>
          Encourage your employees to expand their network. With your digital
          {window.innerWidth >= 756 && <br />}
          business card, feel the weight of your brand.
        </p>
      </section>

      <div className={style.flipSec} ref={targetRef}>
        <div className={style.container}>
          <motion.div
            className={style.lowerSec}
            style={{ width: lowerSecWidth }}
          >
            <img src={left1} className={style.img} />
            <img src={right1} className={style.img} />
          </motion.div>
          <motion.div
            className={style.middleSec}
            style={{ width: middleSecWidth }}
          >
            <img src={lemft2} className={style.img} />
            <img src={right2} className={style.img} />
          </motion.div>
          <div className={style.outerSec}>
            <img src={middel} className={style.img1} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlipSection;
