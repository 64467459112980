import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    login: false,
    hasCard: false,
  },
  reducers: {
    setLoagin(state, action) {
      state.login = action.payload;
    },
    setHasCard(state, action) {
      state.hasCard = action.payload;
    },
  },
});

export const authActions = authSlice.actions;
export default authSlice;
