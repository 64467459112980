import React from "react";
import style from "./textInputField.module.css";
import useUpdateCard from "../../../utils/useUpdateCard";

const UserPintrestLinkField = () => {
  const { dispatch, fieldActions } = useUpdateCard();
  const pintrestChange = (event) => {
    dispatch(fieldActions.setUserPintrest(event.target.value));
  };
  return (
    <>
      <input
        type="text"
        placeholder="pintrest account link"
        className={style.textInput}
        onChange={pintrestChange}
      />
    </>
  );
};

export default UserPintrestLinkField;
