import React from "react";

const UserShowPaypal = ({ data, fields, paypal, deco }) => {

  return (
    <div className={deco}>
      {data?.Link_paypal
        ? data.Link_paypal
        : fields.Link_paypal
        ? fields.Link_paypal
        : paypal}
    </div>
  );
};

export default UserShowPaypal;
