import React from "react";

const UserShowFacebook = ({ data, fields, facebook, deco }) => {
  return (
    <div className={deco}>
      {data?.link_facebook
        ? data.link_facebook
        : fields.link_facebook
        ? fields.link_facebook
        : facebook}
    </div>
  );
};

export default UserShowFacebook;
