import React from "react";
import style from "./card9.module.css";
import logo from "../../../../assets/cards/card9/logo.png";
import profile from "../../../../assets/cards/card9/profile.jpg";
import insta from "../../../../assets/cards/card9/insta.svg";
import facebook from "../../../../assets/cards/card9/facebook.svg";
import twitter from "../../../../assets/cards/card9/twitter.svg";
import { FaMobileAlt } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import { FaEnvelopeOpenText } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import UserBrandLogo from "../common/user-logo-com/UserBrandLogo";
import UserShowName from "../common/UserShowName";
import UserShowDesigination from "../common/UserShowDesigination";
import UserProfile from "../common/user-profile-com/UserProfile";
import UserMobileNum from "../common/UserMobileNum";
import UserEmail from "../common/UserEmail";
import UserWhatsapp from "../common/UserWhatsapp";
import UserFacebook from "../common/UserFacebook";
import UserShowFacebook from "../common/UserShowFacebook";
import UserInsta from "../common/UserInsta";
import UserShowInsta from "../common/UserShowInsta";
import UserTwitter from "../common/UserTwitter";
import UserShowTwitter from "../common/UserShowTwitter";
import { useSelector } from "react-redux";
const Card9 = ({ data }) => {
  const fields = useSelector((state) => state.cardFiled);
  return (
    <div className={style.main}>
      <div className={style.header}>
        <UserBrandLogo
          data={data}
          fields={fields}
          logo={logo}
          deco={style.logo}
        />
      </div>
      <div className={style.profile_sec}>
        <div className={style.name}>
          <UserShowName
            data={data}
            fields={fields}
            name="LINDA JOHNSON"
            deco={style.name_title}
          />
        </div>
        <div className={style.desigination}>
          <UserShowDesigination
            data={data}
            fields={fields}
            desigination="Owner/CEO"
            deco={style.desigination_title}
          />
        </div>
        <div className={style.image_section}>
          <UserProfile
            data={data}
            fields={fields}
            profile={profile}
            deco={style.img}
          />
          <div className={style.links}>
            <UserMobileNum data={data} fields={fields} mobile="fds">
              <FaMobileAlt className={style.icon} />
            </UserMobileNum>
            <UserEmail data={data} fields={fields} email="">
              <MdOutlineEmail className={style.icon} />
            </UserEmail>
            <UserEmail data={data} fields={fields} email="">
              <FaEnvelopeOpenText className={style.icon} />
            </UserEmail>
            <UserWhatsapp data={data} fields={fields} whatsapp="">
              <FaWhatsapp className={style.icon} />
            </UserWhatsapp>
          </div>
        </div>
      </div>
      <div className={style.main_links}>
        <div className={style.row}>
          <UserFacebook data={data} fields={fields} facebook="">
            <div className={style.item}>
              <img src={facebook} className={style.main_img} />
              <div className={style.url_sec}>
                <span className={style.title}> Facebook</span>
                <UserShowFacebook
                  data={data}
                  fields={fields}
                  facebook="Linda Johnson"
                  deco={style.url}
                />
              </div>
            </div>
          </UserFacebook>
        </div>
        <div className={style.row}>
          <UserInsta data={data} fields={fields} insta="">
            <div className={style.item}>
              <img src={insta} className={style.main_img} />
              <div className={style.url_sec}>
                <span className={style.title}>Instagram</span>
                <UserShowInsta
                  data={data}
                  fields={fields}
                  insta="Linda Johnson"
                  deco={style.url}
                />
              </div>
            </div>
          </UserInsta>
        </div>
        <div className={style.row}>
          <UserTwitter data={data} fields={fields} twitter="">
            <div className={style.item}>
              <img src={twitter} className={style.main_img} />
              <div className={style.url_sec}>
                <span className={style.title}> Facebook</span>
                <UserShowTwitter
                  data={data}
                  fields={fields}
                  twitter="Linda Johnson"
                  deco={style.url}
                />
              </div>
            </div>
          </UserTwitter>
        </div>
      </div>
      <div className={style.footer}>All Rights Reserved</div>
    </div>
  );
};

export default Card9;
