import React from "react";
import UserNameFiled from "../../../ui/card-fileds/UserNameFiled";
import UserDesginationField from "../../../ui/card-fileds/UserDesginationField";
import UserPostField from "../../../ui/card-fileds/UserPostField";
import UserBioField from "../../../ui/card-fileds/UserBioField";
import UserWhatsappField from "../../../ui/card-fileds/UserWhatsappField";
import UserMobileField from "../../../ui/card-fileds/UserMobileField";
import UserFacebookLinkField from "../../../ui/card-fileds/UserFacebookLinkField";
import UserTwitterLinkField from "../../../ui/card-fileds/UserTwitterLinkField";
import UserYoutubeLinkField from "../../../ui/card-fileds/UserYoutubeLinkField";
import UserInstaLinkField from "../../../ui/card-fileds/UserInstaLinkField";
import UserProfileImgField from "../../../ui/card-fileds/UserProfileImgField";
import PaymentModalOpner from "../card-forms/payment-model-opner/PaymentModalOpner";

const Card2Form = () => {
  return (
    <>
      <div>
        <UserNameFiled />
        <UserDesginationField />
        <UserPostField />
        <UserBioField />
        <UserWhatsappField />
        <UserMobileField />
        <UserProfileImgField />
      </div>
      <div>
        <UserFacebookLinkField />
        <UserTwitterLinkField />
        <UserYoutubeLinkField />
        <UserInstaLinkField />
      </div>
      <div>
        <PaymentModalOpner />
      </div>
    </>
  );
};

export default Card2Form;
