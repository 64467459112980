import React, { useRef } from "react";
import style from "./slider.module.css";
import slid1 from "../../../assets/about-us-page/wellcome-sec/slide1.jpg";
import slid2 from "../../../assets/about-us-page/wellcome-sec/slide2.jpg";
import slid3 from "../../../assets/about-us-page/wellcome-sec/slide3.jpg";
import slid4 from "../../../assets/about-us-page/wellcome-sec/slide4.jpg";
import Slider from "react-slick";
import { useInView, motion } from "framer-motion";

const SliderSec = () => {
  const ref = useRef();
  const inView = useInView(ref, { once: true });

  const settings = {
    Infinite: true,
    autoPlay: true,
    autoPlaySpeed: 2000,
    slidesToShow: window.innerWidth < 756 ? 1 : 3,
    slidesToScroll: 1,
    speed: 500,
  };
  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0 }}
      animate={{ opacity: inView ? 1 : 0 }}
      transition={{ duration: 1 }}
      className={style.main}
    >
      <section className={style.section}>
        <Slider {...settings}>
          <div className={style.image}>
            <img src={slid1} className={style.img} />
          </div>
          <div className={style.image}>
            <img src={slid2} className={style.img} />
          </div>
          <div className={style.image}>
            <img src={slid3} className={style.img} />
          </div>
          <div className={style.image}>
            <img src={slid4} className={style.img} />
          </div>
        </Slider>
      </section>
    </motion.div>
  );
};

export default SliderSec;
