import React from "react";

import UserNameFiled from "../../../ui/card-fileds/UserNameFiled";
import UserDesginationField from "../../../ui/card-fileds/UserDesginationField";
import UserBrandPictureField from "../../../ui/card-fileds/UserBrandPictureField";
import UserProfileImgField from "../../../ui/card-fileds/UserProfileImgField";
import UserWhatsappField from "../../../ui/card-fileds/UserWhatsappField";
import UserMobileField from "../../../ui/card-fileds/UserMobileField";
import UserEmailField from "../../../ui/card-fileds/UserEmailField";
import UserWebsiteLinkField from "../../../ui/card-fileds/UserWebsiteLinkField";
import UserHomeAddressField from "../../../ui/card-fileds/UserHomeAddressField";
import UserInstaLinkField from "../../../ui/card-fileds/UserInstaLinkField";
import UserLinkdinLinkField from "../../../ui/card-fileds/UserLinkdinLinkField";
import UserTiktokLinkField from "../../../ui/card-fileds/UserTiktokLinkField";
import UserPintrestLinkField from "../../../ui/card-fileds/UserPintrestLinkField";
import UserSnapchatLinkField from "../../../ui/card-fileds/UserSnapchatLinkField";
import UserYoutubeLinkField from "../../../ui/card-fileds/UserYoutubeLinkField";
import UserFacebookLinkField from "../../../ui/card-fileds/UserFacebookLinkField";
import UserTwitterLinkField from "../../../ui/card-fileds/UserTwitterLinkField";
import PaymentModalOpner from "../card-forms/payment-model-opner/PaymentModalOpner";
const Card1Form = () => {
  return (
    <>
      <div>
        <UserNameFiled />
        <UserDesginationField />
        <UserWhatsappField />
        <UserMobileField />
        <UserEmailField />
        <UserWebsiteLinkField />
        <UserHomeAddressField />
        <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
          <UserProfileImgField />
          <UserBrandPictureField />
        </div>
      </div>
      <div>
        <UserInstaLinkField />
        <UserFacebookLinkField />
        <UserYoutubeLinkField />
        <UserTiktokLinkField />
      </div>
      <div>
        <UserLinkdinLinkField />
        <UserTwitterLinkField />
        <UserSnapchatLinkField />
        <UserPintrestLinkField />
      </div>
      <div>
        <PaymentModalOpner />
      </div>
    </>
  );
};

export default Card1Form;
