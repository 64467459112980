import React from "react";
import style from "./textInputField.module.css";
import useUpdateCard from "../../../utils/useUpdateCard";

const UserInstaLinkField = () => {
  const { dispatch, fieldActions } = useUpdateCard();
  const instaChange = (event) => {
    dispatch(fieldActions.setUserInsta(event.target.value));
  };
  return (
    <>
      <input
        type="text"
        placeholder="insta account link"
        className={style.textInput}
        onChange={instaChange}
      />
    </>
  );
};

export default UserInstaLinkField;
