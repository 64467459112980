import React, { useEffect, useState } from "react";
import style from "./carddetail.module.css";
import apis from "../../../../utils/apis";
import httpAction from "../../../../store/slices/actions/httpAction";
import cardList from "../../../../utils/cardList";
import { useDispatch, useSelector } from "react-redux";
const CardDetail = () => {
  const params = new URLSearchParams(window.location.search);
  const id = params.get("id");
  const dispatch = useDispatch();
  const apiList = apis();

  const [card, setCard] = useState();

  const data = {
    url: apiList.getSingleCardById + "?id=" + id,
  };

  const getSingleCard = async () => {
    const result = await dispatch(httpAction(data));

    if (result?.status) {
      const index = result?.card.data?.card_index;
      //   console.log(index)
      const data = result?.card?.data;
      const list = cardList(data);
      setCard(list[index]);
    }
  };

  useEffect(() => {
    getSingleCard();
  }, [id]);

  return <div className={style.card}>{card}</div>;
};

export default CardDetail;
