import React from "react";
import profile from "../../../../assets/cards/card7/profile.png";
import { FaPaypal } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaInternetExplorer } from "react-icons/fa";
import logo from "../../../../assets/cards/card7/logo.png";
import style from "./card7.module.css";

// common components
import UserProfile from "../common/user-profile-com/UserProfile";
import UserBioShow from "../common/UserBioShow";
import UserShowWebsite from "../common/UserShowWebsite";
import UserWebsite from "../common/UserWebsite";
import UserLinkdin from "../common/UserLinkdin";
import { useSelector } from "react-redux";
import UserBrandLogo from "../common/user-logo-com/UserBrandLogo";
import UserShowName from "../common/UserShowName";
import UserShowDesigination from "../common/UserShowDesigination";
import UserPaypal from "../common/UserPaypal";
import UserShowPaypal from "../common/UserShowPaypal";
import UserShowLinkdin from "../common/UserShowLinkdin";

const Card7 = ({ data }) => {
  const fields = useSelector((state) => state.cardFiled);

  return (
    <div className={style.main}>
      <div className={style.header}>
        <UserBrandLogo
          data={data}
          fields={fields}
          logo={logo}
          deco={style.logo}
        />
      </div>
      <div className={style.user_profile}>
        <UserProfile
          data={data}
          fields={fields}
          profile={profile}
          deco={style.user_profile_img}
        />
      </div>
      <div className={style.user_profession}>
        <UserShowName
          data={data}
          fields={fields}
          name="Leonardo DiCaprio"
          deco={style.user_name}
        />
        <UserShowDesigination
          data={data}
          fields={fields}
          desigination="REAL ESTATE BROKER"
          deco={style.user_designation}
        />
        <UserBioShow
          data={data}
          fields={fields}
          bio="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo."
        deco={style.user_bio}
       />
      </div>
      <div className={style.user_links}>
        <div className={style.item}>
          <UserPaypal data={data} fields={fields} paypal="">
            <FaPaypal className={style.icon} />
          </UserPaypal>
          <UserShowPaypal
            data={data}
            fields={fields}
            paypal="Paypal"
            deco={style.title}
          />
        </div>
        <div className={style.item}>
          <UserWebsite data={data} fields={fields} website="">
            <FaInternetExplorer className={style.icon} />
          </UserWebsite>
          <UserShowWebsite
            data={data}
            fields={fields}
            website="Website"
            deco={style.title}
          />
        </div>
        <div className={style.item}>
          <UserLinkdin data={data} fields={fields} linkdin="">
            <FaLinkedin className={style.icon} />
          </UserLinkdin>
          <UserShowLinkdin
            data={data}
            fields={fields}
            linkdin="Linkdin"
            deco={style.title}
          />
        </div>
        <div className={style.footer}>All rights reserved</div>
      </div>
    </div>
  );
};

export default Card7;
