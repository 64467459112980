import React from "react";
import style from "./card5.module.css";
import { useSelector } from "react-redux";
import logo from "../../../../assets/cards/card5/logo.png";
import profile from "../../../../assets/cards/card5/user.jpg";
import { FaInternetExplorer } from "react-icons/fa6";
import { FaLocationArrow } from "react-icons/fa";
import { FaMobileAlt } from "react-icons/fa";
import { MdOutlineMail } from "react-icons/md";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaGooglePlusG } from "react-icons/fa";
import { FaPinterestP } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";

// common components
import UserProfile from "../common/user-profile-com/UserProfile";
import UserShowName from "../common/UserShowName";
import UserBrandLogo from "../common/user-logo-com/UserBrandLogo";
import UserShowDesigination from "../common/UserShowDesigination";
import UserWebsite from "../common/UserWebsite";
import UserShowWebsite from "../common/UserShowWebsite";
import UserMobileNum from "../common/UserMobileNum";
import UserShowMobileNum from "../common/UserShowMobileNum";
import UserLocation from "../common/UserLocation";
import UserShowLocation from "../common/UserShowLocation";
import UserEmail from "../common/UserEmail";
import UserShowEmail from "../common/UserShowEmail";
import UserFacebook from "../common/UserFacebook";
import UserTwitter from "../common/UserTwitter";
import UserInsta from "../common/UserInsta";
import UserGoogle from "../common/UserGoogle";
import UserPintrest from "../common/UserPintrest";

const Card5 = ({ data }) => {
  const fields = useSelector((state) => state.cardFiled);

  return (
    <div className={style.main}>
      <div className={style.user_profile_sec}>
        <div className={style.user_bg}>
          <UserProfile
            deco={style.user_profile}
            data={data}
            fields={fields}
            profile={profile}
          />

          <UserBrandLogo
            deco={style.user_logo}
            data={data}
            fields={fields}
            logo={logo}
          />
          <div className={style.user_info}>
            <UserShowName
              deco={style.name}
              data={data}
              fields={fields}
              name="KHIM RICHARDS"
            />
            <UserShowDesigination
              deco={style.desigination}
              data={data}
              fields={fields}
              desigination="REAL ESTATEAGENT"
            />
          </div>
        </div>
      </div>

      <div className={style.user_links}>
        <div className={style.row}>
          <div className={style.pair}>
            <UserMobileNum data={data} fields={fields} mobile="">
              <FaMobileAlt className={style.icon} />
            </UserMobileNum>
            <UserShowMobileNum
              data={data}
              fields={fields}
              mobile="(+)123-4567-8902"
              deco={style.mobile}
            />
          </div>
          <div className={style.pair}>
            <UserLocation data={data} fields={fields} location="">
              <FaLocationArrow className={style.icon} />
            </UserLocation>
            <UserShowLocation
              deco={style.location}
              data={data}
              fields={fields}
              location="12, Your Lane,Your Street, La dsdhas lksd lfj sdljf lsfjsdljflsd j flhds jhbvk hdkjf gkjhdf kgfhd"
            />
          </div>
        </div>
        <div className={style.bar} ></div>
        <div className={style.row}>
          <div className={style.pair}>
            <UserEmail data={data} fields={fields} emai="">
              <MdOutlineMail className={style.icon} />
            </UserEmail>
            <UserShowEmail
              data={data}
              fields={fields}
              email="Info@gmail.com"
              deco={style.email}
            />
          </div>
          <div className={style.pair}>
            <UserWebsite data={data} fields={fields} website="">
              <FaInternetExplorer className={style.icon} />
            </UserWebsite>
            <UserShowWebsite
              deco={style.website_url}
              data={data}
              fields={fields}
              website="Yourwebsite.com"
            />
          </div>
        </div>
      </div>
      <div className={style.connect_title}>CONNECT WITH ME</div>
      <div className={style.user_links_icon}>
        <UserFacebook data={data} fields={fields} facebook="">
          <FaFacebook className={style.simple_icon} />
        </UserFacebook>
        <UserTwitter data={data} fields={fields} twitter="">
          <FaTwitter className={style.simple_icon} />
        </UserTwitter>
        <UserInsta data={data} fields={fields} insta="">
          <FaInstagram className={style.simple_icon} />
        </UserInsta>
        <UserGoogle data={data} fields={fields} google="">
          <FaGooglePlusG className={style.simple_icon} />
        </UserGoogle>
        <UserPintrest data={data} fields={fields} pintrest="">
          <FaPinterestP className={style.simple_icon} />
        </UserPintrest>
      </div>
      <div className={style.footer}>
        <UserWebsite data={data} fields={fields} website="">
          <FaInternetExplorer className={style.simple_icon_sm} />
        </UserWebsite>
        <UserShowWebsite
          deco={style.website_url}
          data={data}
          fields={fields}
          website="ourwebsite.com"
        />
      </div>
    </div>
  );
};

export default Card5;
