import { Link, Outlet } from "react-router-dom";

const UserMobileNum = ({ data, fields, mobile, children }) => {
  // Added 'children' prop
  return (
    <Link
      to={
        data?.link_mobile
          ? data.link_mobile
          : fields.link_mobile
          ? fields.link_mobile
          : mobile
      }
    >
      <Outlet />
      {children}
    </Link>
  );
};

export default UserMobileNum;
