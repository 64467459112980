import pro1 from "../../../assets/home-products/product1.jpg";
import pro2 from "../../../assets/home-products/product2.jpg";
import pro3 from "../../../assets/home-products/product3.jpg";
import pro4 from "../../../assets/home-products/product4.jpg";

const data = [
  {
    img: pro1,
    title: "Metal Cards",
    detail: "Premium Look ",
    detail2: "Custom Design",
    price: "From Rs.5000/-",
  },
  {
    img: pro2,
    title: "Plastic Cards",
    detail: "Eco Friendly ",
    detail2: "Custom Design",
    price: "From Rs.3800/-",
  },
  {
    img: pro3,

    title: "Wood Cards",
    detail: "Basic card (mostly using) ",
    detail2: "Custom Design",
    price: "From Rs.2500/-",
  },
  {
    img: pro4,
    title: "Card Box",
    detail: "Card Box ",
    detail2: "Custom Design",
    price: "From Rs.6000/-",
  },
];

export default data;
