import React from "react";
import style from "./card6.module.css";
import profile from "../../../../assets/cards/card6/user.jpg";
import insta from "../../../../assets/cards/card6/insta.svg";
import facebook from "../../../../assets/cards/card6/facebook.svg";
import twitter from "../../../../assets/cards/card6/twitter.svg";
import { FaMobileAlt } from "react-icons/fa";
import { MdOutlineMailOutline } from "react-icons/md";
import { FaEnvelopeOpenText } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";

import UserProfile from "../common/user-profile-com/UserProfile";
import { useSelector } from "react-redux";
import UserShowName from "../common/UserShowName";
import UserShowDesigination from "../common/UserShowDesigination";
import UserEmail from "../common/UserEmail";
import UserMobileNum from "../common/UserMobileNum";
import UserWhatsapp from "../common/UserWhatsapp";
import UserFacebook from "../common/UserFacebook";
import UserShowFacebook from "../common/UserShowFacebook";
import UserInsta from "../common/UserInsta";
import UserShowInsta from "../common/UserShowInsta";
import UserTwitter from "../common/UserTwitter";
import UserShowTwitter from "../common/UserShowTwitter";
// common components

const Card6 = ({ data }) => {
  const fields = useSelector((state) => state.cardFiled);

  return (
    <div className={style.main}>
      <div className={style.user_profile_section}>
        <UserProfile data={data} fields={fields} profile={profile} />
        <div className={style.user_name_section}>
          <UserShowName
            deco={style.name}
            data={data}
            fields={fields}
            name="Lucille R.Grondin"
          />
          <UserShowDesigination
            data={data}
            fields={fields}
            desigination="Owner/CEO"
            deco={style.desigination}
          />
        </div>
      </div>
      <div className={style.user_Links}>
        <UserEmail data={data} fields={fields} email="">
          <div className={style.icon_box}>
            <MdOutlineMailOutline className={style.icon} />
          </div>
        </UserEmail>
        <UserMobileNum data={data} fields={fields} mobile="">
          <div className={style.icon_box}>
            <FaMobileAlt className={style.icon} />
          </div>
        </UserMobileNum>
        <UserEmail data={data} fields={fields} email="email">
          <div className={style.icon_box}>
            <FaEnvelopeOpenText className={style.icon} />
          </div>
        </UserEmail>
        <UserWhatsapp data={data} fields={fields} whatsapp="">
          <div className={style.icon_box}>
            <FaWhatsapp className={style.icon} />
          </div>
        </UserWhatsapp>
      </div>
      <div className={style.row_container}>
        <div className={style.row}>
          <div className={style.col1}>
            <UserFacebook data={data} fields={fields} facebook="">
              <img src={facebook} className={style.img_icon} />
            </UserFacebook>
          </div>
          <div className={style.col2}>
            <span className={style.title}>Facebbok</span>
            <UserShowFacebook
              data={data}
              fields={fields}
              facebook="Lucile R. Grondin"
              deco={style.facebook}
            />
          </div>
        </div>
        <div className={style.row}>
          <div className={style.col1}>
            <UserInsta data={data} fields={fields} insta="">
              <img src={insta} className={style.img_icon} />
            </UserInsta>
          </div>
          <div className={style.col2}>
            <span className={style.title}>Instagram</span>
            <UserShowInsta
              data={data}
              fields={fields}
              insta="@Lucile R. Grondin"
              deco={style.facebook}
            />
          </div>
        </div>
        <div className={style.row}>
          <div className={style.col1}>
            <UserTwitter data={data} fields={fields} twitter="">
              <img src={twitter} className={style.img_icon} />
            </UserTwitter>
          </div>
          <div className={style.col2}>
            <span className={style.title}>Twitter</span>
            <UserShowTwitter
              data={data}
              fields={fields}
              twitter="Lucile R. Grondin"
              deco={style.facebook}
            />
          </div>
        </div>
      </div>
      <div className={style.footer}>All rights reserved</div>
    </div>
  );
};

export default Card6;
