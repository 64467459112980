import React from "react";
import style from "./textInputField.module.css";
import useUpdateCard from "../../../utils/useUpdateCard";

const UserTiktokLinkField = () => {
  const { dispatch, fieldActions } = useUpdateCard();
  const tiktokChange = (event) => {
    dispatch(fieldActions.setUserTiktok(event.target.value));
  };
  return (
    <>
      <input
        type="text"
           placeholder="tiktok account link"
        className={style.textInput}
        onChange={tiktokChange}
      />
    </>
  );
};

export default UserTiktokLinkField;
