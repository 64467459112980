// import costIcon from "../../../assets/home-feature/cost-icon.svg";
import costIcon from "../../../assets/home-feature/cost-icon.svg";
import designIcon from "../../../assets/home-feature/design-icon.svg";
import pluginIcon from "../../../assets/home-feature/plugin-icon.svg";
import customIcon from "../../../assets/home-feature/custom-icon.svg";
import androidIcon from "../../../assets/home-feature/adnroid-icon.svg";
import updateIcon from "../../../assets/home-feature/update-icon.svg";

const features = [
  {
    icon: pluginIcon,
    title: "No Pluging Need",
    detail:
      "Easily update electronic services and internet connectivity with a modern IT solution-no extra software or tools needed.",
  },
  {
    icon: androidIcon,
    title: "ISO & Andriod Supported",
    detail:
      "ISO and Android partner for improved mobile app security and privacy, benefiting businesses and users.",
  },
  {
    icon: customIcon,
    title: "Easy Customization",
    detail:
      "Customization enhances digital technology by improving satisfaction, saving time and money, and promoting creativity.",
  },
  {
    icon: updateIcon,
    title: "Instantly Updatable",
    detail:
      "Updatable systems allow easy real-time content updates, customized options, quicker editing, and fresher content for visitors.",
  },
  {
    icon: designIcon,
    title: "Custom Design",
    detail:
      "Custom Design offers personalized business solutions that exceed expectations. Contact us for more information.",
  },
  {
    icon: costIcon,
    title: "Cost Effective",
    detail:
      "Cost-effectiveness saves money and preserves the environment for future generations by reducing waste.",
  },
];

export default features;
