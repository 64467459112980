import React, { useState } from "react";
import PaymentSelectorModal from "../payment-selector-model/PaymentSelectorModal";
import style from "./paymentModelOpener.module.css";

const PaymentModalOpner = () => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <PaymentSelectorModal open={open} setOpen={setOpen} />
      <button
        onClick={() => setOpen(true)}
        type="submit"
        className={style.action}
      >
        Save
      </button>
    </div>
  );
};

export default PaymentModalOpner;
