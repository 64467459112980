import React, { useEffect, useRef, useState } from "react";
import style from "./homePeroductsSec.module.css";
import data from "./productData";
import CtaButton from "../../ui/cta/CtaButton";
import { motion, useInView } from "framer-motion";
const HomeProductsSec = () => {
  const ref = useRef();
  const inView = useInView(ref, { once: true });
  const exploreHandler = () => {};

  const boxAnimation = {
    hidden: { y: 400, opacity: 0 },
    visible: {
      y: inView ? 0 : 400,
      opacity: inView ? 1 : 0,

      transition: {
        duration: 1.5,
        stagger: 0.5,
      },
    },
  };

  const childAnimation = {
    hidden: { y: 400 },
    visible: {
      y: inView ? 0 : 400,
      opacity: inView ? 1 : 0,
    },
  };

  return (
    <div className={style.main}>
      <motion.section
        ref={ref}
        variants={boxAnimation}
        initial="hidden"
        animate="visible"
        className={style.section}
      >
        <p className={style.pageTitle}>OUR PRODUCTS</p>
        <p className={style.pageDetail}>Explore our products</p>
        <motion.div
          variants={childAnimation}
          transition={{ duration: 1.5 }}
          className={style.products}
        >
          {data.map((pro, index) => {
            return (
              <div key={index} className={style.product}>
                <div className={style.imgParent}>
                  <img src={pro.img} className={style.img} alt="product" />
                  <p className={style.title}>{pro.title}</p>
                  <p className={style.detail}>{pro.detail}</p>
                  <p className={style.detail}>{pro.detail2}</p>
                  <p className={style.price}>{pro.price}</p>
                </div>
              </div>
            );
          })}
        </motion.div>
        <div className={style.action}>
          <CtaButton
            onClick={exploreHandler}
            width="200px"
            animation={true}
            bg="black"
            clr="white"
            title="Explore Products"
          />
        </div>
      </motion.section>

      <div style={{ textAlign: "center" }}></div>
    </div>
  );
};

export default HomeProductsSec;
