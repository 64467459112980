import React from "react";
import style from "./singleounter.module.css";
import CountUp from "react-countup";

const SingleCounter = ({ value, title, after, operator }) => {
  return (
    <div className={style.main}>
      <section className={style.section}>
        <div>
          <p className={style.num}>
            <CountUp decimals={1} end={value} duration={2} /> {after}
            <sup className={style.square}>{operator}</sup>
          </p>
          <p className={style.title}>{title}</p>
        </div>
      </section>
    </div>
  );
};

export default SingleCounter;
