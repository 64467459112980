import React from "react";
import style from "./card19.module.css";
import profile from "../../../../assets/cards/card19/profile.png";
import UserProfile from "../common/user-profile-com/UserProfile";
import UserShowName from "../common/UserShowName";
import UserShowDesigination from "../common/UserShowDesigination";
import UserShowFacebook from "../common/UserShowFacebook";
import UserFacebook from "../common/UserFacebook";
import UserEmail from "../common/UserEmail";
import { MdEmail } from "react-icons/md";
import UserShowEmail from "../common/UserShowEmail";
import UserWebsite from "../common/UserWebsite";
import {
  FaInternetExplorer,
  FaLocationArrow,
  FaFacebook,
} from "react-icons/fa";
import UserShowWebsite from "../common/UserShowWebsite";
import UserLocation from "../common/UserLocation";
import UserShowLocation from "../common/UserShowLocation";
import { useSelector } from "react-redux";
const Card19 = ({ data }) => {
  const fields = useSelector((state) => state.cardFiled);

  return (
    <div className={style.main}>
      <div className={style.profile_section}>
        <UserProfile
          data={data}
          fields={fields}
          deco={style.profile}
          profile={profile}
        />
      </div>
      <div className={style.name_section}>
        <UserShowName
          data={data}
          fields={fields}
          deco={style.name}
          name="Richard Olivia"
        />
        <UserShowDesigination
          data={data}
          fields={fields}
          desigination="Senior Accountant"
          deco={style.desigination}
        />
      </div>
      <div className={style.links}>
        <UserFacebook data={data} fields={fields} facebook="">
          <div className={style.item}>
            <FaFacebook className={style.icon} />
            <UserShowFacebook
              data={data}
              fields={fields}
              facebook="+1 480 567 890"
              deco={style.website}
            />
          </div>
        </UserFacebook>
        <UserEmail data={data} fields={fields} email="">
          <div className={style.item}>
            <MdEmail className={style.icon} />
            <UserShowEmail
              data={data}
              fields={fields}
              deco={style.website}
              email="Oliver.72@gmail.com"
            />
          </div>
        </UserEmail>
        <UserWebsite data={data} fields={fields} website="">
          <div className={style.item}>
            <FaInternetExplorer className={style.icon} />
            <UserShowWebsite
              data={data}
              fields={fields}
              website="www.yoursite.com"
              deco={style.website}
            />
          </div>
        </UserWebsite>
        <UserLocation data={data} fields={fields} location="">
          <div className={style.item}>
            <FaLocationArrow className={style.icon} />
            <UserShowLocation
              data={data}
              fields={fields}
              location="
75 rd ave, New York,
NY 10003, USA"
              deco={style.website}
            />
          </div>
        </UserLocation>
      </div>
      <div className={style.action}>
        <button className={style.btn}>Save To Contact</button>
      </div>
    </div>
  );
};

export default Card19;
