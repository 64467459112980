import React from "react";
import profile from "../../../../assets/cards/card23/profile.png";

import { useSelector } from "react-redux";
import {
  FaFacebook,
  FaInstagram,
  FaLocationDot,
  FaTwitter,
  FaWhatsapp,
  FaYoutube,
} from "react-icons/fa6";
import UserProfile from "../common/user-profile-com/UserProfile";
import style from "./card22.module.css";
import UserShowName from "../common/UserShowName";
import UserShowDesigination from "../common/UserShowDesigination";
import UserMobileNum from "../common/UserMobileNum";
import { IoCafe, IoCall } from "react-icons/io5";
import UserLocation from "../common/UserLocation";
import UserEmail from "../common/UserEmail";
import { MdEmail } from "react-icons/md";
import UserWhatsapp from "../common/UserWhatsapp";
import UserFacebook from "../common/UserFacebook";
import UserInsta from "../common/UserInsta";
import UserTwitter from "../common/UserTwitter";
import UserYoutube from "../common/UserYoutube";
import { FaSave } from "react-icons/fa";
const Card22 = ({ data }) => {
  const fields = useSelector((state) => state.cardFiled);
  return (
    <div className={style.main}>
      <div className={style.profile_section}>
        <UserProfile
          data={data}
          fields={fields}
          profile={profile}
          deco={style.profile}
        />
      </div>
      <div className={style.name_section}>
        <UserShowName
          data={data}
          fields={fields}
          name="JOHANNES SCHNEIDER"
          deco={style.name}
        />
        <UserShowDesigination
          data={data}
          fields={fields}
          desigination="Director Privet Banking IN Bank"
          deco={style.desigination}
        />
      </div>
      <div className={style.link_section}>
        <div className={style.links1}>
          <UserMobileNum data={data} fields={fields} mobile="">
            <div className={style.item}>
              <IoCall className={style.icon} />
              <span className={style.title}>Mobile</span>
            </div>
          </UserMobileNum>
          <UserLocation data={data} fields={fields} location="">
            <div className={style.item}>
              <FaLocationDot className={style.icon} />
              <span className={style.title}>Location</span>
            </div>
          </UserLocation>
          <UserEmail data={data} fields={fields} email="">
            <div className={style.item}>
              <MdEmail className={style.icon} />
              <span className={style.title}>Email</span>
            </div>
          </UserEmail>
        </div>
        <div className={style.link2}>
          <span className={style.heading}> Links</span>
          <div className={style.links}>
            <UserWhatsapp data={data} fields={fields} whatsapp="">
              <div className={style.item}>
                <FaWhatsapp className={style.icon} />
                <span className={style.title}>Whatsapp</span>
              </div>
            </UserWhatsapp>
            <UserFacebook data={data} fields={fields} facebook="">
              <div className={style.item}>
                <FaFacebook className={style.icon} />
                <span className={style.title}>Facebook</span>
              </div>
            </UserFacebook>
            <UserInsta data={data} fields={fields} insta="">
              <div className={style.item}>
                <FaInstagram className={style.icon} />
                <span className={style.title}>Instagram</span>
              </div>
            </UserInsta>
          </div>
          <div className={style.links}>
            <UserTwitter data={data} fields={fields} twitter="">
              <div className={style.item}>
                <FaTwitter className={style.icon} />
                <span className={style.title}>Twitter</span>
              </div>
            </UserTwitter>
            <UserYoutube data={data} fields={fields} youtube="">
              <div className={style.item}>
                <FaYoutube className={style.icon} />
                <span className={style.title}>Youtube</span>
              </div>
            </UserYoutube>
            <div className={style.item}>
              <FaSave className={style.icon} />
              <span className={style.title}>Save</span>
            </div>
          </div>
        </div>
      </div>

      <div className={style.footer} ></div>
    </div>
  );
};

export default Card22;
